<template>
  <div class="host flex-centered" v-if="preRoomState">
    <event-start :is_host="false" :is_direct="true" @confirmed="openRoom"></event-start>
  </div>
  <agreement-modal v-if="!authorized && !guestAgreed"></agreement-modal>
  <conference-room v-else-if="event && roomState"></conference-room>
  
</template>

<script>
// @ is an alias to /src
import { mapState } from 'vuex';
import emitter from "@/services/emitter.service";
import EventStart from '@/components/EventStart.vue';
import ConferenceRoom from "@/components/ConferenceRoom.vue"
import AgreementModal from "@/components/AgreementModal.vue";

export default {
  name: 'RoomView',
  components: {
    AgreementModal,
    //LanguageSelector,
    ConferenceRoom,
    EventStart
  },
  data() {
    return {
      roomState: false,
      preRoomState: false
    }
  },
  methods: {
    openRoom() {
      this.roomState = true;
      this.preRoomState = false;
    }
  },
  computed: {
    ...mapState('event', ['event', 'guest']),
    ...mapState('nonpersist', ['user', 'guestAgreed']),
    ...mapState('auth', ['authorized', 'agreed']),
  },
  async created() {
    emitter.on("close_room", () => {
        this.$store.dispatch("event/close");
        this.$router.replace("/");
    });

    if(this.$route.params && this.$route.params.room_code) {
        await this.$store.dispatch('event/join', this.$route.params.room_code).then(() => {
          if (!this.authorized && !this.guest) {
            this.preRoomState = true;
          } else {
            this.roomState = true;
            this.preRoomState = false;
          }
        })
    }
  }
}
</script>

<style lang="scss">

</style>
