<template>
  <footer class="mobile-footer">
    Also available for Desktop Web
  </footer>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'MainFooter',
  components: {
    
  },
  computed: {
    displayCite() {
      return this.$route.name == 'home'
    }
  }
}
</script>

<style lang="scss">
  .mobile-footer {
    display: flex;
    align-items: center;
    height: 150px;
    text-transform: uppercase;
    color: #46E0D4;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    background: #000;
    padding-left: 20px;
  }
</style>
