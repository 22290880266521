import axios from 'axios';
import {Constants} from '@/cfg/constants'

class LanguagesService {

  getArray() {
    return axios
      .get(Constants.LANGUAGES_DB_PATH);
  }

  
}
export default new LanguagesService();