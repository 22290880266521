<template>
  <div class="overlay">
    <video-background 
            src="/media/output.mp4"
            style="height: 100%;"
        >
        <div class="overlay__bg"></div>
  </video-background>
  </div>
  <div class="start flex-centered">
    <agreement-modal v-if="!agreed" :signup="true"></agreement-modal>
    <div class="signup__container orange-bordered" v-else>
      <router-link to="/" class="icon-btn conference-close">
        <img src="@/assets/icons/xmark.svg" alt="Close">
      </router-link>
        <div class="start__header">
          <div class="start-slider">
            <div class="start-slider__item">
              <img src="@/assets/media/mic.svg" alt="">
              <p v-html="$t('start_tip_mic')"></p>
            </div>
          </div>
        </div>
        <div class="start__options">
          
          <!--<GoogleLogin :callback="googleCallback" :button-config="buttonConfig"/>-->
            <GoogleLogin :callback="googleCallback" popup-type="TOKEN">
              <button class="social-auth-btn social-auth-btn--google">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M23.04 12.2614C23.04 11.4459 22.9668 10.6618 22.8309 9.90909H12V14.3575H18.1891C17.9225 15.795 17.1123 17.013 15.8943 17.8284V20.7139H19.6109C21.7855 18.7118 23.04 15.7636 23.04 12.2614Z" fill="#4285F4"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M11.9999 23.4998C15.1049 23.4998 17.7081 22.4701 19.6108 20.7137L15.8942 17.8283C14.8644 18.5183 13.5472 18.926 11.9999 18.926C9.00467 18.926 6.46945 16.903 5.56513 14.1848H1.72308V17.1644C3.61536 20.9228 7.50445 23.4998 11.9999 23.4998Z" fill="#34A853"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M5.56523 14.1851C5.33523 13.4951 5.20455 12.758 5.20455 12.0001C5.20455 11.2421 5.33523 10.5051 5.56523 9.81506V6.83551H1.72318C0.944318 8.38801 0.5 10.1444 0.5 12.0001C0.5 13.8557 0.944318 15.6121 1.72318 17.1646L5.56523 14.1851Z" fill="#FBBC05"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M11.9999 5.07386C13.6883 5.07386 15.2042 5.65409 16.396 6.79364L19.6944 3.49523C17.7029 1.63955 15.0997 0.5 11.9999 0.5C7.50445 0.5 3.61536 3.07705 1.72308 6.83545L5.56513 9.815C6.46945 7.09682 9.00468 5.07386 11.9999 5.07386Z" fill="#EA4335"/>
</svg>
                {{ $t('signin_google') }}
              </button>
            </GoogleLogin>
            <button class="social-auth-btn social-auth-btn--apple" id="apple-sign-in" @click="appleSignIn">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M21.2806 18.424C20.9328 19.2275 20.5211 19.9672 20.0441 20.6472C19.3938 21.5743 18.8614 22.216 18.4511 22.5724C17.8151 23.1573 17.1336 23.4568 16.4039 23.4739C15.88 23.4739 15.2483 23.3248 14.5129 23.0224C13.775 22.7214 13.097 22.5724 12.477 22.5724C11.8268 22.5724 11.1294 22.7214 10.3835 23.0224C9.63644 23.3248 9.03463 23.4824 8.5745 23.498C7.87472 23.5278 7.17722 23.2197 6.48099 22.5724C6.03662 22.1848 5.48081 21.5204 4.81496 20.5791C4.10057 19.5739 3.51323 18.4084 3.0531 17.0795C2.56032 15.6442 2.31329 14.2543 2.31329 12.9087C2.31329 11.3673 2.64636 10.0379 3.31348 8.92385C3.83778 8.029 4.53528 7.32312 5.40826 6.80493C6.28124 6.28674 7.2245 6.02267 8.2403 6.00578C8.79611 6.00578 9.52499 6.1777 10.4308 6.51559C11.334 6.85462 11.9139 7.02655 12.1682 7.02655C12.3583 7.02655 13.0026 6.82552 14.0948 6.42473C15.1276 6.05305 15.9993 5.89916 16.7134 5.95978C18.6485 6.11595 20.1023 6.87876 21.0691 8.25303C19.3385 9.30163 18.4824 10.7703 18.4994 12.6544C18.515 14.122 19.0474 15.3432 20.0937 16.3129C20.5679 16.7629 21.0975 17.1107 21.6867 17.3578C21.5589 17.7283 21.424 18.0832 21.2806 18.424ZM16.8426 0.960131C16.8426 2.11039 16.4224 3.18439 15.5847 4.17847C14.5739 5.36023 13.3513 6.04311 12.0254 5.93536C12.0085 5.79736 11.9987 5.65213 11.9987 5.49951C11.9987 4.39526 12.4794 3.21349 13.3331 2.24724C13.7593 1.75801 14.3013 1.35122 14.9586 1.02671C15.6146 0.707053 16.235 0.530273 16.8185 0.5C16.8355 0.653772 16.8426 0.807554 16.8426 0.960116V0.960131Z" fill="black"/>
</svg>


                {{ $t('signin_apple')}}
              </button>
        
        </div>
        <div class="or-divider">{{ $t('common_or') }}</div>
        <router-link to="/signin" class="orange-link orange-link--inline">{{ $t('signin_email') }}</router-link>
    </div>
  </div>
  <main-footer></main-footer>
  
</template>

<script>
// @ is an alias to /src
import VideoBackground from 'vue-responsive-video-background-player';
import MainFooter from '@/components/MainFooter.vue';
import AgreementModal from '@/components/AgreementModal.vue';
import { mapState } from 'vuex';
 
const appleState = new Date().getTime.toString();

if (window.AppleID) {
  window.AppleID.auth.init({
    clientId : process.env.VUE_APP_APPLE_COM,
    scope : 'name email',
    redirectURI : process.env.VUE_APP_DOMAIN,
    state : appleState,
    nonce: 'somenonce',
    usePopup : true
  });
}



export default {
  name: 'SignupView',
  components: {
    VideoBackground,
    MainFooter,
    AgreementModal
  },
  data() {
    return {
      user: {},
      errors: {},
      buttonConfig: {
        theme: "filled_black",
        logo_alignment: "left",
        text: "continue_with",
        locale: "en",
        size: "large",
        width: 300,
      }
    }
  },
  methods: {
    googleCallback(response) {
      // This callback will be triggered when the user selects or login to
      // his Google account from the popup
      this.$store.dispatch("auth/googleLogin", {
        googleResponse: response,
        room: false
      }).then(
        (data) => {
          console.log(data);
        },
        (error) => {
          console.log(error);
        }
      )
    },
    async appleSignIn() {
      try {
        const data = await window.AppleID.auth.signIn();
        this.$store.dispatch("auth/appleLogin", {
          appleResponse: data,
          room: false
        });
        // Handle successful response.
      } catch ( error ) {
          console.log(error)
      }
      
    }
  },
  computed: {
    ...mapState('auth', ['agreed', 'authorized']),
  }
}
</script>

<style lang="scss">

  #app {
    height: 100%;
  }

  .overlay {
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;

    &__bg {
      background: linear-gradient(180deg, #000000 0%, rgba(27, 8, 8, 0.02) 40.5%, #060101 96.62%);
      z-index: 1;
      height: 100%;
    }
  }

  .start__options {
    margin-top: 40px;
  }

  .social-auth-btn {
    width: 300px;
    height: 50px;
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.084), 0px 2px 3px rgba(0, 0, 0, 0.168);
    border-radius: 10px;
    border: none;
    display: flex;
    align-items: center;
    padding-left: 51px;
    cursor: pointer;

    svg {
      margin-right: 9px;
    }
  }

  .social-auth-btn.social-auth-btn--google {
    background: #2E2E2E;
    color: #fff;
  }

  .social-auth-btn.social-auth-btn--apple {
    margin-top: 17px;
  }

  .start-slider__item {
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
      margin-bottom: 50px;
    }

    p {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 700;
      font-size: 1rem;
      line-height: 1.18rem;
      text-align: center;
      text-transform: uppercase;
      color: #fff;
      margin: 0;
    }
  }

  .signup__container {
    position: relative;
  }

  /** RESPONSIVE */

  .start {

    
  }


</style>
