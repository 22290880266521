<template>
  <div class="page-wrapper page-wrapper--about">
    <retina-image source="about" ext="jpg"></retina-image>
    <div class="about-text" v-html="this.$t('website_about_text')"></div>
    <div class="waverly-logo">
        <img src="@/assets/website/img/sorenson-logo.svg" alt="Sorenson">
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import RetinaImage from '@/components/ui/RetinaImage.vue'

export default {
  name: 'AboutView',
  components: {
    RetinaImage
  },
  data() {

  }
}
</script>

<style lang="scss">
    .page-wrapper {
        padding-top: 5rem;
        display: flex;
        flex-direction: column;

        &--about {
            justify-content: center;
            align-items: center;
            width: 70%;
            margin: 0 auto;

            img {
                max-width: 57rem;
                min-width: 40rem;
            }
        }

        .about-text {

            p {
                margin: 30px 0 0;
                text-transform: uppercase;
                font-size: 1.25rem;
                line-height: 1.875rem;
                text-align: center;
                color: #000000;
            }
        }

        .waverly-logo {
            margin-top: 3rem;
            text-align: center;
            margin-bottom: 4rem;
        }
    }
</style>
