<template>
    <div class="forum-info-popup">
      <a href="javascript:;" class="icon-btn conference-close" @click="hide"><img src="@/assets/icons/xmark.svg" alt="Close"></a>
      <div class="forum-info">
        <div class="forum-info__text">
          Download the <b>Sorenson Forum</b> App for additional functionality and the ability to record and translate your speech. Try it for free today!
        </div>
        <div class="forum-info__links">
          <a href="https://apps.apple.com/app/id1612322502">
            <img src="@/assets/appstore.svg" alt="Get it on the App Store" width="160">
          </a>
          <a href="https://play.google.com/store/apps/details?id=com.waverlylabs.audience.translate&pli=1">
            <img src="@/assets/googleplay.svg" alt="Get it on Google Play" width="160">
          </a>
        </div>
        <div class="forum-info__footer">
          <b>Thanks for helping us create
 a world without language barriers. </b>
        </div>
      </div>
    </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'AppInfo',
  data() {
    return {
    }
  },
  components: {
    
  },
  methods: {
    hide() {
      this.$emit('close');
    },
  },
  computed: {
   
  }
}
</script>

<style lang="scss">
  
</style>


