<template>
  <div class="locale-selector" v-click-away="onClickAway">
    <a href="javascript:;" class="locale-selector__opener" @click="openLocalesList"><img :src="'/data/flags/' + getLangFlag(initial) + '.png'"/></a>
    <div class="locales-list" v-show="selectorExpanded">
      <span class="locale-item" v-show="key != initial" v-for="(item, key) in availableLocales" :key="key" @click="setLocale(key)">
        <span class="locale-item__flag"><img :src="'/data/flags/' + getLangFlag(key) + '.png'"/></span>
<!--        <span class="locale-item__title">{{ $t('language_' + item)}}</span>-->
      </span>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { loadLanguageAsync } from "@/plugins/i18n";
// @ is an alias to /src
//import { mapState } from 'vuex';

export default {
  name: 'LocaleSelector',
  props: {
    initial: {
      type: String,
      required: true,
      default: "en"
    },
  },
  components: {
    
  },
  data() {
    return {
      selected: false,
      selectorExpanded: false,
      locale: "en",

    }
  },
  methods: {
    getLangFlag(locale) {
      return this.availableLocales[locale];
    },
    setLocale(locale) {
      loadLanguageAsync(locale);
      this.$i18n.locale = locale;
      this.$store.dispatch("locale/set", locale)
      this.selectorExpanded = false;
    },
    openLocalesList() {
      this.selectorExpanded = true;
    },
    onClickAway() {
      this.selectorExpanded = false;
    }
  },
  mounted() {
  },
  computed: {
    ...mapState('locale', ['locale', 'availableLocales']),
  }
}
</script>

<style lang="scss" scoped>
  .locale-selector {
    position: relative;
    width: 45px;
    margin-left: 75px;

    &__opener {
      margin-left: 0;
      width: 45px;
      height: 45px;
      overflow: hidden;
      border-radius: 50%;
      display: flex;
      border: 2px solid #46E0D4;

      img {
        max-width: 100%;
      }

      &:hover {
        display: flex;
      }
    }
  }

  .locales-list {
    position: absolute;
    top: 57px;
    right: 0;
  }

  .locale-item {
    display: flex;
    cursor: pointer;
    align-items: center;
    margin-bottom: 10px;

    &__flag {
      width: 45px;
      height: 45px;
      overflow: hidden;
      border-radius: 50%;
      display: flex;
      /*margin-right: 10px;*/

      img {
        max-width: 100%;
      }
    }

    &__title {
      color: #ffffff;
    }
  }

</style>
