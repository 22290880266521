<template>
  <div class="page-wrapper page-wrapper--faq">
    <div class="faq-flex">
        <!-- temp hide -->
        <div class="faq-video"  v-if="false">
            <div class="faq-video-cover" v-show="!playing">
                <retina-image source="video-cover" ext="jpg"></retina-image>
                <a href="javascript:;" class="faq-video-cover__play" @click="playVideo"><img src="@/assets/icons/video-play.svg" alt="Play the video"></a>
            </div>
            <YouTube 
                src="https://www.youtube.com/watch?v=DXP1KdZX4io" 
                @ready="onReady"
                width="750"
                height="460"
                host="http://localhost:8080"
                :vars="ytProps"
                ref="youtube" 
                v-show="playing"
                />
            <div class="faq-video__text">
                {{ $t('website_faq_more')}} <a href="javascript:;" @click="openBeacon">{{ $t('contact_us_title')}}</a>
            </div>    
        </div>
        <div class="faq-questions">
            <div class="faq-questions__item" v-for="item of faqs" :key="item.question">
                <div class="question" :class="{ expanded : item.expanded}" @click="item.expanded = !item.expanded">
                    <div class="question__title">
                        {{ item.question }}
                    </div>
                    <span class="question__arrow"><img src="@/assets/icons/accord-arrow.svg" alt="Expand"></span>
                </div>
                <div class="answer" v-if="item.expanded" v-html="item.answer">
                
                </div>
            </div>
        </div>
    </div>
    <!-- temp hide -->
    <div class="faq-resources" v-if="false">
        <h3 class="faq-resources__title">Additional Resources</h3>
        <div class="faq-resources-links">
            <a href="#" class="resource-link">
                <span class="resource-link__title">Preparing for a <br>great conference</span>
                <span class="resource-link__type">PDF Guide</span>
                <img src="@/assets/icons/res1.svg" class="bottomed" alt="">
            </a>
            <a href="#" class="resource-link">
                <span class="resource-link__title">Projecting <br>into a screen</span>
                <span class="resource-link__type">PDF Guide</span>
                <img src="@/assets/icons/res2.svg" alt="">
            </a>
            <a href="#" class="resource-link">
                <span class="resource-link__title">Recording from <br>audio Mixer</span>
                <span class="resource-link__type">PDF Guide</span>
                <img src="@/assets/icons/res3.svg" alt="">
            </a>
        </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import RetinaImage from '@/components/ui/RetinaImage.vue'

export default {
  name: 'FaqView',
  components: {
    RetinaImage,
  },
  data() {
    return {
        playing: false,
        ytProps: {
            controls: 0,
            modestbranding: 1,
            autohide: 1,
            showinfo: 0
        },
        faqs: [
            {
                question: this.$t('support_faq_microphone_q'),
                answer: this.$t('support_faq_microphone_a'),
                expanded: true
            },
            {
                question: this.$t('support_faq_languages_q'),
                answer: this.$t('support_faq_languages_a')
            },
            {
                question: this.$t('support_faq_remote_q'),
                answer: this.$t('support_faq_remote_a')
            },
            {
                question: this.$t('support_faq_accuracy_q'),
                answer: this.$t('support_faq_accuracy_a')
            },
            {
                question: this.$t('support_faq_pause_q'),
                answer: this.$t('support_faq_pause_a')
            },
            {
                question: this.$t('support_faq_feedback_q'),
                answer: this.$t('support_faq_feedback_a')
            },
            {
                question: this.$t('support_faq_scenario_q'),
                answer: this.$t('support_faq_scenario_a')
            },  
        ]
    }
  },
  methods: {
    playVideo() {
        this.playing = true;
        console.log(this.$refs)
        this.$refs.youtube.play()
    },
    openBeacon() {
      window.Beacon('open');
    }
  }
}
</script>

<style lang="scss">
    .page-wrapper {
        padding-top: 5rem;
        display: flex;
        flex-direction: column;

        &--faq {
            justify-content: center;
            align-items: center;
            width: 85%;
            margin: 0 auto;

            img {
                max-width: 57rem;
            }
        }

    }

    .faq-flex {
        display: flex;
        width: 100%;
        justify-content: space-between;
    }

    .faq-video-cover {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 460px;
        width: 750px;

        > img {
            position: absolute;
            left: 0;
            top: 0;
            z-index: 1;
        }

        &__play {
            position: relative;
            z-index: 2;
        }
    }

    .faq-questions {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding-left: 7%;

        &__item {
            margin-bottom: 1.5rem;
        }
    }

    .question {
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;

        &.expanded {

            .question__arrow {
                 transform: rotate(180deg); 
                 position: relative;
                 top: -5px;
            }
        }

        &__title {
            font-weight: 700;
            font-size: 1.625rem;
            line-height: 2.4rem;
            text-transform: uppercase;

            color: #46E0D4;
        }
    }

    .answer {
        font-size: 1rem;
        line-height: 1.6rem;
        padding-top: 10px;
    }

    .faq-video {

        &__text {
            font-size: 1.6rem;
            display: flex;
            justify-content: center;
            margin-top: 1rem;

            a {
                font-weight: 700;
                font-size: 1.6rem;
                /* identical to box height, or 38px */

                align-items: center;
                text-align: center;
                text-decoration-line: underline;
                margin-left: 10px;

                /* Gold */

                color: #46e0d4;
            }
        }
    }

    .faq-resources {
        margin-top: 3.4rem;
        margin-bottom: 3.4rem;
        width: 100%;

        &__title {
            font-weight: 500;
            font-size: 1.625rem;
            line-height: 3rem;
            text-transform: uppercase;
            color:  #505050;
            margin-bottom: 11px;
            text-align: center;
        }

        &-links {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            column-gap: 5.25rem;

            .resource-link {
                border: 3px solid #C2C2C2;
                border-radius: 25px;
                padding: 1.1rem 1rem;
                position: relative;
                height: 120px;

                &__title {
                    font-weight: 700;
                    font-size: 1.5rem;
                    line-height: 120%;
                    /* or 120% */

                    display: flex;
                    align-items: center;
                    text-transform: uppercase;

                    color: #C2C2C2;
                }

                &__type {
                    font-weight: 500;
                    font-size: 1.1rem;
                    line-height: 1.5rem;
                    /* identical to box height */

                    display: flex;
                    align-items: center;
                    text-transform: uppercase;

                    color: #C2C2C2;
                }

                img {
                    position: absolute;
                    bottom: 9%;
                    right: 3%;

                    &.bottomed {
                        bottom: 0;
                    }
                }
            }
        }
    }
</style>
