import api from '@/services/api.service';

class GlossaryService {

    addTerm(token, term, language = 'all') {
        return api.
            post('/custom-vocabulary-add', {
                token: token,
                term: term,
                language: language
            });
    }

    deleteTerm(token, term_id) {
        return api.
            post('/custom-vocabulary-delete', {
                token: token,
                term_id: term_id
            });
    }

    getTerms(token) {
        return api.
            post('/custom-vocabulary-get', {
                token: token
            });
    }
}
export default new GlossaryService();