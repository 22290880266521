<template>
  <div class="overlay">
    <video-background 
            src="/media/output.mp4"
            style="height: 100%;"
        >
        <div class="overlay__bg"></div>
  </video-background>
  </div>
  <div class="profile flex-centered">
    <div class="signup__title">
      <h1 class="heading_1">{{ heading }}</h1>
    </div>

    
    <div class="profile__container orange-bordered">
      <router-link to="/" class="icon-btn conference-close">
        <img src="@/assets/icons/xmark.svg" alt="Close">
      </router-link>
        <div class="pageSettings pageStateContainer" v-show="pageState == 'settings'">
          <a href="javascript:;" class="profile-link" @click="setProfilePage" v-show="user">
              <span class="profile-link__name" v-if="authorized">
                <i>{{ user.email }}</i> <br>
                <b>{{ user.subscription.title }} plan</b>
              </span>
              <span class="profile-link__name" v-else>
                <i>{{ user.name }}</i> <br>
                <b>{{ $t('common_guest')}}</b>
              </span>
              <span class="profile-link__lang" v-if="user.language"><img :src="'/data/flags/' + user.language + '.png'"/></span>
          </a>
           <div class="profile-settings">
            <div class="settings-item">
                <div class="tts-settings" v-if="plan">
                    <!-- if individual plans or team plan participant -->
                    <a href="javascript:;" @click="setSubscriptionPage" class="table-item table-item--link" >
                      <div class="table-item__left">
                        <span>{{ $t('plan_title')}}</span>
                      </div>
                      <div class="table-item__right">
                        <span style="text-transform: capitalize;">{{ user.subscription.title }}</span>
                      </div>
                    </a>

                    <!-- if team plan admin -->
                    
                    <router-link to="/plan-dashboard" class="table-item table-item--link table-item--no-border" v-if="isMultiSeat">
                      <div class="table-item__left">
                        <span>{{ $t('teams_plan_manage')}}</span>
                      </div>
                      <div class="table-item__right">
                        <span style="text-transform: capitalize;"></span>
                      </div>
                    </router-link>
                    <!--<router-link to="/plan-dashboard" class="table-item table-item--link" v-else>
                      <div class="table-item__left">
                        <span>{{ $t('teams_plan_manage')}}</span>
                      </div>
                      <div class="table-item__right">
                        <span style="text-transform: capitalize;">{{ plansMapping[user.subscription.type] }}</span>
                      </div>
                    </router-link>-->
                </div>   
                <div class="table-item table-item--no-border table-item--notify" v-if="user.subscription.upcoming_changes">
                  <div class="table-item__left">
                    <img src="@/assets/icons/info-icon.svg" alt="" width="20">
                    <span v-html="upcomingText"></span>
                  </div>
                   <div class="table-item__right"></div>
                </div> 
            </div>
            <div class="settings-item">
                <label class="item-label">
                  <span class="item-label__text">{{ $t('settings_menu')}}</span>
                </label>
                <div class="tts-settings">
                    <a href="javascript:;" @click="setTranscriptsPage" class="table-item table-item--link">
                      <div class="table-item__left">
                        <img src="@/assets/icons/transcripts-icon.svg" />
                        <span>{{ $t('transcripts_title')}}</span>
                      </div>
                      <div class="table-item__right"></div>
                    </a>
                    <router-link to="/glossary" class="table-item table-item--link table-item--no-border" v-if="canGlossary">
                      <div class="table-item__left">
                        <img src="@/assets/icons/glossary-icon.svg" width="20"/>
                        <span>{{ $t('glossary_title')}}</span>
                      </div>
                      <div class="table-item__right"></div>
                    </router-link>
                    <!--<a href="/faq" target="_blank" class="table-item table-item--link">
                      <div class="table-item__left">
                        <img src="@/assets/icons/help-icon.svg" />
                        <span>{{ $t('help_center_title')}}</span>
                      </div>
                      <div class="table-item__right"></div>
                    </a>-->
                    <a href="javascript:;" @click="openBeacon" class="table-item table-item--link">
                      <div class="table-item__left">
                        <img src="@/assets/icons/contact-icon.svg" />
                        <span>{{ $t('contact_us_title')}}</span>
                      </div>
                      <div class="table-item__right"></div>
                    </a>
                  <a href="/terms" target="_blank" class="table-item table-item--link">
                    <div class="table-item__left">
                      <span>{{ $t('terms_of_service')}}</span>
                    </div>
                    <div class="table-item__right"></div>
                  </a>
                  <a href="https://sorenson.com/legal/terms-of-use/" target="_blank" class="table-item table-item--link">
                    <div class="table-item__left">
                      <span>{{ $t('terms_of_use')}}</span>
                    </div>
                    <div class="table-item__right"></div>
                  </a>
                  <a href="https://sorenson.com/legal/privacy-policy/" target="_blank" class="table-item table-item--link">
                    <div class="table-item__left">
                      <span>{{ $t('support_privacy_title')}}</span>
                    </div>
                    <div class="table-item__right"></div>
                  </a>
                </div>
                
              </div>
               <div class="settings-item">
                  <div class="account-actions">
                    <a href="javascript:;" class="danger-btn" @click="logout">
                      <img src="@/assets/icons/logout-icon.svg" alt="Sign out">
                      {{ $t('settings_signout')}}
                    </a>
                    <a href="javascript:;" class="danger-btn" @click="deactivate">
                      <img src="@/assets/icons/delete-icon.svg" alt="Delete Account">
                      {{ $t('profile_delete_account')}}
                    </a>
                  </div>
               </div>
          </div>  
        </div>
        <div class="pageSubscription pageStateContainer" v-show="pageState == 'subscription'">
          <header class="selector-header" @click="setSettingsPage()"><a href="javascript:;" class="back-arrow-btn">{{ $t('common_back') }}</a></header>
          <subscription-page :subscription="user.subscription" :features="user.features"></subscription-page>
        </div>
        <div class="pageTranscripts pageStateContainer" v-show="pageState == 'transcripts'">
          <header class="selector-header" @click="setSettingsPage()"><a href="javascript:;" class="back-arrow-btn">{{ $t('common_back') }}</a></header>
          <transcripts-page></transcripts-page>
        </div>
        <div class="pageProfile pageStateContainer" v-show="pageState == 'profile'">
          <settings-form :user="user"  @profileback="profileBack" @saved="savedProfile"></settings-form>
          
        </div>
    </div>
  </div>
  <main-footer></main-footer>
  
</template>

<script>
// @ is an alias to /src

import VideoBackground from 'vue-responsive-video-background-player';
import MainFooter from '@/components/MainFooter.vue';
import SubscriptionPage from '@/components/SubscriptionPage.vue';
import TranscriptsPage from "@/components/TranscriptsPage";
import SettingsForm from '@/components/SettingsForm.vue';
import ProductsService from '@/services/products.service'

import { mapMutations, mapState } from 'vuex';
import Swal from 'sweetalert'
///import emitter from "@/services/emitter.service";

import permissions from "@/services/permissions.service";

export default {
  name: 'ProfileView',
  components: {
    VideoBackground,
    MainFooter,
    SubscriptionPage,
    SettingsForm,
    TranscriptsPage
  },
  data() {
    return {
      errors: {},
      heading: this.$t('profile_title'),
      pageState: "settings",
      plan: false
    }
  },
  methods: {
    ...mapMutations('nonpersist', ['setUser']),
    logout() {
      this.$store.dispatch("auth/logout");
    },
    deactivate() {
      //this.$store.dispatch("auth/logout");
      let swalTitle = this.$t('common_are_you_sure');
      let swalDescription = this.$t('profile_delete_account_warning');
      Swal({
        title: swalTitle,
        text: swalDescription,
        icon: 'warning',
        dangerMode: true,
        buttons: {
          cancel: {
            text: this.$t('common_cancel'),
            value: false,
            visible: true,
            className: "",
            closeModal: true,
          },
          confirm: {
            text: this.$t('common_confirm'),
            value: true,
            visible: true,
            className: "",
            closeModal: true
          }
        },
      }).then((result) => {
        if (result) {
          this.$store.dispatch("auth/delete", this.user);
        }
      })
    },
    savedProfile(profile) {
      this.setUser(profile);
      this.formRequest();
    },
    formRequest() {
      if (this.validate()) {
        this.$store.dispatch("auth/update", this.user).then(
        () => {
          this.updateSuccess = true;
          this.$notify('common_changes_saved', {
            icon: "success",
            toast: true
          });
          this.setSettingsPage();
        },
        (error) => {
          this.$notify(error.response.data.error, {
              icon: "error",
              toast: true
          });
        }
      );
      }
    },
    profileBack() {
      this.pageState = "settings";
    },
    setSettingsPage() {
      this.pageState = 'settings';
      this.heading = this.$t('profile_title');
    },
    setProfilePage() {
      this.pageState = 'profile';
      this.heading = this.$t('profile_title');
    },
    setSubscriptionPage() {
      this.pageState = 'subscription';
      this.heading = this.$t('plan_title');
    },
    setTranscriptsPage() {
      this.pageState = 'transcripts';
      this.heading = this.$t('transcripts_title');
    },
    validate() {
      // for now we are just validation passwords match
      // TODO other validation we need
      return true;
    },
    selectLanguage(langCode) {
      this.user.language = langCode;
    },
    openBeacon() {
      window.Beacon('open');
    },
  },
  computed: {
    ...mapState('auth', ['token', 'authorized']),
    ...mapState('nonpersist', ['user']),
    ...mapState('locale', ['plansMapping']),
    
    subscription() {
      return this.user.subscription ?? false;
    },
    upcomingText() {
      let planString =  "<b style='text-transform: capitalize;'>" + this.user.subscription.upcoming_changes.type + "</b>";
      if (this.user.subscription.upcoming_changes.seats) {
        planString += " " + "(" + this.user.subscription.upcoming_changes.seats + " " + this.$t('team_dashboard_seat_plural') +")";
      }
      return this.$t('profile_upcoming_changes', {
        plan:  planString,
        date: this.user.subscription.upcoming_changes.date

      });
    },
    isMultiSeat() {
      return this.plan.billing.is_multi_seats
    },
    canGlossary() {
      return permissions.can('custom_terms', this.user);
    }
  },
  async mounted() {
    this.$store.dispatch("auth/userMe", {
      token: this.token,
      room: false
    });

    await ProductsService.getPlanById(this.user.subscription.type).then(
      (response) => {
        this.plan = response.data.result.subscription;
      }
    )
  }
}
</script>

<style lang="scss">
  .radio-tabs {
    display: flex;
    background: #333333;
    border-radius: 10px;
    height: 44px;
    padding: 4px;

    .radio-tabs__btn {
      cursor: pointer;
      width: 50%;
      display: flex;
      align-items: center;
      margin: 0;
      text-transform: none;
      font-weight: normal;
      padding-left: 0;

      svg {
        vertical-align: middle;
        margin-right: 10px;
      }
    }

    .radio-tabs__visual {
      border-radius: 10px;
      display: flex;
      align-items: center;
      padding-left: 18px;
      height: 100%;
      width: 100%;
    }
  }

  

  .hidden-radio:checked + .radio-tabs__visual {
    background: #46E0D4;
  }

  .profile-form {
    position: relative;
  }

  .profile {
    
    &-link {
      width: 100%;
      margin-bottom: 20px;
    }

    &__email {
      color: #505050;
      font-size: 1rem;
      line-height: 1.18rem;
      margin-bottom: 20px;
    }

    &-settings {
      margin-top: 0;

      .settings-item {
        margin-bottom: 1.875rem;

        &:last-child {
          margin-bottom: 0;
        }

        .item-label {
          font-weight: 500;
          font-size: 14px;
          line-height: 1.18rem;
          /* identical to box height */

          display: flex;
          align-items: flex-end;
          text-transform: uppercase;

          /* White Audience */

          color: #FFFFFF;

          opacity: 0.3;
        }

        .table-item {

          &--link {
                background: url("@/assets/icons/arrow-gray.svg") 95% 46% no-repeat;

                .table-item__right {
                  padding-right: 45px;
                }
          }

          &--no-border {
            border: none;
          }

          &--notify {
            padding-top: 10px;

            span {
              font-size: 0.9rem;
              opacity: 0.5;
            }
          }

          &__left {
            display: flex;
            align-items: center;
            font-size: 1rem;
            line-height: 1.18rem;

            img {
              vertical-align: top;
              margin-right: 10px;
            }
          }

          &__right {
            
            span {
              color: #FFFFFF;
              text-decoration: none;
              opacity: 0.5;
            }
          }
        }
      }
    }
  }

  .pageSettings, .pageSubscription, .pageTranscripts  {
    width: 100%;
  }

  .profile-link__lang {
    margin-right: 20px;
  }

  .pageStateContainer {
    position: relative;

    .selector-header {
      margin-bottom: 15px
    }
  }

  .subscription-upgrade {
    display: flex;
    justify-content: center;
    margin-top: 30px;
    flex-direction: column;
    
    .danger-btn {
      margin-top: 16px;
    }
  }

  .account-actions {
    margin-top: 20px;
    display: flex;
    align-items: center;

    .danger-btn {
      flex: 1;
      justify-content: flex-end;
      
      &:first-child {
        margin: 0;
        justify-content: flex-start;
      }
    }

  }

  .lang-flag {
    max-width: 30px;
  }

  .lang-flag img {
    max-width: 28px;
  }

  .profile__container {
    position: relative;
    padding-top: 20px;
    padding-bottom: 20px;
  }
</style>
