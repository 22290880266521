<template>
  <div class="overlay">
    <video-background 
            src="/media/output.mp4"
            style="height: 100%;"
        >
        <div class="overlay__bg"></div>
  </video-background>
  </div>
  <div class="signin flex-centered">
    <div class="signup__title">
      <h1 class="heading_1">{{ heading }}</h1>
      <div class="signup__email" v-if="client_user && client_user.email">{{ client_user.email }}</div>
    </div>
    
    <div class="signin__container orange-bordered">

      <div class="signup-form form form-default">
        <form action="#" method="POST" @submit.prevent="formRequest">
          <div class="form-row">
            <label for="#signin_email">{{ $t('profile_email')}}</label>
            <input type="email" required name="email" v-model="user.email" class="form-input form-input--text" placeholder="" id="signin_email">
          </div>
          <div class="form-row">
            <label for="#signin_password">{{ $t('profile_password')}}</label>
            <input type="password" required class="form-input form-input--text" name="password" v-model="user.password" placeholder="" id="signin_password">
          </div>

          <div class="pwdrecovery">
            <router-link to="/forgotpwd" class="pwdrecovery__link">{{ $t('profile_forgot_password') }}</router-link>
          </div>
          
          <button type="submit" name="signin_submit" id="signin_submit" class="orange-btn form-btn" value="Sign In">{{ $t('signin_title') }}</button>
          <div class="or-divider">{{ $t('common_or') }}</div>
          <router-link to="/signup" class="orange-link orange-link--inline">{{ $t('signup_title') }}</router-link>
        </form>
        
      </div>
    </div>
  </div>
  <main-footer></main-footer>
  
</template>

<script>
// @ is an alias to /src
 import VideoBackground from 'vue-responsive-video-background-player';
 import MainFooter from '@/components/MainFooter.vue';
import { mapState } from 'vuex';

export default {
  name: 'SigninView',
  components: {
    VideoBackground,
    MainFooter
  },
  data() {
    return {
      user: {},
      errors: {},
      heading: this.$t('signin_title'),
    }
  },
  methods: {
    formRequest() {
      if (this.validate()) {
        this.$store.dispatch("auth/login", this.user).then(
        (data) => {
          console.log(data);
        },
        (error) => {
          this.$notify(error.response.data.error, {
            icon: "error",
            toast: true
          });
        }
      );
      }
    },
    validate() {
      // for now we are just validation passwords match
      // TODO other validation we need
      return true;
    },
    passwordsMatchCheck() {
      let match = this.user.password === this.user.retype_password;
      if (!match) {
        this.$refs.retype_password_input.setCustomValidity(this.$t('error_passwords_match'));
        this.$refs.retype_password_input.focus()
      } else {
        this.$refs.retype_password_input.setCustomValidity("");
      } 

      return match;
    }
  },
  computed: {
    ...mapState('auth', ['client_user'])
  }
}
</script>

<style lang="scss">

  #app {
    height: 100%;
  }

  .overlay {
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;

    &__bg {
      background: linear-gradient(180deg, #000000 0%, rgba(27, 8, 8, 0.02) 40.5%, #060101 96.62%);
      z-index: 1;
      height: 100%;
    }
  }

  .pwdrecovery {
    text-align: center;
    margin-bottom: 100px;

    &__link {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 400;
      font-size: 1rem;
      line-height: 1.18rem;
      text-align: center;

      color: #7D7D7D;
    }
  }

  /** responsive */
  
  @media all and (max-height: 800px) {
    .pwdrecovery {
      margin-bottom: 50px;
    }
  }

</style>
