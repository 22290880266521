<template>
    <div class="agreement__container orange-bordered" :class="{ scrolledView: signup }">
      <router-link to="/" class="icon-btn conference-close">
        <img src="@/assets/icons/xmark.svg" alt="Close">
      </router-link>
      <div class="textview-wrap" v-if="signup">
          <terms-inc></terms-inc>
      </div>
      <div class="linksview-w" >
        <div class="agreement-disclaimer" v-if="!signup">
          {{ $t('common_check_agree')}} <br><a href="https://sorenson.com/legal/terms-of-use/" target="_blank">{{ $t('terms_of_use') }} {{ $t('common_and') }} {{ $t('support_privacy_title')}}</a>
        </div>
        <div class="agreement-form form">
          <form method="POST" action="#" @submit.prevent="acceptAgreements">
            <div class="form-row">
            </div>
            <button type="submit" name="signup_submit" id="signup_submit" class="orange-btn form-btn" value="Accept">{{ $t('common_accept')}}</button>
          </form>
        </div>
      </div>
      
    </div>
    
</template>

<script>
// @ is an alias to /src
import { mapState } from 'vuex';
import TermsInc from '@/components/includes/TermsInc.vue';

export default {
  name: 'AgreementModal',
  props: {
    signup: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  components: {
    TermsInc,
  },
  data() {
    return {
      termsShow: false,
      privacyShow: false,
    }
  },
  methods: {
    acceptAgreements() {
      if (!this.authorized && !this.signup) {
        this.$store.commit('nonpersist/setGuestAgreed', true);
      } else {
        this.$store.commit("auth/setAgreed", true);
      }
    },
  },
  computed: {
    ...mapState('auth', ['token', 'authorized', 'agreed']),
    ...mapState('nonpersist', ['guestAgreed']),
    displayLogo() {
      return this.$route.name != 'home'
    }
  }
}
</script>

<style lang="scss">
  .agreement__container {
    font-family: 'Inter', sans-serif;
    position: relative;

    @media all and (max-width: 800px) {
      position: fixed;
      top: 0;
      width: 100% !important;
      height: 100% !important;
      z-index: 10000;
      border-radius: 0 !important;
    }
  }

  .agreement-disclaimer {
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 150%;
    text-transform: uppercase;
    text-align: center;
    color: #FFFFFF;

    a {
      color: #46e0d4;
      text-decoration: underline;
    }

    @media all and (max-width: 800px) {
      font-size: 1.3rem;
      line-height: 1.5rem;
    }
  }

  .agreement-links {
    margin-top: 40px;
    margin-bottom: 65px;
    border-top: 1px solid #505050;
    display: flex;
    flex-direction: column;
    width: 100%;

    a {
      display: flex;
      font-weight: 400;
      font-size: 1rem;
      line-height: 22px;
      height: 44px;
      width: 100%;
      align-items: center;
      /* identical to box height, or 138% */
      text-transform: capitalize;
      color: #46E0D4;
      border-bottom: 1px solid #505050;
      padding-left: 15px;
      background: url(../assets/icons/arrow-r.svg) 90% center no-repeat;

      @media all and (max-width: 800px) {
        font-size: 1.3rem;
      }
    }
  }

  .custom-checkbox {
    cursor: pointer;
    display: flex !important;

    input {
      position: absolute;
      opacity: 0;
    }

    .checkmark {
      height: 36px;
      flex-basis: 36px;
      flex-grow: 0;
      flex-shrink: 0;
      background: #515151;
      border: 2px solid #EAEAEA;
      border-radius: 5px;
      margin-right: 14px;
    }

    input:checked + .checkmark {
      background: #515151 url(../assets/icons/checkmark.svg) center center no-repeat;
    }

    &__label {
      font-weight: 400;
      font-size: 0.9rem;
      line-height: 1.125rem;

      color: #FFFFFF;
      text-transform: none;

      @media all and (max-width: 800px) {
        font-size: 1.2rem;
        line-height: 1.4rem;
      }
    }
  }

  .scrolledView {
    
    width: 800px;

    .textview-wrap {
      height: 460px;
      overflow: auto;
      max-width: 100%;
    }

    .text-content {
      padding-right: 25px;
    }

    @media all and (max-width: 800px) {
      .conference-close {
        display: none;
      }

      .text-content {
        padding-right: 0;
        font-size: 14px;
      }
    }
  }

  .agreement__close {
    position: absolute;
    right: 20px;
    top: 20px;
    display: block;
    width: 25px;

    img {
      max-width: 100%;
    }

    @media all and (max-width: 800px) {
      width: 33px;
      height: 33px;
      top: 5%;
      right: 5%;
    }
  }

  .conference {
    .agreement__container {
      margin: auto;

      .conference-close {
        position: absolute;
        top: -7%;
        right: -7%;

        @media all and (max-width: 800px) {
          top: 5%;
          right: 5%;
          position: absolute;
        }
      }
    }
  }

  .agreement-form form {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

</style>
