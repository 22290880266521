<template>
  <header class="main-header">
        <a href="/" class="main-header__logo" v-if="displayLogo">
          <img src="../assets/logo-sm.svg" width="167" alt="">
        </a>
        <nav class="main-nav" v-if="!$route.meta.tpl">
          <!--<router-link to="/faq">{{ $t('how_it_works_title')}}</router-link>-->
          <router-link to="/plans">{{ $t('pricing_title') }}</router-link>
          <!--router-link to="/about">{{ $t('about_title')}}</router-link>-->
          <a href="javascript:;" @click="openBeacon">{{ $t('contact_us_title')}}</a>
          <router-link to="/start"  v-if="!authorized" class="orange-link">{{ $t('signin_title')}}/{{ $t('register_title')}}</router-link>
          <router-link to="/profile"  v-else class="orange-link">{{ $t('profile_title')}}</router-link>
          <locale-selector :initial="$i18n.locale"></locale-selector>
        </nav>
        
        <a href="javascript:;" v-if="$route.meta.tpl && $route.meta.tpl == 'conference'" @click="closeConference" class="icon-btn conference-close">
          <img src="@/assets/icons/xmark.svg" alt="Close">
        </a>
      </header>
</template>

<script>
// @ is an alias to /src
import { mapState } from 'vuex';
import Swal from 'sweetalert'
import emitter from "@/services/emitter.service";
import LocaleSelector from "@/components/LocaleSelector";


export default {
  name: 'MainHeader',
  components: {
    LocaleSelector
  },
  methods: {
    closeConference() {
      const isRegularRoom = this.event.room_type !== "permanent" && this.event.room_type !== "temporary";
      let swalTitle = this.is_room_owner && isRegularRoom ? this.$t('end_session_question') : this.$t('leave_session_question');
      let swalDescription = this.is_room_owner ? this.$t('session_speaker_quit_warning') : this.$t('session_listener_quit_warning');
      Swal({
        title: swalTitle,
        text: swalDescription,
        icon: 'warning',
        dangerMode: true,
        buttons: {
           cancel: {
              text: this.$t('common_cancel'),
              value: false,
              visible: true,
              className: "",
              closeModal: true,
            },
            confirm: {
              text: this.$t('common_confirm'),
              value: true,
              visible: true,
              className: "",
              closeModal: true
            }
        },
      }).then((result) => {
        if (result) {
          if (isRegularRoom) {
            emitter.emit('close_room', { is_room_owner: this.is_room_owner})
          } else {
            emitter.emit('quit_room')
          }
        }
      })
    },
    openBeacon() {
      window.Beacon('open');
    }
  },
  computed: {
     ...mapState('auth', ['token', 'authorized']),
     ...mapState('event', ['event', 'is_room_owner']),
    displayLogo() {
      return this.$route.name != 'home'
    }
  }
}
</script>

<style lang="scss">
  .main-header {
    padding: 33px 30px 0;
    display: flex;
    justify-content: space-between;

    &__logo {  
      position: relative;
      z-index: 10;
    }
  }

  .main-nav {
    display: flex;
    justify-content: flex-end;
    margin-left: auto;
    align-items: center;

    a {
      margin-left: 75px;
      font-weight: 300;
      font-size: 1.3rem;
      line-height: 2rem;
      /* identical to box height */
      text-align: center;
      text-transform: uppercase;

      color: #FFFFFF;

      &.orange-link, &:hover {
        color: #46E0D4;
        display: inline;
      }

      &:first-child {
        margin-left: 0;
      }

      @media all and (max-width: 1400px) {
        margin-left: 40px;
        font-size: 1.2rem;
      }
    }
  }

  .conference-close {
    z-index: 6;
  }
</style>
