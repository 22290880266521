<template>
  <div class="page-wrapper page-wrapper--team-dashboard">
    <div class="pricing">
        <div class="pricing-header">
            <div class="pricing-header__left"><span style="text-transform: capitalize;">{{ $t('plan_title')}}: {{ user.subscription.title }}</span>, {{ $t('team_dashboard_seat_plural')}}: {{ user.features.seats.total_allowed_items }}</div>
        </div>
        <div class="pricing-tabs">
          <div class="tabs-controls">
            <a href="#manage" :class="{ active: tab == 'manage'}" @click="setTab('manage')">{{ $t('team_dashboard_manage_tab_title') }}</a>
            <a href="#billing" v-if="user.subscription.payment_type != 'manual'" :class="{ active: tab == 'billing'}" @click="setTab('billing')">{{ $t('team_dashboard_billing_tab_title') }}</a>
          </div>
          <div class="tabs-controls__subheader" v-if="tab == 'team'">{{ $t('plans_teams_subheader') }}</div>
          <div class="tabs-content">
            <div class="tab" data-tab="manage"  v-show="tab == 'manage'">
              <div class="manage-seats">
                <h2>{{ $t('team_dashboard_assigned_seats_heading') }}</h2>
                <div class="seats-table">
                    <div class="seats-table__body"  v-if="seats && seats.length > 0">
                        <div class="seats-table__row seats-table__row--header">
                            <div class="seats-table__col">{{ $t('profile_email')}}</div>
                            <div class="seats-table__col">{{ $t('profile_name')}}</div>
                            <div class="seats-table__col">{{ $t('team_dashboard_status_column') }}</div>
                            <div class="seats-table__col seats-table__col--actions">{{ $t('team_dashboard_actions_column') }}</div>
                        </div>
                        <div class="seats-table__row" v-for="seat in seats" :key="seat.email">
                            <div class="seats-table__col">{{ seat.email }}</div>
                            <div class="seats-table__col">{{ seat.name }}</div>
                            <div class="seats-table__col"><span class="seat-status" :class="seat.status">{{ $t('team_dashboard_status_' + seat.status) }}</span></div>
                            <div class="seats-table__col seats-table__col--actions">
                                <a href="javascript:;" class="revoke-button" @click="revokeSeat(seat)" v-if="seat.status != 'revoked'">	&#10060; {{ $t('team_dashboard_revoke_access') }}</a>
                                <!--<span class="status--revoked" v-else>revoked</span>-->
                            </div>
                        </div>
                    </div>
                    <div class="seats-table__empty" v-else>
                        <p v-html="$t('team_dashboard_no_seats_assigned')"></p>
                    </div>
                    <div class="seats-table__row seats-table__row--adding" v-if="adding">
                        <div class="seats-table__col">
                            <form action="#" @submit.prevent="assignSeat" method="POST">
                                <input type="email" class="email-input" required v-model="newSeat.email" placeholder="Email" :class="{error: newSeat.errors.email}">
                                <div class="adding-actions">
                                    <button type="submit" class="adding-actions__confirm"><span> &check;</span>{{ $t('common_confirm') }}</button>
                                    <a href="javascript:;" class="adding-actions__cancel" @click="cancelAdding()">{{ $t('common_cancel') }}</a>    
                                </div>
                            </form>
                            
                        </div>
                    </div>
                    <div class="seats-table__footer"  v-if="!adding">
                        <div class="seats-table__col">
                            <div class="seats-table__add">
                                <a href="javascript:;" @click="setAdding(true)">
                                    <span class="plus-add">+</span>
                                    {{ $t('team_dashboard_assign_seat_button') }}</a>
                                <span class="seats-available"> {{ $t('common_available') }}: {{ seatsAvailable }}</span>
                            </div>
                            
                        </div>
                        
                    </div>
                </div>
              </div>
            </div>

            <!-- billing tab -->
            <div class="tab" data-tab="billing" v-show="tab == 'billing'" v-if="plan &&  user.subscription.payment_type != 'manual'">
                <div class="team-upcoming-changes" v-if="user.subscription.upcoming_changes">
                    <img src="@/assets/icons/info-icon-gray.svg" alt="" width="20">
                    <span v-html="upcomingText"></span>
                </div>
                <div class="seats-table seats-table--billing">
                    <div class="seats-table__row seats-table__row--header">
                        <div class="seats-table__col">{{ $t('team_dashboard_number_of_seats') }}</div>
                        <div class="seats-table__col">{{ $t('team_dashboard_price_per_seat') }}</div>
                        <div class="seats-table__col">{{ $t('team_dashboard_total') }}</div>
                    </div>
                    <div class="seats-table__row">
                        <div class="seats-table__col">
                            <div class="seats-selector" v-if="billing.editing">
                                <div class="seats-selector__wrap">
                                    <div class="seats-selector__select">
                                        <button type="button" class="seats-selector__minus" :disabled="billing.seats <= minSeats" @click="decSeats()">&mdash;</button>
                                        <input type="number"  v-model="billing.seats" :min="minSeats" :max="plan.billing.max_allowed_multi_seats">
                                        <button type="button" class="seats-selector__plus" @click="incSeats()" >+</button>  
                                    </div>
                                    <div class="seats-selector__total"></div>
                                </div>
                            </div>
                            <div class="seats-static" v-else>{{ user.features.seats.total_allowed_items }}</div>
                            
                        </div>
                        <div class="seats-table__col"><span class="price-bold">${{ seatPrice }}</span></div>
                        <div class="seats-table__col"><span class="price-bold">${{ (seatPrice * billing.seats).toFixed(2) }}</span></div>
                    </div>
                    
                    <div class="seats-table__row">
                        <div class="seats-table__col" v-if="billing.editing">
                            <div class="adding-actions" >
                                    <button type="button" class="adding-actions__confirm" :class="{ disabled: sameSeatsNumber}" :disabled="sameSeatsNumber" @click="confirmSeatsNumberChange"><span> &check;</span>Confirm</button>
                                    <a href="javascript:;" class="adding-actions__cancel" @click="cancelBillingEditing()">Cancel</a>    
                                </div>
                            </div>
                        <div class="seats-table__col" v-else><a href="javascript:;" class="edit-link" @click="setBillingEditing(true)">&#128393; {{ $t('common_edit') }}</a></div>
                    </div>
                </div>
                
                        
                <div class="cancel-wrap">
                    <a href="javascript:;" class="danger-btn" @click="cancelSubscription" v-if="canCancel">
                        <img src="@/assets/icons/sub-cancel-icon.svg" alt="Cancel Subscription">
                        {{ $t('profile_cancel_subscription')}}
                    </a>
                </div>
            </div>
          </div>
        </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapState } from 'vuex';
import ProductService from '@/services/products.service';
import MultiseatService from '@/services/multiseat.service';

export default {
  name: 'TeamDashboard',
  components: {
    
  },
  data() {
    return {
        tab: "manage",
        plan: false,
        seats: [],
        newSeat: {
            email: "",
            errors: {
                email: false
            }
        },
        adding: false,
        billing: {
            seats: 0,
            editing: false
        }
    }
  },
  methods: {
    openBeacon() {
      window.Beacon('open');
    },
    setTab(tab) {
      this.tab = tab;
    },
    incSeats() {
      this.billing.seats = this.billing.seats + 1;
    },
    decSeats() {
      this.billing.seats = this.billing.seats - 1;
    },
    setAdding(bool) {
        this.adding = bool;
    },
    cancelAdding() {
        this.setAdding(false);
        this.newSeat.email = "";
        this.newSeat.errors.email = false;
    },
    getSeats() {
        MultiseatService.getAssignedSeats(this.token).then((data) => {
            this.seats = data.data.result;
        })
    },
    getTeamPlans() {
      ProductService.getTeamPlans().then((data) => {
        this.plan = data.data.result.subscriptions[this.user.subscription.type];
        if (!this.plan.billing.is_multi_seats) {
             this.$router.push('profile');
        }
      }).catch((error) => {
        this.$router.push('profile');
        console.log(error);
      });
    },
    assignSeat() {
        MultiseatService.assignSeat(this.token, this.newSeat.email).then(() => {
           this.getSeats();
           this.cancelAdding();
        }).catch((error) => {
            if (error.response.data.error == "target_user_is_not_found") {
                this.$notify('team_dashboard_target_user_is_not_found', {
                    icon: "warning",
                    showCancelButton: true,
                    cancelButtonText: this.$t('common_cancel'),
                    confirmButtonText: this.$t('team_dashboard_invite_button'),
                    focusCancel: false,
                }).then((result) => {
                    if (result.isConfirmed) {
                        MultiseatService.sendInvite(this.token, this.newSeat.email).then(() => {
                            this.$notify('team_dashboard_invite_sent_success', {
                                icon: "success",
                                showCancelButton: false,
                                toast: true
                            })
                        }).catch((error) => {
                            this.$notify(error.response.data.error, {
                                icon: "error",
                                showCancelButton: false,
                                toast: true
                            })
                        });
                        this.cancelAdding();
                    } else {
                        this.cancelAdding();
                    }
                })
            } else if (error.response.data.error == "invalid_email") {
                this.newSeat.errors.email = true;
            } else if (error.response.data.error == 'target_user_already_has_subscription') {
                this.$notify('team_dashboard_target_user_already_has_subscription', {
                    icon: "error",
                    showCancelButton: false,
                    confirmButtonText: this.$t('common_ok'),
                });
                this.cancelAdding();
            } else if (error.response.data.error == 'target_user_is_not_activated') {
                this.$notify('team_dashboard_target_user_is_not_activated', {
                    icon: "error",
                    showCancelButton: false,
                    confirmButtonText: this.$t('common_ok'),
                });
                this.cancelAdding();
            }
        });
    },
    revokeSeat(seat) {
        this.$notify('team_dashboard_revoke_access_warning', {
            icon: "warning",
            showCancelButton: true,
            cancelButtonText: this.$t('common_cancel'),
            confirmButtonText: this.$t('common_yes'),
            focusCancel: true,
        }).then((result) => {
            if (result.isConfirmed) {
                MultiseatService.revokeSeat(this.token, seat).then(() => {
                    this.getSeats();
                    this.$notify('team_dashboard_revoke_access_success', {
                        icon: "success"
                    });

                })
            }
        });
        
    },
    setBillingEditing(bool) {
        this.billing.editing = bool;
    },
    cancelBillingEditing() {
        this.billing.editing = false;
        this.billing.seats = this.user.features.seats.total_allowed_items;
    },
    cancelSubscription() {
      if (this.user.subscription.payment_type != 'stripe' && this.user.subscription.payment_type.length > 0) {
        this.$notify('apple_subscription_notice', {
          icon: "warning",
        })
        return false;
      }

      this.$notify('profile_subscription_cancel_warning', {
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: this.$t('common_cancel'),
        confirmButtonText: this.$t('common_yes'),
        focusCancel: true,
      }).then((result) => {
        if (result.isConfirmed) {
            ProductService.cancel(this.token).then(() => {
              this.$notify('profile_subscription_cancel_success', {
                icon: "success"
              }).then(() => {
                location.reload();
              })
            })
        }
      })
    },
    confirmSeatsNumberChange() {
        MultiseatService.updateTeamPlan(this.token, this.plan.payment_types.stripe.prices.monthly.id, this.billing.seats).then((data) => {
          let result = data.data.result;
          if (result.downgrade) {
            this.$notify('plans_downgrade_success', {
              icon: "success",
              confirmReload: true
            })
          } else {
            location.href = result.url;
          }
        });
    }
  },
  computed: {
    ...mapState('auth', ['token', 'authorized']),
    ...mapState('nonpersist', ['user']),
    seatsAvailable() {
        return  this.user.features.seats.total_allowed_items - this.seats.length;
    },
    seatsTotal() {
       return this.user.features.seats.total_allowed_items; 
    },
    assignedSeats() {
        return this.seats.length;
    },
    seatPrice() {
        return (this.plan.payment_types.stripe.prices.monthly.price / 100).toFixed(2);
    },
    minSeats() {
        return this.plan.billing.min_allowed_multi_seats >= this.assignedSeats ? this.plan.billing.min_allowed_multi_seats : this.assignedSeats;
    },
    canCancel() {
      return !this.isCanceled;
    },
    isCanceled() {
      return this.user.subscription.upcoming_changes && this.user.subscription.upcoming_changes.type == 'basic';
    },
    upcomingText() {
      let planString =  "<b style='text-transform: capitalize;'>" + this.user.subscription.upcoming_changes.type + "</b>";
      if (this.user.subscription.upcoming_changes.seats) {
        planString += " " + "(" + this.user.subscription.upcoming_changes.seats + " " + this.$t('team_dashboard_seat_plural') +")";
      }
      return this.$t('profile_upcoming_changes', {
        plan:  planString,
        date: this.user.subscription.upcoming_changes.date
      });
    },
    sameSeatsNumber() {
        return this.billing.seats == this.user.features.seats.total_allowed_items;
    }
  },
  watch:{
     'billing.seats': function (newVal){
       
         if (newVal > this.plan.billing.max_allowed_multi_seats) {
          this.billing.seats = this.plan.billing.max_allowed_multi_seats;
         } else if (newVal < this.minSeats) {
          this.billing.seats = this.minSeats;
         }
     },
  },
  async mounted() {
    let hash = location.hash;
    if (hash) {
      hash = hash.substring(1);
      if (hash == 'billing') {
        this.tab = 'billing';
      }
    }

    await this.getSeats();
    await this.getTeamPlans();

    this.billing.seats = this.user.features.seats.total_allowed_items;
  },
  created() {
  }
}
</script>

<style lang="scss">
    .page-wrapper {
        padding-top: 5rem;
        display: flex;
        flex-direction: column;

        &--team-dashboard {
            width: 70%;
            margin: 0 auto;

            img {
                max-width: 57rem;
            }

            h1 {
                font-size: 3rem !important;
                margin: 0.3em 0;
            }
        }

        .about-text {

            p {
                margin: 30px 0 0;
                text-transform: uppercase;
                font-size: 1.25rem;
                line-height: 1.875rem;
                text-align: center;
                color: #000000;
            }
        }
    }

    .pricing {
      width: 100%;

        &-header {
            display: flex;
            justify-content: space-between;
            margin-bottom: 2rem;

            &__left {
                font-size: 1.25rem;
                line-height: 100%;
                font-weight: 400;
                color: #979797;
                display: flex;
                align-items: center;
            }

            &__right {
                font-weight: 400;
                font-size: 1.375rem;
                line-height: 150%;
                color: #3D485A;
                display: flex;

                .toggle-container {
                    margin: 0 1rem;
                }
            }
        }

        .buttons-row {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr;
            column-gap: 11px;

            .button-column {
                padding: 0 1.5rem 0;
            }
        }
        .subscribe-btn {
            background: #46E0D4;
            border-radius: 25px;
            color: #fff;
            text-transform: uppercase;
            font-size: 1rem;
            line-height: 1.2rem;
            display: flex;
            align-items: center;
            text-align: center;
            letter-spacing: 0.15em;
            height: 2.8rem;
            justify-content: center;
            font-weight: 700;
            margin: 11px auto 0;
            width: 80%;

            &:hover {
                filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
            }

            &--margined {
                margin-top: 25px;
            }
        }

        .current-selected {
          text-transform: uppercase;
          font-size: 1rem;
          line-height: 1.2rem;
          display: flex;
          align-items: center;
          text-align: center;
          letter-spacing: 0.15em;
          height: 2.8rem;
          justify-content: center;
          font-weight: 700;
          margin: 11px auto 0;
          width: 80%;

          &.current-selected--downgrade {
                text-transform: none;
                /* line-height: 1rem; */
                letter-spacing: 0.1em;
                width: 90%;
          }
        }

        &-footer {
            display: flex;
            justify-content: center;
            font-weight: 400;
            font-size: 1.6rem;
            line-height: 120%;
            /* identical to box height, or 30px */

            display: flex;
            align-items: center;
            text-align: center;
            font-feature-settings: 'pnum' on, 'lnum' on;

            color: #979797;
            margin-top: 6rem;
            padding-bottom: 2.25rem;

            a {
                font-weight: 700;
                font-size: 1.6rem;
                /* identical to box height, or 38px */

                display: flex;
                align-items: center;
                text-align: center;
                text-decoration-line: underline;

                /* Gold */

                color: #46e0d4;
                margin-left: 1rem;
            }
        }
    }
    :deep(.popper) {
        max-width: 200px !important;
        text-align: left !important;
        font-size: 0.8rem !important;
        line-height: 150% !important;
        font-weight: 400;
        padding: 1rem;
    }

    .tabs-controls {
      margin-bottom: 30px;
      display: flex;
      column-gap: 2.25rem;

      a {
        color: #000;
        font-weight: 700;
        font-size: 2.25rem;
        line-height: 100%;
        padding-bottom: 10px;
        border-bottom: 2px solid transparent;

        &.active {
          color: #46E0D4;
          border-color: #46E0D4;
        }
      }
    }

    .tabs-controls__subheader {
      
        margin-bottom: 30px;
        font-size: 1.25rem;
        line-height: 125%;
        width: 90%;
      
    }

    .contact-link {
      font-weight: 700;
      font-size: 1.6rem;
      display: flex;
      align-items: center;
      text-align: center;
      -webkit-text-decoration-line: underline;
      text-decoration-line: underline;
      color: #46e0d4;
      justify-content: center;
      margin-top: 1rem;
    }

    input[type=number] { 
        -moz-appearance: textfield;
        appearance: textfield;
        margin: 0; 
    }

    input[type=number]::-webkit-inner-spin-button,
    input[type=number]::-webkit-outer-spin-button { 
          -webkit-appearance: none; 
          margin: 0; 
    }

    .seats-selector {
      
      &__wrap {
        
        display: inline-flex;
       
      }

      &__select {
        position: relative;
        padding-left: 10px;
      }

      &__total {
        margin-left: 30px;
        display: flex;
        align-items: center;
        font-size: 1rem;
        font-weight: 700;
      }

      &__text {
        color: #979797;
        margin-bottom: 10px;
        font-size: 0.8rem;
      }

      button {
        background: #46e0d4;
        border: none;
        outline: none;
        border-radius: 50%;
        height: 30px;
        width: 30px;
        color: #fff;
        position: absolute;
        cursor: pointer;

        &.seats-selector__minus {
          left: -3px;
        }

        &.seats-selector__plus {
          right: -14px;
        }

        &:disabled, &[disabled] {
          opacity: 1;
          background: #979797;
        }
      }

      input {
          height: 30px;
          border: none;
          text-align: center;
          width: 80px;
          padding: 0 20px;
          border: 1px solid #46e0d4;

          &:focus {
            border: 2px solid #46e0d4;
            outline: none;
          }
      }
    }

    .seats-table {
        display: table;
        width: 65%;

        @media all and (max-width: 1400px) {
            width: 100%;
        }

        &__body {
            display: table;
            width: 100%;
        }

        &--billing {
            width: 600px;

            .seats-table__col {
                font-size: 1.2rem;
                height: 55px;
            }

            .seats-table__col:first-child {
                width: 225px;
            }

            .seats-table__col:last-child {
                width: 120px;
            }

            .adding-actions {
                margin-top: 0;
            }

        }

        &__header, &__row {
            display: table-row;
        }

        &__add {
            display: flex;
            column-gap: 12px;

            .seats-available {
                display: flex;
                align-items: center;
                color: #979797;
            }
        }

        &__col {
            display: table-cell;
            vertical-align: middle;
            padding: 12px 12px 12px 0;
            box-sizing: border-box;

            &--actions {
                text-align: center;
            }

            input[type="email"] {
                border: 2px solid #505050;
                border-radius: 10px;
                font-weight: 400;
                font-size: 1rem;
                line-height: 1.18rem;
                background: transparent;
                padding: 0 10px;
                height: 44px;
                width: 350px;
                outline: none;

                &.error {
                    border-color: red;
                }
            }
        }

        &__row--header {
            .seats-table__col {
                font-weight: 700;
                color: #979797;
                border-bottom: 1px solid #979797;
            }

            & + .seats-table__row .seats-table__col {
                padding-top: 30px;
            }
        }

        &__footer {
            margin-top: 12px;
            a {
                color: #46E0D4;
                font-weight: 700;
                display: flex;
                align-items: center;

                span {
                    font-size: 1.5rem;
                    margin-right: 8px;
                }
            }
        }
    }

    .adding-actions {
        display: flex;
        column-gap: 24px;
        margin-top: 12px;

        &__confirm {
            border: none;
            outline: none;
            color: #46E0D4;
            display: flex;
            align-items: center;
            background: none;
            cursor: pointer;
            font-weight: 700;
            
            span {
                font-size: 1.5rem;
                margin-right: 8px;
            }

            &.disabled {
                color: #979797;
            }
        }

        &__cancel {
            color: #000 !important;
            font-weight: 400 !important;
            display: flex;
            align-items: center;
        }
    }

    .revoke-button {
        color: #EA4335;
        font-weight: 700;
    }

    .status--revoked {
        color: #979797;
    }

    .price-bold {
        font-weight: 700;
    }

    .seats-static {
        font-weight: 700;
    }

    .edit-link {
        color: #46E0D4;
        font-weight: 700;
    }

    .cancel-wrap {
        margin-top: 60px;
        border-top: 2px solid #EA4335;
        padding-top: 20px;
        width: 600px;

    }

    .team-upcoming-changes {
        display: flex;
        align-items: center;
        margin-bottom: 30px;

        img {
            vertical-align: top;
            margin-right: 8px;
        }
    }

    .seat-status {
        display: flex;
        align-items: center;
        font-weight: 700;
        color: #979797;

        &::before {
            content: "";
            width: 8px;
            height: 8px;
            background: #979797;
            border-radius: 50%;
            margin-right: 6px;
        }

        &.active {
            color: green;
            
            
            &:before {
                background: green;
            }
        }
    }
</style>
