<template>
  <div class="overlay">
    <video-background 
            src="/media/output.mp4"
            style="height: 100%;"
        >
        <div class="overlay__bg"></div>
  </video-background>
  </div>
  <div class="home flex-centered">
    <div class="home__logo">
      <img alt="FORUM logo" class="center-logo" src="../assets/logo.svg">
      <div class="home__slogan" v-show="false">{{ $t('forum_slogan') }}</div>
    </div>
    <div class="home__actions">
      <router-link to="/join" class="orange-btn orange-btn--icon">
        <img src="../assets/icons/headphones.svg" alt="headphones">
        {{ $t('home_join')}}
      </router-link>
      <div class="home__actions-or">{{ $t('common_or')}}</div>
      <router-link to="/host" class="orange-link orange-link--icon">
        <img src="../assets/icons/mic.svg" alt="Mic">
        {{ $t('home_host')}}
      </router-link>
    </div>
  </div>
  <main-footer></main-footer>
  
</template>

<script>
// @ is an alias to /src
 import VideoBackground from 'vue-responsive-video-background-player';
 import MainFooter from '@/components/MainFooter.vue';

export default {
  name: 'HomeView',
  components: {
    VideoBackground,
    MainFooter
  }
}
</script>

<style lang="scss" scoped>
  
  .overlay {
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;

    &__bg {
      background: linear-gradient(180deg, #000000 0%, rgba(27, 8, 8, 0.02) 49.5%, #060101 96.62%);
      z-index: 1;
      height: 100%;
    }
  }

  .center-logo {
    width: 670px;

    @media all and (max-height: 800px) {
      max-width: 550px;
    }
  }

  .home__actions {
    display: flex;
    flex-direction: column;
    align-items: center;

    a:hover {
      opacity: 0.9;
    }

    @media all and (max-height: 800px) {
      .orange-btn {
        height: 70px;

        img {
          width: 45px;
        }
      }
    }

    
  }

  .home__actions-or {
    font-weight: 700;
    font-size: 1.25rem;
    line-height: 1.875rem;
    text-transform: uppercase;

    color: #C2C2C2;
    margin: 21px auto 10px;
  }

  .home__slogan {
    color: #fff;
    font-size: 1.8rem;
    text-align: center;
  }
</style>
