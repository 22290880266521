import api from '@/services/api.service';

class ProductService {

    getProducts() {
        return api
            .get('/product-get', {
                params: {
                    type: "individuals",
                    product_type: "subscription"
                }
            });
    }
    
    getProductsArray() {
        return api
            .get('/product-get', {
                params: {
                    type: "individuals",
                    product_type: "subscription",
                    format: "array"
                }
            });
    }

    getTeamPlans() {
        return api
            .get('/product-get', {
                params: {
                    type: "multi-seats",
                    product_type: "subscription"
                }
            });
    }

    getTeamProductsArray() {
        return api
            .get('/product-get', {
                params: {
                    type: "multi-seats",
                    product_type: "subscription",
                    format: "array"
                }
            });
    }


    getPlanById(type) {
        return api
            .get('/product-get', {
                params: {
                    type: "all",
                    product_type: "subscription",
                    tier: type
                }
            });

    }

    createOrder(token, price_id, seats = false) {
        let params = {
            token: token,
            price_id: price_id,
            success_url: 'payment_success?session_id={CHECKOUT_SESSION_ID}',
            cancel_url: 'payment_cancel?session_id={CHECKOUT_SESSION_ID}'
        };

        if (seats) {
            params.seats = seats;
        }
        return api.post('/payment-stripe-order-create', params)
    }

    cancel(token) {
        return api.post('/payment-stripe-cancel-subscription', {
            token: token,
        })
    }
    verifyOrder(token, session_id) {
        return api.post('/payment-stripe-order-verify', {
            token: token,
            session_id: session_id
        })
    }
    getSubscriptions() {
        return api
            .get('/subscription-get');
    }
}
export default new ProductService();