<template>
  <div class="page-wrapper page-wrapper--about">
    <svg class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
      <circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
      <path class="checkmark__check" fill="none" d="M16 16 36 36 M36 16 16 36" />
    </svg>
    <div class="about-text">
        <p>Payment process didn't complete.</p>
      <a href="javascript:;" class="subscribe-btn" @click="goPayment">Try again</a>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

import {mapState} from "vuex";
import ProductService from "@/services/products.service";

export default {
  name: 'PaymentCancel',
  components: {

  },
  data() {

  },
  methods: {
    goPayment() {
      if (!this.priceId) {
        return;
      }
      ProductService.createOrder(this.token, this.priceId).then((data) => {
        let result = data.data.result;
        //console.log(result);
        location.href = result.url;
      })
    }
  },
  computed: {
    ...mapState('auth', ['token', 'authorized', 'priceId']),
  }
}
</script>

<style lang="scss" scoped>
.checkmark__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: #FF0000;
  fill: none;
  animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}
.checkmark {
  width: 56px;
  height: 56px;
  border-radius: 50%;
  display: block;
  stroke-width: 2;
  stroke: #fff;
  stroke-miterlimit: 10;
  margin: 3% auto;
  box-shadow: inset 0px 0px 0px #FF0000;
  animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both;
}
.checkmark__check {
  transform-origin: 50% 50%;
  stroke-dasharray: 29;
  stroke-dashoffset: 29;
  animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}
@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes scale {
  0%, 100% {
    transform: none;
  }
  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}
@keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 30px #FF0000;
  }
}

.subscribe-btn {
  background: #46E0D4;
  border-radius: 25px;
  color: #fff;
  text-transform: uppercase;
  font-size: 1rem;
  line-height: 1.2rem;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.15em;
  height: 2.8rem;
  justify-content: center;
  font-weight: 700;
  margin: 45px auto 0;
  width: 80%;

  &:hover {
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  }
}
</style>
