import axios from 'axios'
import {Constants} from '@/cfg/constants'

export default axios.create({
    baseURL: Constants.API_URL,
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded'
    },
    transformRequest: [(data) => {
        let formData = new URLSearchParams();
        for ( let key in data ) {
            formData.append(key, data[key]);
        }

        return formData;
    }, ...axios.defaults.transformRequest]
 })