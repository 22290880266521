<template>
  <div class="overlay">
    <video-background 
            src="/media/output.mp4"
            style="height: 100%;"
        >
        <div class="overlay__bg"></div>
  </video-background>
  </div>
  <div class="home flex-centered">
    <div class="home__logo">
      <img alt="FORUM logo" class="center-logo" src="../assets/logo.svg">
      <div class="home__slogan" v-show="false">Interpretation for standing ovations</div>
    </div>
    <div class="home__text">
      <p>
        The world is ready for your ideas. Experience interpreted meetings, events, and conferences.
      </p>
      <p>
        Forum app allows users to present and converse with their guests, regardless of what language they speak.
      </p>
    </div>
    <div class="home__actions">
      <a href="https://apps.apple.com/app/id1612322502">
        <img src="@/assets/appstore.svg" alt="Get it on the App Store" width="200">
      </a>
      <a href="https://play.google.com/store/apps/details?id=com.waverlylabs.audience.translate&pli=1">
        <img src="@/assets/googleplay.svg" alt="Get it on Google Play" width="200">
      </a>
    </div>
  </div>
  <mobile-footer></mobile-footer>
  
</template>

<script>
// @ is an alias to /src
 import VideoBackground from 'vue-responsive-video-background-player';
 import MobileFooter from '@/components/mobile/MobileFooter.vue';

export default {
  name: 'MobileView',
  components: {
    VideoBackground,
    MobileFooter
  }
}
</script>

<style lang="scss" scoped>
  
  .overlay {
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;

    &__bg {
      background: linear-gradient(180deg, #000000 0%, rgba(27, 8, 8, 0.02) 49.5%, #060101 96.62%);
      z-index: 1;
      height: 100%;
    }
  }

  .center-logo {
    max-width: 100%;
  }

  .home__actions {
    display: flex;
    flex-direction: column;
    align-items: center;

    a {
      margin-top: 15px;

      &:first-child {
        margin-top: 0;
      }
    }
  }

  .home__actions-or {
    font-weight: 700;
    font-size: 1.25rem;
    line-height: 1.875rem;
    text-transform: uppercase;

    color: #C2C2C2;
    margin: 21px auto;
  }

  .home__logo {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .home__slogan {
    font-weight: 500;
    font-size: 20px;
    line-height: 14px;
    /* identical to box height */

    text-transform: uppercase;

    color: #D9D9D9;
        margin-top: 20px;

    @media screen and (max-width: 800px) {
      font-size: 14px;
      margin-top: 10px;
    }
  }

  .home__text {

    display: flex;
    flex-direction: column;
    width: 75%;
    
    p {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 400;
      
      /* or 150% */

      text-align: center;
      text-transform: uppercase;

      color: #FFFFFF;
      font-size: 24px;
    line-height: 36px;

      @media screen and (max-width: 800px) {
        font-size: 13px;
        line-height: 19px;
      }
    }
  }
</style>
