<template>
    <div class="room-info-popup">
      <div class="room-code-wrap">
        <v-otp-input
          ref="roomCodeInput"
          input-classes="roomcode-input"
          separator=""
          :num-inputs="6"
          :should-auto-focus="true"
          :is-input-num="true"
          :is-disabled="true"
          :conditionalClass="['num_0', 'num_1', 'num_2', 'num_3', 'num_4', 'num_5']"
          :placeholder="['', '', '', '', '', '']"
        />
        <div class="copy-clipboard">
          <a href="javascript:;" class="orange-link text-transform-none" @click="clipboardCopy"><img src="@/assets/icons/clipboard.svg"/>{{ $t('code_copy_clipboard')}}</a>
        </div>
        <div class="code-info" :class="status">{{ statusText }}</div>
        <div class="room-qr-code">
          <qrcode-vue :value="qr_link" :size="200" level="H" :margin="2"/>
        </div>
        <div class="margined-submit">
            <a href="javascript:;" class="orange-btn form-btn" @click="selfClose">{{ $t('common_done')}}</a>
          </div>
      </div>
    </div>   
  
</template>

<script>
// @ is an alias to /src
import { mapState } from 'vuex';

import VOtpInput from 'vue3-otp-input';
import QrcodeVue from 'qrcode.vue'
import QRService from "@/services/qr.service";

export default {
  name: 'EventStart',
  data() {
    return {
      status: "",
      statusText: "",
      guestProfileState: false,
    }
  },
  props: {
    room_code: {
      type: String,
      required: true
    }
  },
  components: {
    VOtpInput,
    QrcodeVue
  },
  methods: {
    populateCodeInputs() {
      if (this.room_code) {
        const splitCode = this.room_code.split('');
        splitCode.forEach((n, i) => {
          document.querySelector('.num_' + i).value = n;
        });
      }
    },
    clipboardCopy() {
       navigator.clipboard.writeText(this.room_code);
      this.$notify('code_copy_clipboard_complete', {
        icon: "success",
        toast: true
      });
    },
    selfClose() {
      this.$emit('closed');
    }
  },
  computed: {
     ...mapState('event', ['event', 'guest']),
     qr_link() {
      return QRService.generateQRLink(this.room_code);
     }
  },
  mounted() {
    this.populateCodeInputs();
  }
}
</script>

<style lang="scss">
  .room-info-popup {
    background: #505050;
    border-radius: 35px;
    width: 550px;
    padding: 60px;
    position: absolute;
    z-index: 5;

    @media all and (max-width: 800px) {
      width: 100%;
      height: 100%;
      padding: 0;
      align-items: center;
        justify-content: center;
        border-radius: 0;
        display: flex;
    }
    
    .room-code-wrap {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      

      @media all and (max-width: 800px) {
        
      }
    }

    .roomcode-input {
      width: 55.21px;
      height: 71.4px;
      font-weight: 400;
      font-size: 3.125rem;
      border-radius: 14.5712px;
      border-width: 2px;
    }
  }

  @media all and (max-height: 800px) {
    .room-info-popup {
      padding: 30px;
    }
  }
</style>
