export const locale = {
    namespaced: true,
    state: {
        locale: "en",
        availableLocales: {
            "en": "en-US",
            "es": "es-ES",
            "it": "it-IT",
            "ru": "ru-RU",
            "pt-br": "pt-BR"
        },
        plansMapping: {
            basic: "Free",
            starter: "Personal",
            professional: "Professional",
            premium: "Premium",
            teams: "Teams",
            organizations: "Organizations",
            teams_participant: "Teams participant",
            organizations_participant: "Organizations participant"
        }
    },
    actions: {
        set({ commit }, locale) {
            commit('setLocale', locale);
        },
    },
    getters: {

    },
    mutations: {
        setLocale(state, locale) {
            state.locale = locale;
        }
    }
}