<template>
  <div class="page-wrapper page-wrapper--about">
    <div class="circle-loader" :class="{ loadcomplete: success}">
      <div class="checkmark draw" v-if="success"></div>
    </div>
    <div class="about-text">
        <p v-if="!success">Verifying your payment...</p>
        <p v-else>Thank you for your purchase! <br> Your Plan is updated.</p>
        <p v-if="success">You will be now redirected to your Profile.</p>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import ProductService from "@/services/products.service";
import {mapState} from "vuex";

export default {
  name: 'PaymentSuccess',
  components: {

  },
  data() {
    return {
      success: false
    }
  },
  computed: {
    ...mapState('auth', ['token', 'authorized']),
    ...mapState('nonpersist', ['user']),
  },
  mounted() {
    const session_id = this.$route.query.session_id;
    if (session_id) {
      ProductService.verifyOrder(this.token, session_id).then(() => {
        this.success = true;
        setTimeout(() => {
          location.href = '/profile';
        }, 2000)
      }).catch(() => {

      })
    }
  }
}
</script>

<style lang="scss" scoped>
// Define vars we'll be using
$brand-success: #5cb85c;
$loader-size: 7em;
$check-height: $loader-size/2;
$check-width: $check-height/2;
$check-left: ($loader-size/6 + $loader-size/12);
$check-thickness: 3px;
$check-color: $brand-success;

  .page-wrapper {
    padding-top: 5rem;
    display: flex;
    flex-direction: column;

    &--about {
      justify-content: center;
      align-items: center;
      width: 70%;
      margin: 0 auto;

      img {
        max-width: 57rem;
      }
    }

    .about-text {

      p {
        margin: 30px 0 0;
        text-transform: uppercase;
        font-size: 1.25rem;
        line-height: 1.875rem;
        text-align: center;
        color: #000000;
      }
    }
  }

  .circle-loader {
    margin-bottom: $loader-size/2;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-left-color: $check-color;
    animation: loader-spin 1.2s infinite linear;
    position: relative;
    display: inline-block;
    vertical-align: top;
    border-radius: 50%;
    width: $loader-size;
    height: $loader-size;
  }

  .loadcomplete {
    -webkit-animation: none;
    animation: none;
    border-color: $check-color;
    transition: border 500ms ease-out;
  }

  .checkmark {
    &.draw:after {
      animation-duration: 800ms;
      animation-timing-function: ease;
      animation-name: checkmark;
      transform: scaleX(-1) rotate(135deg);
    }

    &:after {
      opacity: 1;
      height: $check-height;
      width: $check-width;
      transform-origin: left top;
      border-right: $check-thickness solid $check-color;
      border-top: $check-thickness solid $check-color;
      content: '';
      left: $check-left;
      top: $check-height;
      position: absolute;
    }
  }

  @keyframes loader-spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes checkmark {
    0% {
      height: 0;
      width: 0;
      opacity: 1;
    }
    20% {
      height: 0;
      width: $check-width;
      opacity: 1;
    }
    40% {
      height: $check-height;
      width: $check-width;
      opacity: 1;
    }
    100% {
      height: $check-height;
      width: $check-width;
      opacity: 1;
    }
  }
</style>
