<template>
  <custom-scrollbar  class="profile-scroll" :class="{visible: selectorExpanded}" :autoHide="false">
    <div class="profile-settings">
            <div class="settings-item" v-if="transcripts">
                <div class="tts-settings">
                    <a :href="generateLink(item)" target="_blank" class="table-item table-item--link" v-for="item in transcripts" :key="item.title">
                      <div class="table-item__left">
                        <span>{{ item.title }}</span>
                      </div>
                      <div class="table-item__right">
                        <span style="text-transform: capitalize;">{{ formatDate(item.timestamp) }}</span>
                      </div>
                    </a>
                </div>    
            </div>
            <div class="settings-item" v-else>{{ $t('profile_no_transcripts') }}</div>
          </div>
  </custom-scrollbar>
</template>

<script>
// @ is an alias to /src
import { mapState } from 'vuex';
import TranscriptsService from "@/services/transcripts.service";
import moment from 'moment';

export default {
  name: 'TranscriptsPage',
  props: {

  },
  components: {

  },
  data() {
    return {
      transcripts: []
    }
  },
  methods: {
    formatDate(date) {
      return moment(Date.parse(date)).format("DD/MM/YYYY");
    },
    generateLink(item) {
      return TranscriptsService.getTranscriptUrl(item, this.token);
    }
  },
  computed: {
    ...mapState('auth', ['token', 'authorized', 'agreed']),
  },
  async mounted() {
    await TranscriptsService.getSessionsList({ token: this.token}).then((data) => {
      this.transcripts = data.data.result.rooms;
    });
  }
}
</script>

<style lang="scss">

  
</style>
