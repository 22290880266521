<template>
  <div class="overlay">
    <video-background 
            src="/media/output.mp4"
            style="height: 100%;"
        >
        <div class="overlay__bg"></div>
  </video-background>
  </div>
  <div class="signin flex-centered">
    <div class="signup__title">
      <h1 class="heading_1">{{ heading }}</h1>
      <div class="signup__email" v-if="user.email && resetSuccess">{{ user.email }}</div>
    </div>
    
    <div class="signin__container padded-top-block orange-bordered">

      <div class="signup-form form form-default" v-if="!resetSuccess">
        <form action="#" method="POST" @submit.prevent="formRequest">
          <div class="form-row">
            <label for="#signin_email">{{ $t('profile_email') }}</label>
            <input type="email" required name="email" v-model="user.email" class="form-input form-input--text" placeholder="" id="signin_email">
          </div>
          
          <button type="submit" name="forgot_submit" id="forgot_submit" class="orange-btn margined-submit form-btn" value="Reset Password">{{ $t('profile_reset_password') }}</button>
          <div class="or-divider">{{ $t('common_or') }}</div>
          <router-link to="/signin" class="orange-link orange-link--inline">{{ $t('signin_title') }}</router-link>
        </form>
      </div>
      <div class="email-confirmation" v-else>
        <div class="email-confirmation__text"><svg width="16" height="22" viewBox="0 0 16 22" fill="none" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
          <rect width="16" height="22" fill="url(#pattern0)"/>
          <defs>
          <pattern id="pattern0" patternContentUnits="objectBoundingBox" width="1" height="1">
          <use xlink:href="#image0_371_438" transform="translate(0 0.136364) scale(0.0104167 0.00757576)"/>
          </pattern>
          <image id="image0_371_438" width="96" height="96" xlink:href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGAAAABgCAYAAADimHc4AAAABmJLR0QA/wD/AP+gvaeTAAAFNElEQVR4nO2cTYgcVRSFz2uFUTdBRndCMCDEZGeIK1ciSOJOGEUQjIiBIOoqutSlxlUEk6jEjCs1Iy7EKCK4cmNw6SAoMwgu86MuNBNw5nPxprGnrequqr7vp3reBwNDd/V7p+6tPlV16/WVCoVCoVAwBDgIHEitow8MAo27JOmJQGMXpgGsAj+l1rEr2bafIcWGphDCgpZG/i82FJtt+xlSbCgmY/ZTbKgB1ha0VPFasaFYjNlPsaGY1NhPsaEpWFpQlf0MKTYUmhr7KTYUgyn2U2xoAlYWNMl+hhQbCsUU+yk2FJKG9lNsKATbwb/YIgEXgYMZ6D4MHE6toxPbQX+9oe3UsQacBh5KtA/LwIUUc3fCKOhZJANYBG4AG8DdMebsROCgJ0sG8MrIfCdDzdOJREGvwzwZwABYH5njV+AWq/G7isop6HWYJAN4rGLso1ax7MJA0g1Jv2//5cqGvL7rM47zQsPX0gDsA14Gvot4dNexiv9mmtw74Pdts2KeLeA+izlMIU0yTIM+tj9vTZj3lPV8phA2GcGCPqL/duDqBA3XgTtCzW+KUTKCB31M87MNNB2LocUUelKKAC430HY5ti4TgP0tEhC9GAc82EJfb+tDTe4hfkykbblFAqLXh6weyKwYbWMKsCjpyRYfeYrI9SGrBHzcYJvoCZD0nKTbWmy/IKl/J2Npqg1Ftx/+X/dpStT6kOWylElHeIqj/4ikezt8bq+kR421hIfJV0Mprn6+7HD0D7kUW68JVNtQCvupq/s0JVp9yHpxbpXVpLCfE5pt35yk5420xINqG4pqP0yv+zSlP/WhUdhpQynsp0ndpynBL0lD/ERppeb/WJwwHOtFw7HiwE4bilXtXACWCFMu/wE4Tp/sCG9Dwe0Hf7XzBnAlQODH+QP/XHpf6P2aGXyt/7VAYw+AR/Cl7X8iBH6cTeAb/Dcu7YqKOvDPCUzr/vgFVSfxKyRyYQ2vadFyX7MCOAS8C/yVNNST2cB/I5MstTSHsCfV0LQ6abvQwWwD/gR3XL6MfFdiObPyp6QPJZ12zq3XbZQ8AcBA0sPygX9cUp4ntu5sSfpW0nuSPnPObY6+mSwBwB75hx8vScr/0s6GdflEnHfOXZUSJAA4JH+0Py2pPzc3ttyU9Lmkt0M1bCo0pFhQXNJb0DjlJJwRu/EyNEvYRTdi2UMpReQBsAe/Kju3YtyrQN/tsjmUcnQ+UB7I5AHlkeROSNg7mmY/xmjK96H1zmPv6DOZjlVJkBsxYFXSwDl3f4jxp8y9IOk3SbOu878m6R7n3Mbsquox/wbgnwUfkLQ/hQ05525KWjYY6nzo4Evz2zv6HUmbU7eqZ0vSOSMtcSGT3tHApRlOvl+k0j0TZNQ7Gjg6QwKOxNI5z72jv5L0S4fPrUv62lhLLTES0OZXimY455D0foePnnXObVnrCU6N/aS2oTtpV0X9mz6ucmN6y4Jk3RKBCy0SMPeN/KJ3SwQeaKEv71YFHYOePBk0qw8Fr/t0wjjoSZIBHGug4ZkQc0+jyVVQjJ5yVj3h6vhI0pUJ71+T9Emgue3AtnNW7KZNpyZoeTOGBlM6JiNq0Mf07qX6EeYm0KWlQT6Q8WXomM6q+lA/2xKMQ2a9Iqqguj4Ure4THDLpFVEH4ICfR7St4ZdGJmNee0VUUlEf6mfdp44aG8rCfobwX32on3WfaZC4V0QT8PWhD1LrkML3ivg0wPgWnJF0NrWIIJCgV0RhDCL1ipgHbg007ookAo09V4RMQKFQKBQKhQn8C5/YpNe3Fg3fAAAAAElFTkSuQmCC"/>
          </defs>
          </svg>
          {{ $t('email_confirmation_message') }}
        </div>
        <router-link to="/signin" class="email-confirmation__resend orange-link text-transform-none">{{ $t('signin_title') }}</router-link>
      </div>
    </div>
  </div>
  <main-footer></main-footer>
  
</template>

<script>
// @ is an alias to /src
import VideoBackground from 'vue-responsive-video-background-player';
import MainFooter from '@/components/MainFooter.vue';
import AuthService from '../services/auth.service';

export default {
  name: 'ForgotPwdView',
  components: {
    VideoBackground,
    MainFooter
  },
  data() {
    return {
      user: {},
      errors: {},
      heading: this.$t('profile_reset_password'),
      resetSuccess: false
    }
  },
  methods: {
    formRequest() {
      AuthService.resetPassword({
        email: this.user.email
      }).then(
        () => {
          this.$notify("profile_link_resent", {
            icon: "success",
            toast: true
          });
          this.resetSuccess = true;
        },
        (error) => {
          this.$notify(error.response.data.error, {
            icon: "error",
            toast: true
          });
        }
      );
    },
  },
  computed: {
  }
}
</script>

<style lang="scss">
  

</style>
