<template>
  <footer class="main-footer">
    <div class="footer-left">
      <a href="https://sorenson.com/legal/terms-of-use/" target="_blank">{{ $t('terms_of_use')}}</a>
      <a href="https://sorenson.com/legal/privacy-policy/" target="_blank">{{ $t('support_privacy_title')}}</a>
    </div>
    <div class="footer-center" v-if="displayCite">
      <div class="footer-center__text">
        <p class="footer__cite-text" v-html="$t('home_footer_cite')"></p>
        <router-link to="/host" class="orange-link footer__cite-author">{{ $t('home_footer_cite_sub') }}</router-link>
      </div>
    </div>
    <div class="footer-right">
      <a href="https://sorenson.com/" target="_blank">Sorenson</a>
    </div>
  </footer>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'MainFooter',
  components: {
    
  },
  computed: {
    displayCite() {
      return this.$route.name == 'home'
    }
  }
}
</script>

<style lang="scss">
  .main-footer {
    display: flex;
    justify-content: space-between;
    padding: 0 30px;
    align-items: flex-end;
    padding-bottom: 15px;
    position: relative;

    .footer-left, .footer-right {
      position: relative;
      z-index: 2;
    }

    .footer-left {
      display: flex;
      flex-direction: column;
    }

    a {
      text-transform: uppercase;

      &:hover {
        opacity: 0.8;
      }
    }

    .footer-left a {
      font-weight: 400;
      font-size: 1.25rem;
      line-height: 1.875rem;
      color: #767575;
    }

    .footer-center {
      justify-content: center;
      display: flex;
      text-align: center;
      position: absolute;
      width: 100%;
      left: 0;

      &__text {
        margin-bottom: 17px;
      }
    }

    .footer-right a {
      font-weight: 700;
      font-size: 1.25rem;
      line-height: 1.875rem;
      color: #706D6D;
    }
  }

  .footer__cite-text {
    font-weight: 400;
    font-size: 1rem;
    line-height: 24px;
    letter-spacing: 0.10em;
    color: #FFFFFF;
  }

  .footer__cite-author {
    font-weight: 400;
    font-size: 0.93rem;
    line-height: 1.375rem;
    /* identical to box height */
    letter-spacing: 0.10em;
    color: #FFFFFF;
    justify-content: center;
  }

  @media all and (max-width: 800px) {
    .main-footer {
      flex-direction: column;
      align-items: flex-start;
      padding: 16px;

      .footer-left {
        display: none;
      }
    }
  }
</style>
