<template>
  <div class="page-wrapper page-wrapper--team-dashboard">
    <div class="pricing">
        <div class="pricing-header">
             <h1>{{ $t('glossary_title') }}</h1>
        </div>
        <div class="pricing-tabs">
          
          <div class="manage-seats">
                <h2>{{ $t('glossary_terms') }}</h2>
                <div class="seats-table">
                    <div class="seats-table__empty">
                        <p v-html="$t('glossary_help')"></p>
                    </div>
                    <div class="seats-table__body"  v-if="terms && terms.length > 0">
                        <div class="seats-table__row seats-table__row--header">
                            <div class="seats-table__col">{{ $t('glossary_language_column')}}</div>
                            <div class="seats-table__col">{{ $t('glossary_term_column')}}</div>
                            <div class="seats-table__col seats-table__col--actions">{{ $t('team_dashboard_actions_column') }}</div>
                        </div>
                        <div class="seats-table__row" v-for="term in terms" :key="term.id">
                            <div class="seats-table__col">
                                <div class="lang-span">
                                    <img :src="'/data/flags/' + getLangFlag(term.language) + '.png'"/>
                                    <span>{{ getLangTitle(term.language) }}</span>
                                </div>
                            </div>
                            <div class="seats-table__col">{{ term.term }}</div>
                            <div class="seats-table__col seats-table__col--actions">
                                <a href="javascript:;" class="revoke-button" @click="deleteTerm(term.id)" >	&#10060; {{ $t('commont_delete') }}</a>
                            </div>
                        </div>
                    </div>
                    
                    <div class="term-adding" v-if="adding">
                            <form action="#" @submit.prevent="addTerm" method="POST">
                                <div class="form-inner">
                                    <div class="lang-selector-wrap">
                                        <label>Select language</label>
                                        <language-selector-light @select="setLang"></language-selector-light>
                                    </div>
                                    <div class="word-input">
                                        <label>{{ $t('glossary_field_placeholder') }}</label>
                                        <input type="text" class="email-input" :placeholder="$t('glossary_field_placeholder')" required v-model="newTerm.text" :class="{error: newTerm.errors.text}">
                                    </div>
                                </div>
                                <div class="adding-actions">
                                    <button type="submit" class="adding-actions__confirm"><span> &check;</span>{{ $t('common_confirm') }}</button>
                                    <a href="javascript:;" class="adding-actions__cancel" @click="cancelAdding()">{{ $t('common_cancel') }}</a>    
                                </div>
                            </form>
                    </div>
                    <div class="seats-table__footer"  v-if="!adding">
                        <div class="seats-table__col">
                            <div class="seats-table__add">
                                <a href="javascript:;" @click="setAdding(true)">
                                    <span class="plus-add">+</span>
                                    {{ $t('glossary_add') }}</a>
                                <span class="seats-available"> {{ $t('common_available') }}: {{ termsAvailable }}</span>
                            </div>
                            
                        </div>
                        
                    </div>
                </div>
              </div>
        </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapState } from 'vuex';
import GlossaryService from '@/services/glossary.service';
import LanguageSelectorLight from '@/components/LanguageSelectorLight.vue';

export default {
  name: 'GlossaryView',
  components: {
    LanguageSelectorLight
  },
  data() {
    return {
        plan: false,
        terms: [],
        newTerm: {
            text: "",
            language: "all",
            errors: {
                text: false
            }
        },
        adding: false,
    }
  },
  methods: {
    openBeacon() {
      window.Beacon('open');
    },
    setAdding(bool) {
        this.adding = bool;
    },
    cancelAdding() {
        this.setAdding(false);
        this.newTerm.text = "";
        this.newTerm.errors.text = false;
    },
    getTerms() {
        GlossaryService.getTerms(this.token).then((data) => {
            this.terms = data.data.result;
        })
    },
    addTerm() {
        GlossaryService.addTerm(this.token, this.newTerm.text, this.newTerm.language).then(() => {
           this.getTerms();
           this.cancelAdding();
        }).catch((error) => {
            console.log(error);
        });
    },
    deleteTerm(term_id) {
        this.$notify('glossary_term_delete_warn', {
            icon: "warning",
            showCancelButton: true,
            cancelButtonText: this.$t('common_cancel'),
            confirmButtonText: this.$t('common_yes'),
            focusCancel: true,
        }).then((result) => {
            if (result.isConfirmed) {
                GlossaryService.deleteTerm(this.token, term_id).then(() => {
                    this.getTerms();
                    this.$notify('glossary_term_delete_success', {
                        icon: "success"
                    });

                })
            }
        });
        
    },
    getLangFlag(lang_shortcode) {
        if (lang_shortcode != 'all') {
            const lang_found = this.languages.find(element => element.shortcode == lang_shortcode || element.code == lang_shortcode);
            return lang_found.code;
        } else {
            return 'all';
        } 
    },
    getLangTitle(lang_shortcode) {
        if (lang_shortcode != 'all') {
            const lang_found = this.languages.find(element => element.shortcode == lang_shortcode || element.code == lang_shortcode);
            return lang_found.name;
        } else {
            return this.$t('glossary_all_lang');
        }  
    },
    setLang(lang) {
        let selected = lang.shortcode;
        if (lang.shortcode == 'zh') {
            selected = lang.code;
        }
       this.newTerm.language = selected;
    }
  },
  computed: {
    ...mapState('auth', ['token', 'authorized']),
    ...mapState('nonpersist', ['user']),
    ...mapState('lang', ['languages']),
    termsAvailable() {
        return  this.user.features.custom_terms.total_allowed_items - this.terms.length;
    },
    termsTotal() {
       return this.user.features.custom_terms.total_allowed_items; 
    },
    addedTerms() {
        return this.terms.length;
    },
  },
  watch:{
    
  },
  async mounted() {
    await this.getTerms();
  },
  created() {
  }
}
</script>

<style lang="scss">
    .pricing-header {
        h1 {
            font-weight: 700;
            font-size: 2.25rem;
            line-height: 100%;
            color: #46E0D4;
        }
    }

    .page-wrapper {
        padding-top: 5rem;
        display: flex;
        flex-direction: column;

        &--team-dashboard {
            width: 70%;
            margin: 0 auto;

            img {
                max-width: 57rem;
            }

            h1 {
                font-size: 3rem !important;
                margin: 0.3em 0;
            }
        }

        .about-text {

            p {
                margin: 30px 0 0;
                text-transform: uppercase;
                font-size: 1.25rem;
                line-height: 1.875rem;
                text-align: center;
                color: #000000;
            }
        }
    }

    .pricing {
      width: 100%;

        &-header {
            display: flex;
            justify-content: space-between;
            margin-bottom: 2rem;

            &__left {
                font-size: 1.25rem;
                line-height: 100%;
                font-weight: 400;
                color: #979797;
                display: flex;
                align-items: center;
            }

            &__right {
                font-weight: 400;
                font-size: 1.375rem;
                line-height: 150%;
                color: #3D485A;
                display: flex;

                .toggle-container {
                    margin: 0 1rem;
                }
            }
        }

        .buttons-row {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr;
            column-gap: 11px;

            .button-column {
                padding: 0 1.5rem 0;
            }
        }
        .subscribe-btn {
            background: #46E0D4;
            border-radius: 25px;
            color: #fff;
            text-transform: uppercase;
            font-size: 1rem;
            line-height: 1.2rem;
            display: flex;
            align-items: center;
            text-align: center;
            letter-spacing: 0.15em;
            height: 2.8rem;
            justify-content: center;
            font-weight: 700;
            margin: 11px auto 0;
            width: 80%;

            &:hover {
                filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
            }

            &--margined {
                margin-top: 25px;
            }
        }

        .current-selected {
          text-transform: uppercase;
          font-size: 1rem;
          line-height: 1.2rem;
          display: flex;
          align-items: center;
          text-align: center;
          letter-spacing: 0.15em;
          height: 2.8rem;
          justify-content: center;
          font-weight: 700;
          margin: 11px auto 0;
          width: 80%;

          &.current-selected--downgrade {
                text-transform: none;
                /* line-height: 1rem; */
                letter-spacing: 0.1em;
                width: 90%;
          }
        }

        &-footer {
            display: flex;
            justify-content: center;
            font-weight: 400;
            font-size: 1.6rem;
            line-height: 120%;
            /* identical to box height, or 30px */

            display: flex;
            align-items: center;
            text-align: center;
            font-feature-settings: 'pnum' on, 'lnum' on;

            color: #979797;
            margin-top: 6rem;
            padding-bottom: 2.25rem;

            a {
                font-weight: 700;
                font-size: 1.6rem;
                /* identical to box height, or 38px */

                display: flex;
                align-items: center;
                text-align: center;
                text-decoration-line: underline;

                /* Gold */

                color: #46e0d4;
                margin-left: 1rem;
            }
        }
    }
    :deep(.popper) {
        max-width: 200px !important;
        text-align: left !important;
        font-size: 0.8rem !important;
        line-height: 150% !important;
        font-weight: 400;
        padding: 1rem;
    }

    .tabs-controls {
      margin-bottom: 30px;
      display: flex;
      column-gap: 2.25rem;

      a {
        color: #000;
        font-weight: 700;
        font-size: 2.25rem;
        line-height: 100%;
        padding-bottom: 10px;
        border-bottom: 2px solid transparent;

        &.active {
          color: #46E0D4;
          border-color: #46E0D4;
        }
      }
    }

    .tabs-controls__subheader {
      
        margin-bottom: 30px;
        font-size: 1.25rem;
        line-height: 125%;
        width: 90%;
      
    }

    .contact-link {
      font-weight: 700;
      font-size: 1.6rem;
      display: flex;
      align-items: center;
      text-align: center;
      -webkit-text-decoration-line: underline;
      text-decoration-line: underline;
      color: #46e0d4;
      justify-content: center;
      margin-top: 1rem;
    }

    input[type=number] { 
        -moz-appearance: textfield;
        appearance: textfield;
        margin: 0; 
    }

    input[type=number]::-webkit-inner-spin-button,
    input[type=number]::-webkit-outer-spin-button { 
          -webkit-appearance: none; 
          margin: 0; 
    }

    .seats-selector {
      
      &__wrap {
        
        display: inline-flex;
       
      }

      &__select {
        position: relative;
        padding-left: 10px;
      }

      &__total {
        margin-left: 30px;
        display: flex;
        align-items: center;
        font-size: 1rem;
        font-weight: 700;
      }

      &__text {
        color: #979797;
        margin-bottom: 10px;
        font-size: 0.8rem;
      }

      button {
        background: #46e0d4;
        border: none;
        outline: none;
        border-radius: 50%;
        height: 30px;
        width: 30px;
        color: #fff;
        position: absolute;
        cursor: pointer;

        &.seats-selector__minus {
          left: -3px;
        }

        &.seats-selector__plus {
          right: -14px;
        }

        &:disabled, &[disabled] {
          opacity: 1;
          background: #979797;
        }
      }

      input {
          height: 30px;
          border: none;
          text-align: center;
          width: 80px;
          padding: 0 20px;
          border: 1px solid #46e0d4;

          &:focus {
            border: 2px solid #46e0d4;
            outline: none;
          }
      }
    }

    .seats-table {
        display: table;
        width: 65%;

        @media all and (max-width: 1400px) {
            width: 100%;
        }

        &__body {
            display: table;
            width: 100%;
        }
        &__header, &__row {
            display: table-row;
        }

        &__add {
            display: flex;
            column-gap: 12px;

            .seats-available {
                display: flex;
                align-items: center;
                color: #979797;
            }
        }

        &__col {
            display: table-cell;
            vertical-align: middle;
            padding: 12px 12px 12px 0;
            box-sizing: border-box;

            &--actions {
                text-align: center;
            }

            
        }

        &__row--header {
            .seats-table__col {
                font-weight: 700;
                color: #979797;
                border-bottom: 1px solid #979797;
            }

            & + .seats-table__row .seats-table__col {
                padding-top: 30px;
            }
        }

        &__footer {
            margin-top: 12px;
            a {
                color: #46E0D4;
                font-weight: 700;
                display: flex;
                align-items: center;

                span {
                    font-size: 1.5rem;
                    margin-right: 8px;
                }
            }
        }

        input[type="text"] {
                border: 2px solid #505050;
                border-radius: 10px;
                font-weight: 400;
                font-size: 1rem;
                line-height: 1.18rem;
                background: transparent;
                padding: 0 10px;
                height: 44px;
                width: 350px;
                outline: none;

                &.error {
                    border-color: red;
                }
            }
    }

    .adding-actions {
        display: flex;
        column-gap: 24px;
        margin-top: 12px;

        &__confirm {
            border: none;
            outline: none;
            color: #46E0D4;
            display: flex;
            align-items: center;
            background: none;
            cursor: pointer;
            font-weight: 700;
            
            span {
                font-size: 1.5rem;
                margin-right: 8px;
            }

            &.disabled {
                color: #979797;
            }
        }

        &__cancel {
            color: #000 !important;
            font-weight: 400 !important;
            display: flex;
            align-items: center;
        }
    }

    .revoke-button {
        color: #EA4335;
        font-weight: 700;
    }

    .status--revoked {
        color: #979797;
    }

    .price-bold {
        font-weight: 700;
    }

    .seats-static {
        font-weight: 700;
    }

    .edit-link {
        color: #46E0D4;
        font-weight: 700;
    }

    .cancel-wrap {
        margin-top: 60px;
        border-top: 2px solid #EA4335;
        padding-top: 20px;
        width: 600px;

    }

    .team-upcoming-changes {
        display: flex;
        align-items: center;
        margin-bottom: 30px;

        img {
            vertical-align: top;
            margin-right: 8px;
        }
    }

    .seat-status {
        display: flex;
        align-items: center;
        font-weight: 700;
        color: #979797;

        &::before {
            content: "";
            width: 8px;
            height: 8px;
            background: #979797;
            border-radius: 50%;
            margin-right: 6px;
        }

        &.active {
            color: green;
            
            
            &:before {
                background: green;
            }
        }
    }
    
    .form-inner {
        display: flex;
        align-items: center;
        column-gap: 20px;
        position: relative;

        label {
            display: flex;
            font-weight: bold;
            margin-bottom: 10px;
        }
    }

    .word-input {
        display: flex;
        flex-direction: column;
    }

    .lang-selector-wrap {
        width: 40%;
        position: relative;
    }

    .term-adding {
        margin-top: 30px;
    }

    .lang-span {
        display: flex;
        align-items: center;

        img {
            max-width: 30px;
            margin-right: 10px;
        }

        span {
            font-size: 1rem;
            line-height: 1.18rem;
        }
    }
</style>
