<template>
    <div class="timer">
        <span>{{ formatedTimeValue(hoursComputed) }}</span>:<span>{{ formatedTimeValue(minutes) }}</span>:<span>{{ formatedTimeValue(seconds) }}</span>
    </div>
</template>

<script>
 
export default {
  name: 'AsrTimer',
  data() {
    return {
      
    }
  },
  props: {
    days: {
      type: Number,
      required: true
    },
    hours: {
      type: Number,
      required: true
    },
    minutes: {
      type: Number,
      required: true
    },
    seconds: {
      type: Number,
      required: true
    },
  },
  components: {},
  methods: {
    formatedTimeValue(value) {
        let formatedValue = value;
        if (value <= 9) {
            formatedValue = "0" + formatedValue;
        } 
        return formatedValue;
    }
  },
  computed: {
    hoursComputed() {
      let hours = this.hours;
      if (this.days > 0) {
        hours += 24 * this.days;
      }

      return hours;
    }
  },
  mounted() {
    
  }
}
</script>