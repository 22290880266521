import EventService from '../services/event.service';

export const event = {
  namespaced: true,
  state: {
    event: {},
    is_room_owner: false,
    guest: {
      name: "Guest",
      language: "en-US",
      voice: "male"
    },
    preRoomPassed: false,
    settings: {
      speech_mode: "hold_to_talk", // custom_pause, live_translate
      tts_muted: false,
      tts_speed: 110,
      tts_same_muted: false,
      profanity_filter: true,
    },
    defaults: {
      speech_mode: "hold_to_talk", // custom_pause, live_translate
      tts_muted: false,
      tts_speed: 110,
      tts_same_muted: false,
      profanity_filter: true,
    }
  },
  actions: {
    create({ commit, rootState }, params) {
      let event = {
        title: params.title,
        is_participating_disabled: params.is_participating_disabled,
        token: rootState.auth.token
      };
      return EventService.create(event).then(
        response => {
          commit('setEvent', response.data.result);
          commit('setIsRoomOwner', true);
          return Promise.resolve(response.data.result);
        },
        error => {
          return Promise.reject(error);
        }
      );
    },
    join({ commit, rootState }, roomcode) {
      const params = {
        room_code: roomcode
      };

      params.is_host = rootState.auth.authorized;
      if (rootState.auth.authorized && rootState.auth.token) {
        params.token = rootState.auth.token
      }

      return EventService.join(params).then(
        response => {
          commit('setEvent', response.data.result);
          //commit('setIsRoomOwner', false);
          return Promise.resolve(response.data.result);
        },
        error => {
          return Promise.reject(error);
        }
      );
    },
    close({ commit }) {
      commit('setEvent', null);
      commit('setIsRoomOwner', null);
    },

    setGuestProfile({ commit }, guest) {
      commit('setGuest', guest);
      commit('setPrePassed', true);
      return Promise.resolve(guest);
    },
    saveSettings({ commit }, settings) {
      commit('setSettings', settings);
      return Promise.resolve(settings);
    }
  },
  getters: {
    
  },
  mutations: {
    setEvent(state, event) {
      state.event = event;
    },
    setIsRoomOwner(state, is_room_owner) {
      state.is_room_owner = is_room_owner
    },
    setGuest(state, guest) {
      state.guest = guest;
    },
    setSettings(state, settings) {
      if (!settings) {
        Object.assign(state.settings, state.defaults);
      } else {
        state.settings = settings;
      }
    },
    setPrePassed(state, bool) {
      state.preRoomPassed = bool;
    }
  }
}