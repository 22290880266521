<template>
  <!-- Hardcoded "hover", "openDelay" and "closeDelay" -->
  <Popper v-bind="$attrs">
    <slot />
    <template #content="props">
      <slot name="content" v-bind="props" />
    </template>
  </Popper>
</template>

<script>
  import { defineComponent } from "vue";
  import Popper from "vue3-popper";

  export default defineComponent({
    name: "TooltipWrap",
    components: {
      Popper,
    },
  });
</script>

<style scoped>
  :deep(.popper) {
    max-width: 185px;
    text-align: center;
    font-size: 0.875rem;
    line-height: 1rem;
    font-weight: 400;
  }
</style>