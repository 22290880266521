<template>
    <div class="room-settings-popup">

        <div class="room-settings-form room-profile-form" v-if="profileState || languageOpen">
          <settings-form :user="participant" :openLanguageSelector="openLanguageSelector" @profileback="onProfileBack" @saved="setProfile"></settings-form>
        </div>
        <div class="room-settings-form" v-else>
          <a href="javascript:;"  @click="selfClose" class="icon-btn conference-close">
            <img src="@/assets/icons/xmark.svg" alt="Close">
          </a>
          <custom-scrollbar  class="settings-scroll" :autoHide="false">
            <div class="settings-item">
              <label class="item-label">
                <span class="item-label__icon"><img src="@/assets/icons/user_icon.svg" alt="User Profile"></span>
                <span class="item-label__text">{{ $t('settings_your_profile') }}</span>
              </label>
              <a href="javascript:;" class="profile-link" @click="setProfileState(true)" v-show="participant">
                <span class="profile-link__name" v-if="authorized">
                <i>{{ participant.email }}</i> <br>
                <b>{{ getSubscriptionTier }}</b>
              </span>
              <span class="profile-link__name" v-else>
                <i>{{ participant.name }}</i> <br>
                <b>{{ $t('settings_unregistered_plan') }}</b>
              </span>
                <span class="profile-link__lang" v-if="participant.language"><img :src="'/data/flags/' + participant.language + '.png'"/></span>
              </a>
            </div>
            <div class="settings-item">
              <label class="item-label">
                <span class="item-label__icon"><img src="@/assets/icons/settings_mic.svg" alt="Speech Recognition"></span>
                <span class="item-label__text">{{ $t('speech_recognition') }}</span>
              </label>
              <div class="speech-modes">
                 <TooltipWrap :content="this.$t('session_setting_hold_to_talk_desc')" hover="true" arrow="true">
                    <label class="mode-selector">
                  <input type="radio" class="hidden-radio" name="speech_mode" v-model="settings.speech_mode" value="hold_to_talk"/>
                  <div class="mode-selector__inner">
                    <div class="mode-header">
                      <div class="mode-header__icon"><img src="@/assets/icons/holdtotalk.svg" alt="Hold To Talk"></div>
                      <div class="mode-header__title">{{ $t('session_setting_hold_to_talk') }}</div>
                    </div>
                  </div>
                </label>
                 </TooltipWrap>
                
                <TooltipWrap :content="this.$t('session_setting_translate_after_pause_desc')" hover="true" arrow="true">
                    <label class="mode-selector" :class="{ disabled: !permissions.can('asr.continuous_recording', participant)}">
                    <input type="radio" class="hidden-radio" :disabled="!permissions.can('asr.continuous_recording', participant)" name="speech_mode" v-model="settings.speech_mode" value="custom_pause"/>
                    <div class="mode-selector__inner">
                      <div class="mode-header">
                        <div class="mode-header__icon"><img src="@/assets/icons/pausetotranslate.svg" alt="Custom Pause"></div>
                        <div class="mode-header__title">{{ $t('session_setting_translate_after_pause')}}</div>
                        
                      </div>
                    </div>
                  </label>
                </TooltipWrap>
                
                                <TooltipWrap :content="this.$t('session_setting_translate_live_desc')" hover="true" arrow="true">
                    <label class="mode-selector" :class="{ disabled: !getUserLang.auto_punctuation || !permissions.can('asr.real_time_translation', participant)}" >
                      <input type="radio" class="hidden-radio" :disabled="!getUserLang.auto_punctuation || !permissions.can('asr.real_time_translation', participant)" name="speech_mode" v-model="settings.speech_mode" value="live_translate"/>
                      <div class="mode-selector__inner">
                        <div class="mode-header">
                          <div class="mode-header__icon"><img src="@/assets/icons/livetranslate.svg" alt="Live Translate"></div>
                          <div class="mode-header__title">{{ $t('session_setting_translate_live') }}</div>
                        </div>
                      </div>
                      <div class="live-translate-unavailable" v-if="!getUserLang.auto_punctuation">{{ $t('session_setting_translate_live_unsupported') }}</div>
                      <div class="live-translate-unavailable" v-if="!permissions.can('tts', profile)">{{ $t('session_setting_translate_live_premium') }}</div>
                    </label>
                </TooltipWrap>
              </div>
              <div class="table-item table-item--single">
                  <div class="table-item__left">{{ $t('custom_terms_profanity_filter') }}</div>
                  <div class="table-item__right">
                    <Toggle v-model="settings.profanity_filter" />
                  </div>
              </div>
            </div>
            <div class="settings-item" :class="{ disabled: !permissions.can('tts', participant)}">
              <label class="item-label">
                <span class="item-label__icon"><img src="@/assets/icons/settings_speaker.svg" alt="Text-to-speech"></span>
                <span class="item-label__text">{{ $t('settings_tts') }}</span>
              </label>
              <div class="tts-settings audio-settings">
                <div class="table-item">
                  <div class="table-item__left">{{ $t('session_setting_mute') }}</div>
                  <div class="table-item__right">
                    <Toggle v-model="settings.tts_muted" />
                  </div>
                </div>
                <div class="table-item">
                  <div class="table-item__left">{{ $t('session_setting_same_mute') }}</div>
                  <div class="table-item__right">
                    <Toggle v-model="settings.tts_same_muted" />
                  </div>
                </div>
                <div class="table-item">
                  <div class="table-item__left">{{ $t('session_setting_speech_speed') }}</div>
                  <div class="table-item__right">
                    <div class="tts-controls">
                      <span class="tts-controls__speed">{{ settings.tts_speed }}%</span>
                      <div class="increment-controls">
                        <button type="button" class="increment-controls__btn-minus" @click="decrementSpeed">-</button>
                        <button type="button" class="increment-controls__btn-plus" @click="incrementSpeed">+</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>


          </custom-scrollbar>
          <div class="margined-submit">
            <a href="javascript:;" class="orange-btn form-btn" @click="selfCommit">{{ $t('common_done') }}</a>
          </div>
        </div>

      
    </div>   
  
</template>

<script>
// @ is an alias to /src
import { mapState } from 'vuex';
import Toggle from '@vueform/toggle'
import SettingsForm from '@/components/SettingsForm.vue';
import permissions from "@/services/permissions.service";
import string from "@/services/string.service"; // eslint-disable-line no-unused-vars


export default {
  name: 'EventStart',
  data() {
    return {
      guestProfileState: false,
      constraints: {
        tts_speed_max: 200,
        tts_speed_min: 50,
        tts_speed_step: 10
      },
      settings: this.defaultSettings,
      profileState: false,
      profile: this.participant,
      languageOpen: this.openLanguageSelector,
      permissions: permissions
    }
  },
  props: {
    defaultSettings: {
      type: Object,
      required: true
    },
    participant: {
      type: Object,
      required: true
    },
    openLanguageSelector: {
      type: Boolean,
      required: false,
      default: false,
    }
  },
  components: {
    Toggle,
    SettingsForm,
  },
  methods: {
    selfClose() {
      this.$emit('closed');
    },
    selfCommit() {
      if (this.authorized) {
        this.$store.dispatch("auth/update", this.profile);
      }
      
      this.$emit('saved', {
        settings: this.settings,
        profile: this.profile
      });
    },
    incrementSpeed() {
      if (this.settings.tts_speed < this.constraints.tts_speed_max) {
        this.settings.tts_speed += this.constraints.tts_speed_step;
      }
    },
    decrementSpeed() {
      if (this.settings.tts_speed > this.constraints.tts_speed_min) {
        this.settings.tts_speed -= this.constraints.tts_speed_step
      }
    },
    setProfileState(bool) {
      this.profileState = bool;
    },
    setProfile(profile) {
      this.profile = profile;
      this.setProfileState(false);
      if (this.languageOpen) {
        this.languageOpen = false;
        this.selfCommit();
      }
    },
    onProfileBack() {
      this.setProfileState(false);
    }
  },
  computed: {
     ...mapState('event', ['event', 'guest']),
     ...mapState('nonpersist', ['user']),
     ...mapState('lang', ['languages']),
     ...mapState('auth', ['authorized']),
     getUserLang() {
      let selectedLang = false;
      
      this.languages.forEach(lang => {
        if (lang.code == this.profile.language && lang.dialects.length <= 1) {
          selectedLang = lang;
          return;
        } else {
          lang.dialects.forEach(dialect => {
            if (dialect.code == this.profile.language) {
              selectedLang = dialect;
              return;
            }
          });
        }
      });
      return selectedLang;
     },
     getSubscriptionTier() {
      return this.participant.subscription.title;
     }
  },
  mounted() {
  }
}
</script>

<style src="@vueform/toggle/themes/default.css"></style>

<style lang="scss">
  .room-settings-popup {
    font-family: "Inter";
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    position: absolute;
    z-index: 9;
    background: linear-gradient(180deg, #000000 0%, rgba(27, 8, 8, 0.02) 40.5%, #060101 86.62%);
    display: flex;
    align-items: center;
    justify-content: center;

    .margined-submit {
      margin-top: 40px;
    }

    .room-settings-form {
      width: 700px;
      position: relative;

      &.room-profile-form {
        padding: 40px 72px 50px;

        @media all and (max-width: 800px) {
          padding: 0;
        }
      }

      .conference-close {
        position: absolute;
        right: -60px;
        top: -30px;
      }

      @media all and (max-width: 800px) {
        width: 100%;
        height: 100%;
      }
    }
    
    .settings-item {
      margin-bottom: 32px;

      &.disabled {
        opacity: 0.5;
        pointer-events: none;
        touch-action: none;
        cursor: default;
      }

      &:last-child {
        margin-bottom: 0;
      }

      .item-label {
        position: relative;
        border-bottom: 1px solid #505050;
        width: 100%;
        display: flex;
        margin-bottom: 24px;

        &__icon {
          position: absolute;
          left: -40px;
        }

        &__text {
          font-size: 0.875rem;
          line-height: 1rem;
          /* identical to box height */
          text-transform: uppercase;
          color: #FFFFFF;
          opacity: 0.3;
          padding-bottom: 7px;
          
        }
      }
    }
    
    .profile-link__lang {
      margin-right: 22px;

      img {
        width: 40px;
      }
    }

    .margined-submit {
      display: flex;
      justify-content: center;
    }

    .speech-modes {
      display: flex;
      justify-content: space-between;
      
      .mode-selector {
        cursor: pointer;
        display: flex;
        text-align: center;
        flex-direction: column;
        width: 185px;

        &.disabled {
          cursor: default;

          .mode-header__radio {
            opacity: 0.5;
          } /** LEGACY */

          .mode-header {
            opacity: 0.5;
          }

        }

        &__inner {
          padding: 40px 0 27px;
          border: 5px solid #5A5959;
          border-radius: 40px;
          width: 100%;
          background: rgba(194, 192, 189, 0.1);
          position: relative;

          .pro-indicator {
            position: absolute;
            top: 16px;
            right: 16px;
          }
        }

        &:last-child {
          margin-bottom: 0;
        }

        input:checked + .mode-selector__inner {
            background: rgba(212, 53, 53, 0.15);
            border-color: #EA4335;
            box-shadow: 0px 0px 4px 3px rgba(181, 26, 26, 0.5);
          }

        .mode-header {
          display: flex;
          flex-direction: column;
          margin-bottom: 10px;
          align-items: center;

          &__radio {
            width: 31px;
            height: 31px;
            border: 5px solid #EEEBE7;
            background: #C2C0BD;
            box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
            border-radius: 50%;
            margin-left: -12px;
          }

          input {
            cursor: pointer;
          }

          input:checked + .mode-header__radio {
            background: #905F18;
            border-color: #46E0D4;
            box-shadow: none;
          } /** LEGACY */

          

          input:disabled {
            cursor: default;
          }

          &__title {
            font-size: 1.125rem;
            line-height: 1.375rem;
            color: #FFFFFF;
          }

          &__icon {
            margin-bottom: 21px;
            height: 50px;

            img {
              max-height: 100%;
            }
          }
        }

        &__description {
          font-size: 1rem;
          line-height: 1.18rem;

          color: #ADADAF;
        }
      }

      .live-translate-unavailable {
        font-size: 0.625rem;
        line-height: 0.75rem;

        color: #D30A0A;
        margin-top: 20px;
      }
    }
  }

  .tts-settings {
    padding-top: 10px;

    .tts-controls {
      display: flex;
      align-items: center;

      &__speed {
        font-size: 0.875rem;
        line-height: 1.375rem;
        color: #C2C2C2;
        margin-right: 15px;
      }
    }
    .increment-controls {
      width: 96.73px;
      height: 35px;
      left: 912.49px;
      top: 714px;
      display: flex;
      align-items: center;
      background: #111111;
      border-radius: 15px;
      padding: 5px 0;

      button {
        background: transparent;
        border: none;
        outline: none;
        box-shadow: none;
        font-size: 1.3rem;
        line-height: 1.375rem;
        /* or 105% */

        text-align: center;

        color: #FFFFFF;
        width: 50%;
        cursor: pointer;

        &.increment-controls__btn-minus {
          border-right: 1px solid rgba(255, 255, 255, 0.13);
        }
      }
    }
  }

  .table-item {
    height: 46px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid rgba(57, 57, 57, 0.5);

    &:first-child {
      border-top: 1px solid rgba(57, 57, 57, 0.5);
    }

    &__left {
      font-size: 0.875rem;
      line-height: 1.375rem;
      color: #FFFFFF;
    }

    &--single {
      margin-top: 15px;
    }
  }

  .settings-scroll {
    height: 38rem;
    padding: 0 50px;
  }

  .audio-settings {
    padding-top: 0;

    .table-item:first-child {
      border-top: none;
    }
  }
  
</style>
