<template>
  <div class="conference-meta" :class="{ transparenthidden: roomSettingsPopup, mobileMeta: $isMobile() }" v-if="event">
      <div class="mobile-meta-header"  v-if="$isMobile()">
        <a href="/" class="conference-meta__logo">
          <img src="../assets/logo-sm.svg" width="167" alt="">
        </a>
        <a href="javascript:;" class="mobile-info-btn" @click="showForumInfoPopup">
          <svg width="78" height="78" viewBox="0 0 78 78" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M39 33.8573V57.0001M39 18.4287V23.5716" stroke="#46E0D4" stroke-width="5.14286" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M39 75C58.8823 75 75 58.8823 75 39C75 19.1177 58.8823 3 39 3C19.1177 3 3 19.1177 3 39C3 58.8823 19.1177 75 39 75Z" stroke="#46E0D4" stroke-width="5.14286" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
        </a>
      </div>
      <div class="meta-footer">
        <div class="conference-meta__title">{{ event.title }}</div>
      <div class="conference-meta__speaker">{{ event.speaker_name }}</div>
      </div>
      
  </div>
  <div class="room flex-column" ref="roomwrap" :class="{ anyPopupShown: anyPopupShown}" >

    <div class="room-overlay" v-show="roomOverlayed" :class="{ transparenthidden: roomSettingsPopup }" @click="closePopups" ></div>
    
    <!-- room code & qr code popup -->
    <room-code v-if="roomCodePopup" @closed="closePopups" :room_code="event.room_code"></room-code>
    
    <!-- room settings popup -->
    <room-settings 
      v-if="roomSettingsPopup" 
      @closed="closePopups" 
      @saved="setSettings"
      :participant="participant"
      :openLanguageSelector="openLanguageSelector" 
      :defaultSettings="settings">
    </room-settings>

    <div class="forum-info-popup" v-show="forumInfoPopup">
      <a href="javascript:;" class="icon-btn conference-close" @click="hideForumInfoPopup"><img src="@/assets/icons/xmark.svg" alt="Close"></a>
      <div class="forum-info">
        <div class="forum-info__text">
          Download the <b>Forum Interpreter App</b> for additional functionality and the ability to record and translate your speech. Try it for free today!
        </div>
        <div class="forum-info__links">
          <a href="https://apps.apple.com/app/id1612322502">
            <img src="@/assets/appstore.svg" alt="Get it on the App Store" width="160">
          </a>
          <a href="https://play.google.com/store/apps/details?id=com.waverlylabs.audience.translate&pli=1">
            <img src="@/assets/googleplay.svg" alt="Get it on Google Play" width="160">
          </a>
        </div>
        <div class="forum-info__footer">
          <b>Thanks for helping us create
 a world without language barriers. </b>
          <span>Waverly Labs Inc. Brooklyn, N.Y.</span>
        </div>
      </div>
    </div>
    
    <!-- signup popup -->
    <signup-popup v-show="!authorized && roomSignupPopup" @closed="closePopups"></signup-popup>  

    <div class="screen" :class="{ transparenthidden: roomSettingsPopup }">
      <!-- default text when waiting for messages -->
      <div class="screen__default" v-if="!started && isAllowedToSpeak">{{ defaultText }} <img src="@/assets/icons/arrow-down.svg" alt=""></div>
      <div class="screen__default" v-if="!started && !isAllowedToSpeak"> {{ $t('session_listener_waiting') }} </div>
      
      <!-- transcripts & partials scroller -->
      <custom-scrollbar class="screen__scroll" :class="{visible: transcripts.length > 0 || partials.length > 0 || recording || typing}" throttleType="throttle" :autoHide="false">
        <div class="transcripts">
          <div class="transcripts__item transcripts__item--final" v-for="(transcript, key) in transcripts" :key="'tr_' + key">
            <div class="transcript__time">{{ transcript.time }}</div>
            <div class="transcript__original" :class="{ noTranslations: hasTranslationForUser(transcript) }">
              <div class="transcript__sendername"><div class="transcript__flag"><img :src="'/data/flags/' + transcript.sender.language + '.png'" /></div> {{ transcript.sender.name }}</div>

              <div class="transcript__text">{{ transcript.original.text }}</div>
              <div class="transcript__read" v-if="transcript.sender.sender_id == user_id"><img src="@/assets/icons/sent.svg" /></div>
            </div>
            <div class="transcript__translation" v-if="transcript.translations && hasTranslationForUser(transcript)">
              <div class="transcript__text">{{ getTranslationForUser(transcript).text }}</div>
            </div>
          </div>
          <div class="transcripts__item transcripts__item--partial" v-for="(partial, key) in partials" :key="'pt_' + key">
            <div class="transcript__sendername" v-if="partial.sender"><div class="transcript__flag">
              <img v-if="partial.sender" :src="'/data/flags/' + partial.sender.language + '.png'" />
              <img v-else :src="'/data/flags/' + participant.language + '.png'" />

            </div>{{ partial.sender.name }}</div>
            <div class="transcript__sendername" v-else><div class="transcript__flag">
              <img v-if="partial.sender" :src="'/data/flags/' + partial.sender.language + '.png'" />
              <img v-else :src="'/data/flags/' + participant.language + '.png'" />

            </div>{{ participant.name }}</div>
            <div class="transcript__text">{{ partial.original.text }} {{ partial.concat }}</div>
          </div>
          <div class="transcripts__item transcripts__item--partial" v-if="recording && !hasOwnMessage()">
            <div class="transcript__flag">
              <img :src="'/data/flags/' + participant.language + '.png'" />
            </div>
            <div class="transcript__text">{{ $t('common_listening')}}</div>
          </div>
          <div class="transcripts__item transcripts__item--partial" v-if="partial && hasOwnMessage() && recording">
            <div class="transcript__flag">
              <img :src="'/data/flags/' + participant.language + '.png'" />
            </div>
            <div class="transcript__text">{{ partial.original.text }} {{ partial.concat }}</div>
          </div>

          <div class="transcripts__item transcripts__item--partial keyboard-input clear" v-show="typing">
            <div class="transcript__flag">
              <img :src="'/data/flags/' + participant.language + '.png'" />
            </div>
            <contenteditable tag="div" ref="keyboard_input_field" id="keyboard_input_field" class="transcript__text" v-model="keyboardInputValue" :no-nl="true" :no-html="true" @returned="onEnterPressed" />
            <a href="javascript:;" class="keyboard-input__send" @click="sendKeyboardMessage(true)">
              <img src="@/assets/icons/paperplane.svg" />
            </a>
          </div>
        </div>
      </custom-scrollbar>

      <!-- scroller arrow -->
      <a href="javascript:;" class="chevron-to-bottom-scroller" v-show="scroller.scrolled" @click="resetScroller">
        <svg width="22" height="13" viewBox="0 0 22 13" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M10.8348 13C10.5498 13.0016 10.2674 12.947 10.0036 12.8392C9.73975 12.7315 9.49981 12.5727 9.2975 12.3721L0.636763 3.71132C0.434884 3.50944 0.274746 3.26978 0.16549 3.00601C0.0562339 2.74225 4.25427e-09 2.45954 0 2.17404C-4.25427e-09 1.88854 0.056234 1.60584 0.16549 1.34207C0.274746 1.0783 0.434884 0.83864 0.636763 0.636762C0.838641 0.434883 1.07831 0.274744 1.34207 0.165488C1.60584 0.0562319 1.88854 -4.25427e-09 2.17404 0C2.45954 4.25428e-09 2.74225 0.0562319 3.00602 0.165488C3.26978 0.274744 3.50945 0.434883 3.71132 0.636762L10.8348 7.80352L17.9799 0.918236C18.1791 0.69676 18.4218 0.518763 18.6929 0.395377C18.964 0.271992 19.2577 0.205873 19.5555 0.201154C19.8533 0.196436 20.1489 0.253218 20.4238 0.367953C20.6987 0.482689 20.9469 0.652909 21.153 0.867964C21.3591 1.08302 21.5186 1.33829 21.6216 1.6178C21.7245 1.89731 21.7687 2.19506 21.7513 2.49242C21.7339 2.78977 21.6554 3.08035 21.5206 3.34596C21.3857 3.61158 21.1976 3.84652 20.9678 4.0361L12.3071 12.3937C11.9111 12.7755 11.3847 12.9922 10.8348 13Z" fill="#46E0D4"/>
        </svg>
        <!-- scroller new dot -->
        <span class="new-indicator" v-show="scroller.hasNew"></span>
      </a>

    </div>
    <div class="toolbar" :class="{ transparenthidden: roomSettingsPopup }">
      <div class="toolbar-left">
        <div class="participants-info flex-column">
          <img class="participants-info__icon" src="@/assets/icons/people.svg" alt="Participants">
          <span class="participants-info__count">{{ room.count }}/{{ event.guests_limit }}</span>
        </div>
      </div>
      
      <div class="speech-control flex-column"  :class="{ notAllowed: !isAllowedToSpeak }">
        <asr-timer :days="asrTimer.days" :hours="asrTimer.hours" :minutes="asrTimer.minutes" :seconds="asrTimer.seconds"></asr-timer>
          <div class="speech__wrap" v-if="!$isMobile()">
            <div class="keyboard-input-btn" @click="keyboardInput">
              <img src="@/assets/icons/keyinput.svg"  alt="Keyboard input">
            </div>
          
            <!-- start rec button -->
            <div class="mic flex-row" :class="{ pulsing: !speaked}" v-show="!recording && !asrConnecting" 
                v-on="{ 
                  click: !isHoldToTalk ? startRecording : null, 
                  mousedown: isHoldToTalk ? startRecording : null,
                  }"
                >
              <img :src="this.speechModeIcon" alt="Mic">
            </div>

            <!-- stop rec button -->
            <div class="mic mic-stop flex-row" v-show="recording && !isHoldToTalk() && !asrConnecting" @click="stopRecording">
              <img src="@/assets/icons/stop.svg"  alt="Stop">
            </div>

            <!-- audio wave for hold-to-talk-->
            <div class="mic flex-row mic-wave" v-show="recording && isHoldToTalk() && !asrConnecting">
              <svg id="wave" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 38.05">
                <title>Audio Wave</title>
                <path id="Line_1" data-name="Line 1" d="M0.91,15L0.78,15A1,1,0,0,0,0,16v6a1,1,0,1,0,2,0s0,0,0,0V16a1,1,0,0,0-1-1H0.91Z"/>
                <path id="Line_2" data-name="Line 2" d="M6.91,9L6.78,9A1,1,0,0,0,6,10V28a1,1,0,1,0,2,0s0,0,0,0V10A1,1,0,0,0,7,9H6.91Z"/>
                <path id="Line_3" data-name="Line 3" d="M12.91,0L12.78,0A1,1,0,0,0,12,1V37a1,1,0,1,0,2,0s0,0,0,0V1a1,1,0,0,0-1-1H12.91Z"/>
                <path id="Line_4" data-name="Line 4" d="M18.91,10l-0.12,0A1,1,0,0,0,18,11V27a1,1,0,1,0,2,0s0,0,0,0V11a1,1,0,0,0-1-1H18.91Z"/>
                <path id="Line_5" data-name="Line 5" d="M24.91,15l-0.12,0A1,1,0,0,0,24,16v6a1,1,0,0,0,2,0s0,0,0,0V16a1,1,0,0,0-1-1H24.91Z"/>
                <path id="Line_6" data-name="Line 6" d="M30.91,10l-0.12,0A1,1,0,0,0,30,11V27a1,1,0,1,0,2,0s0,0,0,0V11a1,1,0,0,0-1-1H30.91Z"/>
                <path id="Line_7" data-name="Line 7" d="M36.91,0L36.78,0A1,1,0,0,0,36,1V37a1,1,0,1,0,2,0s0,0,0,0V1a1,1,0,0,0-1-1H36.91Z"/>
                <path id="Line_8" data-name="Line 8" d="M42.91,9L42.78,9A1,1,0,0,0,42,10V28a1,1,0,1,0,2,0s0,0,0,0V10a1,1,0,0,0-1-1H42.91Z"/>
                <path id="Line_9" data-name="Line 9" d="M48.91,15l-0.12,0A1,1,0,0,0,48,16v6a1,1,0,1,0,2,0s0,0,0,0V16a1,1,0,0,0-1-1H48.91Z"/>
              </svg>
            </div>

            <!-- connecting loader-->
            <div class="mic loader default-03" v-show="asrConnecting">
              <div class='loader-container'>
                <div class='ball'></div>
                <div class='ball'></div>
                <div class='ball'></div>
                <div class='ball'></div>
                <div class='ball'></div>
                <div class='ball'></div>
                <div class='ball'></div>
                <div class='ball'></div>
                <div class='ball'></div>
                <div class='ball'></div>
              </div>
            </div>
            <!-- language selector -->
            <div class="user-language-selector language-tooltip" @click="showLanguageSelector">
              <img :src="'/data/flags/' + participant.language + '.png'" alt="">
            </div>
        </div>
        <div class="user-language-selector language-tooltip user-language-selector--mobile" @click="showLanguageSelector">
          <img :src="'/data/flags/' + participant.language + '.png'" alt="">
        </div>
      </div>


      <div class="toolbar-right">
        <!--<div class="toolbar-filler"></div>-->
        <div class="settings-control" v-if="!$isMobile()">
          <div class="settings-menu" >
            <transition name="settings_menu">
                <div class="settings-menu__options" v-show="settingsMenuOpen">
                  <div class="settings-menu-item" @click="showRoomSettingsPopup">
                    <div class="settings-menu-item__text">{{ $t('settings_title') }}</div>
                    <a href="javascript:;"><img src="@/assets/icons/settings-tweaks.svg" /></a>
                  </div>
                  <div class="settings-menu-item">
                    <div class="settings-menu-item__text">{{ $t('session_menu_code') }}</div>
                    <a href="javascript:;" @click="showRoomCodePopup"><img src="@/assets/icons/settings-qr.svg" /></a>
                  </div>
                  <div class="settings-menu-item">
                    <div class="settings-menu-item__text">{{ $t('session_menu_resize') }}</div>
                    <div class="font-changer">
                      <a href="javascript:;" class="font-changer__bigger" @click="changeFontSize(1)"><img src="@/assets/icons/settings-fontbigger.svg" alt="Font Bigger"></a>
                      <a href="javascript:;" class="font-changer__smaller" @click="changeFontSize(-1)"><img src="@/assets/icons/settings-fontsmaller.svg" alt="Font Smaller"></a>
                    </div>
                  </div>
                </div>
            </transition>
            <button type="button" class="settings-control__threedots flex-column" @click="toggleSettingsMenuOpen" title="Settings">
            <img src="@/assets/icons/dots.svg" alt="Open Settings">
          </button>
          </div>
        </div>
        <div class="mute-control" v-else @click="toggleTTS">
            <img class="participants-info__icon" v-if="settings.tts_muted" src="@/assets/icons/muted.svg" alt="Mute/Unmute">
            <img class="participants-info__icon" v-else src="@/assets/icons/unmuted.svg" alt="Mute/Unmute">
          </div>
      </div>
      
    </div>
    <div class="hidden-audio-players">
      <audio ref="start_rec_audio" src="/media/rec_start.opus.mp3"></audio>
      <audio ref="stop_rec_audio" src="/media/rec_stop.opus.mp3"></audio>
      <audio ref="tts_rec_audio" src="/media/rec_start.opus.mp3"></audio>
    </div>

  </div>
</template>

<script>
// @ is an alias to /src
import { mapState, mapGetters, mapMutations } from 'vuex';
import MediaRecorder from 'opus-media-recorder';
// Use worker-loader
import EncoderWorker from 'worker-loader!opus-media-recorder/encoderWorker.js';
// You should use file-loader in webpack.config.js.
// See webpack example link in the above section for more detail.
//import OggOpusWasm from 'opus-media-recorder/OggOpusEncoder.wasm';
//import WebMOpusWasm from 'opus-media-recorder/WebMOpusEncoder.wasm';
import { io } from "socket.io-client";
import ss from "socket.io-stream";
import { v4 as uuidv4 } from 'uuid';
import {Howler} from 'howler';
import emitter from "@/services/emitter.service";
import Swal from 'sweetalert'
import permissions from "@/services/permissions.service";

import RoomCode from '@/components/RoomCode.vue';
import RoomSettings from '@/components/RoomSettings.vue';
import SignupPopup from '@/components/SignupPopup.vue';
import { useTimer } from 'vue-timer-hook';
import AsrTimer from '@/components/AsrTimer.vue';
import contenteditable from 'vue-contenteditable';
import GlossaryService from "@/services/glossary.service";

import Shepherd from 'shepherd.js'

Howler.volume(1);

// Non-standard options
const workerOptions = {
  encoderWorkerFactory: _ => new EncoderWorker(), // eslint-disable-line no-unused-vars
  OggOpusEncoderWasmPath: 'https://cdn.jsdelivr.net/npm/opus-media-recorder@latest/OggOpusEncoder.wasm',
  WebMOpusEncoderWasmPath: 'https://cdn.jsdelivr.net/npm/opus-media-recorder@latest/WebMOpusEncoder.wasm'
};

export default {
  name: 'ConferenceRoom',
  components: {
    RoomCode,
    RoomSettings,
    SignupPopup,
    AsrTimer,
    contenteditable
  },
  data() {
    return {
      tour: {},
      asrTimer: {},
      user_id: false,
      recording: false,
      typing: false,
      recorder: false,
      asrSocket: false,
      apiSocket: false,
      scroller: {
        scrolled: false,
        hasNew: false,
        scrollTop: 0
      },
      holding: false,
      //asrSocketStream: false,
      room_token: false,
      transcripts: [],
      partials: [],
      partial: {
        message_id: false,
        original: {
          text: ""
        },
        concat: ""
      },
      keyboardInputValue: "",
      started: false,
      speaked: false,
      room: {
        count: 0
      },
      participant: {},
      custom_terms: [],
      isGuest: false,
      settingsMenuOpen: false,
      roomOverlayed: false,
      roomCodePopup: false,
      roomSettingsPopup: false,
      forumInfoPopup: false,
      roomSignupPopup: false,
      fontSize: 20,
      fontDim: "px",
      maxFontSize: 40,
      minFontSize: 12,
      micActivated: false,
      asrConnecting: true,
      openLanguageSelector: false,
      ttsQueue: [],
      ttsPlaying: false,
      finalMessages: [],
      deletedMessages: [],
      socketRoomOwner: false,
    }
  },
  methods: {
    ...mapMutations('auth', ['setTutorialShown']),
    setRecording(status) {
      this.recording = status;
    },
    setMicActivated(isActivated) {
      this.micActivated = isActivated;
    },
    keyboardInput() {
      if (!this.isAllowedToSpeak) {
        this.$notify('session_participation_is_disabled', {
          icon: "error",
        });
        return false;
      }
      if (this.typing) {
        this.typing = false;
        this.clearCurrenPartial(true);
      } else {
        this.typing = true;
        if (this.recording) {
          this.stopRecording();
        }

        this.startRoom();

        setTimeout(function() {
          document.querySelector('#keyboard_input_field').focus();
        }, 0);
        this.resetScroller();
      }

    },
    onEnterPressed() {
      this.sendKeyboardMessage(true);
    },
    startRecording(sound = true) {
      console.log("starting");

      if (this.asrConnecting) {
        return false;
      }
      if (!permissions.can('asr', this.participant)) {
        this.noASRmessage();
        this.asrConnecting = false;
        this.speaked = true;
        return false;
      } else if (!this.isAllowedToSpeak) {
        this.$notify('session_participation_is_disabled', {
          icon: "error",
        });
        this.asrConnecting = false;
        this.speaked = true;
        return false;
      } else if ((this.asrTimer.isExpired || this.asrTimeLeft <= 0) && !this.participant.features.asr.overdraft){
        this.$notify('error_no_asr_left', {
          icon: "error",
        });
        this.asrConnecting = false;
        this.speaked = true;
        return false;
      }

      this.startRoom();
      if (this.typing) {
        this.typing = false;
        this.clearCurrenPartial(true);
      }
      this.stopTTS();
      this.setupRecorder();
      this.setRecording(true);
      this.setMicActivated(true);
      if (sound) {
        this.soundPlayerRecStart.play();
      }
      
      
      if (this.speaked) {
          this.asrTimer.resume();
      } else {
        this.asrTimer.start();
      }
      this.speaked = true;
      this.ttsPlaying = false;
      this.ttsQueue = [];
    },
    stopRecording(forced = true, cancel = false) {

      // Remove “recording” icon from browser tab
      this.recorder.stream.getTracks().forEach(i => i.stop());
      if (forced) {
        this.soundPlayerRecStop.play();
        this.holding = false;
      }
      
      if (this.recorder._state != 'inactive') {

        this.recorder.stop();
      }

      if (this.isHoldToTalk() && forced) {
        const appThis = this;
        this.asrConnecting = true;
        
        if (!this.merging) {
          this.merging = setTimeout(function () {
            appThis.asrTimer.pause();
            appThis.asrSocket.emit('asr-stop');
            appThis.partial.is_final = true;
            appThis.partial.push = true;
            appThis.mergePartial();
            appThis.setRecording(false);
            appThis.setMicActivated(false);
            appThis.asrConnecting = false;
            appThis.merging = false

          }, 500)
        }
      } else {

        if (forced || cancel) {
          this.asrTimer.pause();
          this.asrSocket.emit('asr-stop');
          this.clearCurrenPartial(true);
        }

        this.setMicActivated(false);
        this.setRecording(false);
        this.asrConnecting = false;
      }
    },
    restartRecording() {
      if (this.recording) {
        this.asrConnecting = true;
        this.stopRecording(false);
        this.startRecording(false);
      }
    },
    setRoomToken() {
      if (this.user && this.token) {
        this.room_token = this.token;
      } else if (this.event && this.event.guest_token) {
        this.room_token = this.event.guest_token;
      }
    },
    setSettings(roomSettings) {
      this.$store.dispatch("event/saveSettings", roomSettings.settings)
      this.updateParticipant(roomSettings.profile);
      this.closePopups();
    },
    toggleTTS() {
      this.settings.tts_muted = !this.settings.tts_muted;
    },
    toggleSettingsMenuOpen() {
      this.settingsMenuOpen = !this.settingsMenuOpen;
      this.toggleOverlayed();
    },
    toggleOverlayed() {
      this.roomOverlayed = !this.roomOverlayed;
    },
    showRoomCodePopup() {
      this.roomCodePopup = true;
      this.roomOverlayed = true;
      this.settingsMenuOpen = true;
    },
    showForumInfoPopup() {
      this.forumInfoPopup = true;
    },
    hideForumInfoPopup() {
      this.forumInfoPopup = false;
    },
    showRoomSettingsPopup() {
      this.roomSettingsPopup = true;
      this.roomOverlayed = true;
      this.settingsMenuOpen = true;
    },
    showRecSettingsPopup() {
      this.recSettingsPopup = true;
    },
    showRoomSignupPopup() {
      this.roomSignupPopup = true;
    },
    showLanguageSelector() {
      this.roomSettingsPopup = true;
      this.openLanguageSelector = true;
    },
    closePopups() {
      this.roomCodePopup = false;
      this.roomSettingsPopup = false;
      this.recSettingsPopup = false;
      this.roomSignupPopup = false;

      this.settingsMenuOpen = false;
      this.roomOverlayed = false;
    },
    changeFontSize(direction) {
      let newFontSize = parseInt(this.fontSize) + (direction * 2);
      if (newFontSize > this.maxFontSize || newFontSize < this.minFontSize) {
        return false;
      }

      this.fontSize = newFontSize;
    },
    isHoldToTalk() {
      return this.settings.speech_mode == "hold_to_talk";
    },
    isCustomPause() {
      return this.settings.speech_mode == "custom_pause";
    },
    isLiveTranslate() {
      return this.settings.speech_mode == "live_translate";
    },
    startRoom() {
      if (this.started) {
        return;
      }
      this.started = true;
    },
    syncRoomSettings() {
      if (this.$isMobile()) {
        this.settings.speech_mode = 'custom_pause';
      }
    },
    generateMessageId() {
      return uuidv4();
    },
    setMessageId() {
      if (!this.partial.message_id) {
        this.partial.message_id = this.generateMessageId();
      }

      return this.partial.message_id;
    },
    pushTranscript(message) {
      this.finalMessages.push(message.message_id);
      
      let partialIndex = this.partials.findIndex((part => part.message_id == message.message_id));

      if (message.original.text === '_INVALID_' || message.original.text == '_crosstalk_is_detected_') {
        if (partialIndex >= 0) {
          this.partials.splice(partialIndex, 1)
        }

        return false;
      }

      const now = new Date();

      let minutesRaw = now.getMinutes();
      if (minutesRaw < 10) {
        minutesRaw = '0' + minutesRaw;
      }

      let hoursRaw = now.getHours();
      if (hoursRaw < 10) {
        hoursRaw = '0' + hoursRaw;
      }
      const hoursAndMinutes = hoursRaw + ':' + minutesRaw;

      this.transcripts.push({
        ...message,
        time: hoursAndMinutes,
        status: "sent"
      });
      ///this.partial = {};
      
      if (partialIndex > -1) { // only splice array when item is found
        this.partials.splice(partialIndex, 1); // 2nd parameter means remove one item only
      }

      const appThat = this;
      this.scroller.hasNew = true;

      setTimeout(() => {
          appThat.scrollTranscripts();
      }, 50)
      
      //this.clearCurrenPartial();
      //this.$refs.transcripts_scroll.update();
    },
    isSameLanguageAudio(message_id) {
      let messageFound = this.transcripts.find((part => part.message_id == message_id));
      if (messageFound && messageFound.sender) {
        return this.participant.language.slice(0, 2) == messageFound.sender.language.slice(0,2);
      } 

      return false;
    },
    mergePartial(final = true) {
      console.log("merge parital")
      if (this.partial.message_id) {
        this.partial.is_final = final;
        let concatPrev = this.partial.concat;
        this.partial.concat = "";
        if (concatPrev) {
          this.partial.original.text += " " + concatPrev;
        }
        
        this.sendMessage(this.partial);
      } else {
        this.sendMessage(this.partial);
      }
      clearTimeout(this.merging);
    },
    sendHoldToTalkPartial(newPartial) {
      console.log("partial send")
      let tmpMessage = {
        message_id: newPartial.message_id,
        original: {}
      };
      if (this.partial.original.text) {
        let concatPrev = "";
        if (this.partial.original.text && this.partial.concat) {
          concatPrev = this.partial.original.text;
        }
        tmpMessage.original.text = concatPrev + " " + newPartial.original.text ;
        
        tmpMessage.is_final = false;
        this.sendMessage(tmpMessage);
      } else {
        newPartial.is_final = false;
        this.sendMessage(newPartial);
      }
    },
    sendKeyboardMessage(is_final) {
      let text = "...";
      if (this.keyboardInputValue.length > 0) {
        text = this.keyboardInputValue;
      } else if (is_final) {
        return false;
      }
      let message = {
        message_id: this.setMessageId(),
        original: {
          text: text
        },
        is_final: is_final,
      };
      this.sendMessage(message);

      if (is_final) {
        this.keyboardInputValue = '';
      }
      
      
      setTimeout(function() {
        document.querySelector('#keyboard_input_field').focus();
      }, 0);
    },
    clearCurrenPartial(deletemsg = false) {
      if (this.partial.message_id) {
        if (deletemsg) {
          this.deletedMessages.push(this.partial.message_id);
          this.sendMessage({
            message_id: this.partial.message_id,
            original: {
              text: "_INVALID_"
            },
            is_final: true
          });
        }
        
        this.partial = {
          message_id: false,
          original: {
            text: ''
          },
          concat: ''
        };

        this.keyboardInputValue = "";
        //this.typing = false;
      }
    },
    isOwnMessage(message) {
      return this.user_id && this.user_id == message.sender.sender_id;
    },
    hasOwnMessage() {
      return this.partial.original && (this.partial.original.text.length > 0 || this.partial.concat.length > 0);
      /*if (this.partials.length <= 0) {
        return false;
      } else {
        let partialIndex = this.partials.findIndex((message => message.sender_id == this.user_id));
        return partialIndex < 0;
      }*/
    },
    pushPartial(partial) {

      if (this.finalMessages.includes(partial.message_id)) {
        console.log("already")
        return false;
      }

      let senderId = partial.sender_id ?? partial.sender.sender_id;
      if (this.partials.length > 0) {
        let sameSenderMessageIndex = this.partials.findIndex((message => message.sender && message.sender.sender_id == senderId));
        if (sameSenderMessageIndex >= 0) {
          if (partial.message_id != this.partials[sameSenderMessageIndex].message_id) {
            this.partials.splice(sameSenderMessageIndex, 1)
          }
        }
      }

      let partialIndex = this.partials.findIndex((message => message.message_id == partial.message_id));
      console.log(partial);
      if (partial.original.text === '_INVALID_' || partial.original.text == '_crosstalk_is_detected_') {
        if (partialIndex >= 0) {
          this.partials.splice(partialIndex, 1)
        }

        return false;
      }

      if (partialIndex >= 0) {
        this.partials[partialIndex] = partial;
      } else {
        this.partials.push(partial);
      }

      this.scrollTranscripts();
    },
    pushOwnPartial(partial) {
      if (this.finalMessages.includes(partial.message_id)) {
        return false;
      }

      //let partialIndex = this.partials.findIndex((message => message.message_id == partial.message_id));

      if (partial.original.text === '_INVALID_' || partial.original.text == '_crosstalk_is_detected_') {
        return false;
      }

      if (this.isHoldToTalk()) {
        
        this.partial.concat = partial.concat;
        if (partial.is_final) {
          this.partial.original.text += " " + partial.original.text;
          this.partial.concat = "";
        }
      } else {
        this.partial.original.text = partial.original.text;
      }

      this.scrollTranscripts();
    },
    hasTranslationForUser(transcript) {
      const mainLanguage = this.participant.language.slice(0, 2);
      const originalLanguage = transcript.sender.language.slice(0, 2);

      return mainLanguage !== originalLanguage &&  (typeof transcript.translations[this.participant.language] === 'object' &&  transcript.translations[this.participant.language] !== null);
    },
    isSameLanguage(lang1, lang2) {
      const mainLanguage = lang1.slice(0, 2);
      const originalLanguage = lang2.slice(0, 2);

      return mainLanguage === originalLanguage;
    },
    getTranslationForUser(transcript) {
      return transcript.translations[this.participant.language];
    },
    transformTimerSlots(props) {
      const formattedProps = {};

      Object.entries(props).forEach(([key, value]) => {
        formattedProps[key] = value < 10 ? `0${value}` : String(value);
      });

      return formattedProps;
    },
    sendMessage(message) {
        this.apiSocket.emit("message", message);
    },
    closeSocket() {
      this.apiSocket.emit("close_connection");
      this.asrSocket.disconnect();
    },
    noASRmessage() {
      this.showRoomSignupPopup();
    },
    closedRoomMessage() {
      let swalDescription = this.$t('service_event_ended');
      Swal({
        title: this.$t('session_ended'),
        text: swalDescription,
        icon: 'warning',
        dangerMode: false,
        buttons: {
          confirm: {
              text: "OK",
              value: true,
              visible: true,
              className: "",
              closeModal: true
            }
        },
      }).then(() => {
        this.$store.dispatch("event/close");
        this.closeSocket();
        this.$router.replace("/");
      });
    },
    setupRecorder() {
      // setup recorder
      navigator.mediaDevices.getUserMedia({ audio: true }).then(stream => {
        
          if (this.recorder && this.recorder.state !== 'inactive') {
              throw new Error('Stop the recorder first');
          }
          return stream;
          
      }).then((stream) => {
        this.asrConnecting = false;
        let options = { mimeType: 'audio/ogg;codecs=opus' };
          // Start recording
          this.recorder = new MediaRecorder(stream, options, workerOptions);
          // Set record to <audio> when recording will be finished
          this.recorder.ondataavailable = (e) => {
            let blob = e.data;
            let stream = ss.createStream();
            // stream directly to server
            ss(this.asrSocket).emit('audio', stream, {
                size: blob.size
            });

            // pipe the audio blob to the read stream
            ss.createBlobReadStream(blob).pipe(stream);
            if (!this.partial.message_id) {
              this.partial.message_id = this.generateMessageId();
            }
          };
          this.asrSocket.emit('asr-streaming_config', {
            language: this.participant.language,
            token: this.room_token,
            speech_mode: this.$isMobile() ? 'custom_pause' : this.settings.speech_mode,
            ASR_URL: this.event.asr_url,
            ASR_PORT: this.event.asr_port,
            profanity_filter: this.settings.profanity_filter,
            custom_terms: this.custom_terms
          });
          this.recorder.start(100);
          
      }).catch(e => {
          console.log(`MediaRecorder is failed: ${e.message}`);
          //Promise.reject(new Error());
      });
    },
    setupAsrSocket() {
      let asrSocketObject = io(process.env.VUE_APP_SOCKET_URL, {
        path: "/socket.io/",
        auth: {
            token: this.room_token,
            room_code: this.event.room_code
        },
        //transports: ["polling"]
      });

      this.asrSocket = asrSocketObject.on('connect', () => {
        this.asrConnecting = false;
      });

      asrSocketObject.on('connection', () => {
      });

      // On receive ASR result
      asrSocketObject.on('asr', (data) => {
        let recognitionResult = data.result;
        let recognitionText = "";
        if (this.isCustomPause() || this.isHoldToTalk()) {
          recognitionResult = data.result.results[0];
          recognitionText = recognitionResult.alternatives[0].transcript.trim();
        } else {
          recognitionText = recognitionResult.recognition.trim();
        }
      

        if (recognitionText == '_crosstalk_is_detected_' || recognitionText == '_INVALID_' || recognitionText.length <= 0 || !recognitionText) {
          return false;
        }

        let message = {
          message_id: this.setMessageId(),
          original: {
            text: recognitionText
          },
          concat: ""
        };

        //this.partial.original.text = recognitionText;
        //this.setMessageId();

        if (this.isCustomPause()) {
          message.is_final = recognitionResult.is_final;
        } else if (this.isHoldToTalk()) {
          message.is_final = recognitionResult.is_final;
        } else {
          message.is_final = recognitionResult.is_final || recognitionResult.is_stabilized
        }
        
        if (!this.isHoldToTalk()) {
          this.pushOwnPartial(message);
          this.sendMessage(message);
        } else {
          message.concat = message.original.text;
          
          this.pushOwnPartial(message);
          this.sendHoldToTalkPartial(message);
          //this.sendHoldToTalkPartial(message);
          //this.sendHoldToTalkPartial(message);
        }
      

        if (recognitionResult.is_force_stop) {
          this.restartRecording();
        }
      });

      // On receive ASR end 
      asrSocketObject.on('asr-end', () => {
       /* if (this.$refs.asrcountdown) {
          this.$refs.asrcountdown.pause();
        }*/
        if (this.recording) {
          
          this.restartRecording();
        }
        
        //this.stopRecording();
      });
    },
    setupApiSocket() {
      let socketAuth = {
        token: this.room_token,
        room_code: this.event.room_code,
        udid: this.udid
      };

      // TODO: this can be refactored for beautier code
      if(this.user && this.authorized) {
        socketAuth.language = this.user.language;
        socketAuth.display_name = this.user.name;
        socketAuth.gender = this.user.voice;
      } else if (this.guest) {
        socketAuth.language = this.guest.language;
        socketAuth.display_name = this.guest.name;
        socketAuth.gender = this.guest.voice;
      }

      let apiSocketObject = io(this.event.socket_server, {
        path: "/socket.io/",
        auth: socketAuth,
        transports: ["websocket"]
      });

      this.apiSocket = apiSocketObject.on('connect', () => {
        console.log("connected api");
      });
      
      apiSocketObject.on('connection', (data) => {
        this.is_room_owner = data.is_room_owner;
        this.socketRoomOwner = data.is_room_owner;

        if (this.is_room_owner || this.socketRoomOwner) {
          this.showRoomCodePopup();
        } else {
          if (this.isAllowedToSpeak) {
            this.showRoomSettingsPopup();
          } else if(!this.isGuest) {
            this.$notify('session_participation_is_disabled', {
              icon: "error",
            });
          } else if (this.isGuest && !this.preRoomPassed ) {
            this.showLanguageSelector();
          }
        }
      });

      apiSocketObject.on("connect_error", (err) => {
        console.log(err.message);
          this.$notify(err.message);
      });

      apiSocketObject.on("notification", (notification) => {
          this.$notify(notification.message, {
              icon: "info",
              toast: true
          });
      });

      apiSocketObject.on("connected_guests", (data) => {
          this.room.count = data.count;
      });

      apiSocketObject.on("message", (data) => {
        if (!this.started) {
            this.startRoom();
        }

        let newPartial = data;
        if (!this.isOwnMessage(newPartial)) {
          //newPartial.concat = newPartial.original.text;
          console.log("not own")
          this.pushPartial(newPartial);
        } else {
          //console.log(newPartial)
          console.log("own")
        }
        
        if (newPartial.is_final) {
          this.pushTranscript(newPartial);

          if (this.isOwnMessage(newPartial)) {
            this.clearCurrenPartial();
          }
        }
      });

      apiSocketObject.on("room_was_closed", () => {

        this.closedRoomMessage();
        this.closeSocket();
      });

      apiSocketObject.on("tts", (data) => {
        if (this.settings.tts_muted || !permissions.can("tts", this.participant) || (this.settings.tts_same_muted && this.isSameLanguageAudio(data.message_id))) {
          return;
        }
        
        const audio = data.audio;
        if (audio) {
          
          /*const soundPlayerObj = new Howl({
            buffer: audio,
            autoplay: false,
            src: this.getTTSsrc(audio),
            format: [ 'mp3'],
             html5: true
          });
          soundPlayerObj.rate(this.settings.tts_speed / 100);
          soundPlayerObj.play();*/

          this.pushTTSqueue(this.getTTSsrc(audio));
        }
      });

      this.apiSocketObject = apiSocketObject;
      //this.apiSocket =
    },
    pushTTSqueue(ttsSrc)  {
      if (!this.recording) {
        this.ttsQueue.push(ttsSrc);
        this.playTTS();
      }
    },
    playTTS() {
      if (this.ttsQueue.length <= 0) {
        return false;
      }  
      if (!this.ttsPlaying) {
        const firstAudio = this.ttsQueue.shift();
        this.soundPlayerTTS.src = firstAudio;
        this.soundPlayerTTS.playbackRate = this.settings.tts_speed / 100;
        this.soundPlayerTTS.play();
        this.ttsPlaying = true;
        const context = this;
        this.soundPlayerTTS.onended = function(){
          context.ttsPlaying = false;
          context.playTTS();
        };
      }
    },
    stopTTS() {
      if (this.ttsPlaying) {
        this.soundPlayerTTS.pause();
        this.soundPlayerTTS.currentTime = 0;
      }
    },
    setParticipant(socketUser) {
      if (socketUser) {
        this.participant = socketUser;
        return;
      }
      if (this.user && this.authorized) {
        this.participant = this.user;
        this.isGuest = false;
      } else {
        this.participant = this.guest;
        this.isGuest = true;
      }
    },
    setCustomTerms() {
      GlossaryService.getTerms(this.token).then((data) => {
        let termsObjectsArray = data.data.result;
        termsObjectsArray.forEach((term) => {
          this.custom_terms.push(term.term);
        })
      })
    },
    updateParticipant(profile) {
      this.participant.language = profile.language;
      this.participant.name = profile.name;
      this.participant.voice = profile.voice;

      this.apiSocket.emit("update_profile", {
        display_name: this.participant.name ? this.participant.name : "Guest",
        language: this.participant.language,
        gender: this.participant.voice
      });

      this.openLanguageSelector = false;
    },
    setKeyboardShortcuts() {
      const spaceKey = 'Space'; // space
      const escapeKey = 'Escape';
      const altEscapeKey = "Esc";

      window.addEventListener('keydown', (e) => {
        if(this.$route.name == 'room') {
          if (e.code == spaceKey) {
            if (this.anyPopupShown || this.typing) {
              return false;
            }

            if (this.holding) {
              if (this.recording) {
                return false;
              } else {
                this.holding = false;
              }
            } else {
              this.holding = true;
            }

            if (this.isHoldToTalk()) {
              if (!this.micActivated) {
                this.startRecording();
              }
            } else {
              if (!this.micActivated) {
                this.startRecording();
              } else {
                this.stopRecording();
              }
            }

          }

          if (e.code == escapeKey || e.code == altEscapeKey) {
            if (this.recording) {
              this.stopRecording(false, true);
            } else if (this.typing) {
              //this.keyboardInput();
              this.keyboardInputValue = "";
            }
          }
        }
      });

      window.addEventListener('keyup', (e) => {
        if(this.$route.name == 'room') {
          if (e.code == spaceKey && this.recording) {
            
            if (this.isHoldToTalk()) {
              this.holding = false;
              this.stopRecording();
              
            } else {
              this.holding = false;
              
            }
            
          }
        }
      });
      
    },
    getTTSsrc(arrayBuffer) {
      let arrayBufferView = new Uint8Array(arrayBuffer);
      let blob = new Blob( [ arrayBufferView ], { type: 'audio/mp3' } );
      return URL.createObjectURL(blob);
    },
    _unlockAudio() {
      this.soundPlayerRecStart.play();
      this.soundPlayerRecStart.pause();
      this.soundPlayerRecStart.currentTime = 0;

      this.soundPlayerRecStop.play();
      this.soundPlayerRecStop.pause();
      this.soundPlayerRecStop.pause();

      this.soundPlayerTTS.play();
      this.soundPlayerTTS.pause();
      this.soundPlayerTTS.pause();

      this.setTutorialShown(true); // temp disable tutorial
      /*if (!this.tutorialShown && !this.anyPopupShown) {
        this.tour.start();
        this.setTutorialShown(true);
      }*/
      
      if (this.tutorialShown) {
        document.body.removeEventListener('click', this._unlockAudio)
        document.body.removeEventListener('touchstart', this._unlockAudio)
      }
    },
    resetScroller() {
      this.scroller = {
        scrolled: false,
        hasNew: false
      }
      this.scrollTranscripts();
    },
    scrollTranscripts() {
      if (this.scroller.scrolled) {
        return false;
      }
      let scrollEl = document.querySelector(".screen__scroll");
      scrollEl.scrollTop = scrollEl.scrollHeight;
    }
  },
  computed: {
    ...mapState('auth', ['agreed', 'authorized', 'token', 'tutorialShown']),
    ...mapState('lang', ['languages']),
    ...mapState('event', ['event', 'is_room_owner', 'guest', 'settings', 'preRoomPassed']),
    ...mapGetters('auth', [
      'udid',
    ]),
    ...mapState('nonpersist', ['user', 'asrTimeLeft']),
    anyPopupShown() {
      return this.roomCodePopup || this.roomSettingsPopup;
    },
    isHost() {
      return this.authorized;
    },
    isAllowedToSpeak() {
      return !this.isGuest && (this.is_room_owner || this.socketRoomOwner || !this.event.is_participating_disabled);
    },
    speechModeIcon() {
      let modeIcon = "holdtotalk";

      switch (this.settings.speech_mode) {
        case "custom_pause":
          modeIcon = "pausetotranslate";
          break;
        case "live_translate":
          modeIcon = "livetranslate";
          break;
      }
      return require(`@/assets/icons/${modeIcon}.svg`);
    },
    ttsMutedText() {
      return this.settings.tts_muted ? "Sound Off" : "Sound On";
    },
    speechModeTitle() {
      let modeTitle = "Hold To Talk";

      switch (this.settings.speech_mode) {
        case "custom_pause":
          modeTitle = "Natural Pause";
          break;
        case "live_translate":
          modeTitle = "Live Translate";
          break;
      }

      return modeTitle;
    },
    defaultText() {
      
      let modeTitle = "Hold To Talk";

      switch (this.settings.speech_mode) {
        case "custom_pause":
          modeTitle = "Click To Talk";
          break;
        case "live_translate":
          modeTitle = "Click To Talk";
          break;
      }

      return modeTitle;
    }
  },
  watch:{
     'keyboardInputValue': function (){
       if (this.typing) {
        this.sendKeyboardMessage(false);
       }
     },
  },
  async mounted() {
    this.setRoomToken();
    this.setParticipant();
    this.setCustomTerms();
    this.user_id = this.udid;
    
    if (this.user || this.guest) {
      this.setupAsrSocket();
      this.setupApiSocket();
    }
    this.setKeyboardShortcuts();

    document.querySelector('.screen__scroll').addEventListener("scroll", (e) => {
      const scroller = e.target;
      if (scroller.scrollTop + scroller.offsetHeight >= scroller.scrollHeight) {
        this.scroller.scrolled = false;
        this.scroller.hasNew = false;
      } else {
        
        if (scroller.scrollTop < this.scroller.scrollTop) {
          this.scroller.scrolled = true;
        }
        
      }

      this.scroller.scrollTop = scroller.scrollTop;
    })

    this.$refs.roomwrap.addEventListener("mouseup", () => {
      if (this.isHoldToTalk() && this.recording) {
        this.stopRecording();
      }
    })

    this.syncRoomSettings();
    const time = new Date();
    time.setSeconds(time.getSeconds() + this.asrTimeLeft);
    const asrTimer = useTimer(time, false);
    this.asrTimer = asrTimer;

    this.soundPlayerRecStart = this.$refs.start_rec_audio;
    this.soundPlayerRecStop = this.$refs.stop_rec_audio;
    this.soundPlayerTTS = this.$refs.tts_rec_audio;

    document.body.addEventListener('click', this._unlockAudio);
    document.body.addEventListener('touchstart', this._unlockAudio);

    if (!this.tutorialShown ) {
      this.tour = new Shepherd.Tour({
        useModalOverlay: true,
        defaultStepOptions: {
          classes: 'shadow-md bg-purple-dark',
          scrollTo: { behavior: "smooth", block: "center" },
          popperOptions: {
            modifiers: [{ name: "offset", options: { offset: [0, 32] } }]
          },
          modalOverlayOpeningPadding: 10,
          when: {
            show() {
              const currentStep = Shepherd.activeTour?.getCurrentStep();
              const currentStepElement = currentStep?.getElement();
              const footer = currentStepElement?.querySelector('.shepherd-footer');
              const progress = document.createElement('span');
              progress.className = 'shepherd-progress';
              progress.innerText = `${Shepherd.activeTour?.steps.indexOf(currentStep) + 1} of ${Shepherd.activeTour?.steps.length}`;
              footer?.insertBefore(progress, currentStepElement.querySelector('.shepherd-skip'));
            }
          }
        },
      });

      let steps = [];
      if (this.asrTimeLeft > 0 && this.isAllowedToSpeak) {
        steps.push({
          id: 'timer-step',
          text: this.$t('session_walkthrought_timer_desc'),
          attachTo: {
            element: '.timer',
            on: 'top'
          },
          classes: 'example-step-extra-class',
          buttons: [
            {
              text: this.$t('common_skip'),
              action: this.tour.complete,
              classes: "shepherd-skip"
            },
            {
              text: this.$t('common_next'),
              action: this.tour.next
            }
          ],
        });
      }

      if (!this.$isMobile()) {
        steps.push({
            id: 'record-step',
            text: this.$t('session_walkthrought_record_desc'),
            attachTo: {
              element: '.mic',
              on: 'top'
            },
            classes: 'example-step-extra-class',
            buttons: [
              {
                text: this.$t('common_skip'),
                action: this.tour.complete,
                classes: "shepherd-skip"
              },
              {
                text: this.$t('common_next'),
                action: this.tour.next
              }
            ],
        });

        steps.push({
            id: 'record-step2',
            text: this.$t('session_walkthrought_cancel_desc'),
            attachTo: {
              element: '.mic',
              on: 'top'
            },
            classes: 'example-step-extra-class',
            buttons: [
              {
                text: this.$t('common_skip'),
                action: this.tour.complete,
                classes: "shepherd-skip"
              },
              {
                text: this.$t('common_next'),
                action: this.tour.next
              }
            ],
        });
      }

      steps.push({
          id: 'lang-step',
          text: this.$t('session_walkthrought_language_desc'),
          attachTo: {
            element: '.language-tooltip',
            on: 'top'
          },
          classes: 'example-step-extra-class',
          buttons: [
            {
              text: this.$t('common_skip'),
              action: this.tour.complete,
              classes: "shepherd-skip"
            },
            {
              text: this.$t('common_next'),
              action: this.tour.next
            }
          ],
      });

      steps.push({
          id: 'audience-step',
          text: this.$t('session_walkthrought_audience_desc'),
          attachTo: {
            element: '.participants-info',
            on: 'top'
          },
          classes: 'example-step-extra-class',
          buttons: [
            {
              text: this.$t('common_skip'),
              action: this.tour.complete,
              classes: "shepherd-skip"
            },
            {
              text: this.$t('common_next'),
              action: this.tour.next
            }
          ],
      });
      if (!this.$isMobile()) {
        steps.push({
          id: 'settings-step',
          text: this.$t('session_walkthrought_settings_desc'),
          attachTo: {
            element: '.settings-control',
            on: 'top'
          },
          classes: 'example-step-extra-class',
          buttons: [
            {
              text: this.$t('common_skip'),
              action: this.tour.complete,
              classes: "shepherd-skip"
            },
            {
              text: this.$t('common_finish'),
              action: this.tour.complete
            }
          ],
        })
      } else {
        steps.push({
          id: 'settings-step',
          text: this.$t('session_walkthrought_mobile_mute'),
          attachTo: {
            element: '.mute-control',
            on: 'top'
          },
          classes: 'example-step-extra-class',
          buttons: [
            {
              text: this.$t('common_skip'),
              action: this.tour.complete,
              classes: "shepherd-skip"
            },
            {
              text: this.$t('common_finish'),
              action: this.tour.complete
            }
          ],
        })
      }


      this.tour.addSteps(steps);
    }

  },
  created() {
    emitter.on("close_room", () => {
        this.apiSocket.emit("close_room", {});
        this.$store.dispatch("event/close");
        this.$router.replace("/");
        this.closeSocket();
    });

    emitter.on("quit_room", () => {
      this.$store.dispatch("event/close");
      this.$router.replace("/");
      this.closeSocket();
    });
  },
  beforeUnmount() {
    console.log("unmounting");
    this.closeSocket();
  }
}
</script>

<style lang="scss">
  
  .conference-meta {
    font-family: 'Inter', sans-serif;
    position: absolute;
    top: 50px;
    width: 100%;
    z-index: 5;

    &__title {
      font-weight: 700;
      font-size: 1.875rem;
      line-height: 2.2rem;
      text-transform: uppercase;
      text-align: center;

      /* White Audience */

      color: #FFFFFF;
      margin-bottom: 4px;
    }

    &__speaker {
      text-align: center;
      font-weight: 400;
      font-size: 1.4rem;
      line-height: 1.7rem;
      text-transform: capitalize;

      color: #C2C2C2;
    }

    &__logo {
      display: flex;

      img {
        width: 125px;
      }
    }
  }

  .room {
    height: 100%;

    &.anyPopupShown {
      justify-content: center;
      align-items: center;
    }
    
    &-overlay {
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      background: rgb(0,0,0);
      background: linear-gradient(90deg, rgba(0,0,0,0.6727065826330532) 60%, rgba(0,0,0,1) 97%, rgba(0,0,0,1) 100%);
      z-index: 4;
    }
  }

  .screen {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding-top: 30px;
    width: 70%;
    margin: auto;
    position: relative;

    @media all and (max-width: 800px) {
      width: 100%;
    }

    &__scroll {
      min-height: 600px;
      max-height: 600px;
      width: 100%;
      margin: auto auto 0 auto;
      opacity: 0;
      visibility: hidden;
    
      &.visible {
        opacity: 1;
        visibility: visible;
        position: relative !important;
      }

      .scrollbar__content {
        width: 100%;
        height: 100%;
        min-height: 600px;
        display: flex;
      }

    }
    .screen__default {
      font-weight: 700;
      font-size: 3rem;
      line-height: 3.625rem;
      text-align: center;

      color: #FFFFFF;

      opacity: 0.2;
      margin: auto;
      position: absolute;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      text-transform: uppercase;
      flex-direction: column;

      img {
        width: 5rem;
      }
    }
  }

  .toolbar {
    display: flex;
    margin-top: auto;
    width:100%;
    justify-content: space-between;
    padding: 0px 30px 24px;
    align-items: flex-end;

    &-left, &-right {
      display: flex;
    }

    &-filler {
      width: 75px;
      height: 75px;
      display: flex;
      margin: 0 20px;
    }

    .mute-control {
      border: 2px solid #464646;
      border-radius: 50%;
      width: 60px;
      height: 60px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }

    .participants-info {
      background: rgba(43, 43, 43, 0.8);
      border: 2px solid #464646;
      border-radius: 50%;
      width: 75px;
      height: 75px;
      justify-content: center;
      align-items: center;

      @media (max-width: 800px) {
        width: 60px;
        height: 60px;
      }

      &__icon {
        width: 32px;
        margin-bottom: 4px;
      }

      &__count {
        font-weight: 700;
        font-size: 0.875rem;
        line-height: 1.1rem;

        /* White Audience */

        color: #FFFFFF;

        @media all and (max-width: 800px) {
          font-size: 0.65rem;
          line-height: 0.9rem;
        }
      }
    }

    .settings-control {
      width: 75px;
      height: 75px;
      
      
      display: flex;
      align-items: flex-end;
      flex-direction: column;
      position: relative;
      z-index: 5;
      /*right: 30px;*/
      
      &__threedots {
        background: transparent;
        border: none;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 75px;
        
        outline: none;
        cursor: pointer;
      }
    }

    .settings-menu {
      flex-direction: column;
      justify-content: flex-end;
      width: 100%;
      background: rgba(43, 43, 43, 0.8);
      position: absolute;
      bottom: 0;
      border: 2px solid #464646;
      border-radius: 47px;

      &__options {
        padding-top: 26px;
      }

      &-item {
        display: flex;
        position: relative;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 20px;

        &:first-child {
          margin-top: 0;
        }

        img {
          max-width: 42px;
        }

        &__text {
          width: 124px;
          text-align: right;
          font-weight: 400;
          font-size: 1.125rem;
          line-height: 1.7rem;
          color: #FFFFFF;
          position: absolute;
          left: -140px;
        }
      }

      .font-changer {
        background: #111111;
        border-radius: 27.4641px;
        display: flex;
        flex-direction: column;
        width: 54px;

        &__bigger {
          text-align: center;
          padding: 18px 0 15px;
          border-bottom: 2px solid rgba(255, 255, 255, 0.25);
        }

        &__smaller {
          text-align: center;
          padding: 15px 0 18px;
        }
      }
    }

    .speech-control {
      position: relative;

      &__status {
        font-weight: 400;
        font-size: 0.75rem;
        line-height: 1rem;
        color: #FFFFFF;
        text-transform: uppercase;
        text-align: center;
        position: absolute;
        width: 100%;
        top: -28px;
      }

      .mic {
        width: 120px;
        height: 120px;
        justify-content: center;
        align-items: center;
        background: #FFFFFF;
        border-radius: 50%;
        cursor: pointer;
        margin: 0 24px;

        @media all and (max-width: 800px) {
          width: 85px;
          height: 85px;

          img {
            max-width: 30px;
          }
        }
      }

      .mic-stop, .mic.loader {
        background: #EA4335;
      }

      .mic-wave {
        display: flex;
        align-items: center;
        justify-content: center;
        background: #EA4335;
      }

      #wave {
        height: 70px;
        width: 70px;
        fill: #fff;
      }

      @for $i from 1 through 9 {
        #Line_#{$i} {
          animation: pulse 1s infinite;
          animation-delay: $i * .15s;
        }
      }

      @keyframes pulse {
        0% {
          transform: scaleY(1);
          transform-origin: 50% 50%;
        }
        
        50% {
          transform: scaleY(.7);
          transform-origin: 50% 50%;
        }
        
        100% {
          transform: scaleY(1);
          transform-origin: 50% 50%;
        }
      }
      

      .timer {
        font-weight: 700;
        font-size: 1.375rem;
        line-height: 1.7rem;
        color: rgba(255, 255, 255, 0.5);
        text-align: center;
        margin-top: 10px;
        height: 27px;
        margin-bottom: 10px;

        span {
          min-width: 70px;
          
        }

        @media all and (max-width: 768px) {
          display: none;
        }
      }

      &.notAllowed {
        .mic {
          animation: none !important;
          opacity: 0.6;
          cursor: default;
        }

        .keyboard-input-btn {
          opacity: 0.6;
          cursor: default;
        }
      }
    }
  }

  .transcripts {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0 30px;
    align-items: flex-end;
    font-family: "Inter", sans-serif;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.125rem;
    min-height: 100%;

    @media all and (max-width: 800px) {
      padding: 0 15px;
    }

    color: #FFFFFF;
    justify-content: flex-end;

    &__item {
      padding: 15px;
      border-radius: 15px;
      background: rgba(255, 255, 255, 0.05);
      border-radius: 10px;
      width: 100%;
      margin-bottom: 20px;
       position: relative;

      /*&:last-child {
        margin-bottom: 0;
      }*/

      &--partial {
        background: #FFFFFF;
        color: #000000;

        .transcript__sendername {
          color: #000000;
        }

        .transcript__flag {
          margin-top: 5px;
        }
      }

      &.keyboard-input {
        padding-right: 60px;
        position: relative;
        display: flex;

        .keyboard-input__send {
          width: 30px;
          display: flex;
          position: absolute;
          top: 0;
          right: 15px;
          align-items: center;
          height: 100%;

          img {
            max-width: 100%;
          }
        }
      }

      .transcript__original.noTranslations {
        opacity: 0.5;
        border-bottom: 1px solid grey;
        padding-bottom: 4px;

        .transcript__text {
          font-size:  v-bind('fontSize * 0.6 + fontDim');
          line-height: 150%;
        }

        .transcript__flag {
          width: v-bind('fontSize * 0.8 * 1.2 + fontDim');
          height: v-bind('fontSize * 0.8 * 1.2 + fontDim');
        }
      }

      .transcript__original {

        .transcript__read {
          width: 24px;
          position: absolute;
          right: 15px;
          bottom: 10px;
        }
      }

      .transcript__translation {
        margin-top: 0px;
      }
    }
  }

  .transcript__text {
    font-weight: 400;
    font-size: v-bind('fontSize + fontDim');
    line-height: 150%;
    word-break: break-word;

    &:first-letter {
      text-transform: uppercase;
    }
  }

  .transcript__time {
    position: absolute;
    right: 15px;
    font-weight: 400;
  }

  .transcript__sendername {
    font-weight: 700;
    margin-bottom: 10px;
    color: #fff;
    display: flex;
    align-items: center;
  }

  .transcript__flag {
    width: v-bind('fontSize * 1.2 + fontDim');
    height: v-bind('fontSize * 1.2 + fontDim');
    border-radius: 50%;
    display: flex;
    justify-content: center;
    overflow: hidden;
    margin-right: 10px;
    float: left;
  }

  .chevron-to-bottom-scroller {
    position: absolute;
    border-radius: 50%;
    width: 48px;
    height: 48px;
    background: rgba(43, 43, 43, 0.8);
    right: 0;
    bottom: -26px;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      max-width: 70%;
    }

    .new-indicator {
      width: 12px;
      height: 12px;
      border-radius: 50%;
      display: flex;
      background: #EA4335;
      overflow: hidden;
      position: absolute;
      top: -2px;
      right: 2px;
    }
  }

  .speech__wrap {
    display: flex;
    justify-content: space-evenly;
    align-items: center;

    /*@media all and (max-width: 768px) {
      display: none;
    }*/



    .keyboard-input-btn {
      width: 52px;
      cursor: pointer;

      @media all and (max-width: 800px) {
        display: none;
      }

      img {
        width: 100%;
      }
    }

    .speech-mode-selector {
      cursor: pointer;
    }
  }

  .user-language-selector {
      width: 45px;
      height: 45px;
      border-radius: 50%;
      overflow: hidden;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        max-height: 100%;
      }

      &--mobile {
        display: none;

        @media all and (max-width: 768px) {
          display: flex;
          width: 72px;
          height: 72px;
        }
      }
  }

  .room-modals {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 4;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .hidden-audio-players {
    display: none !important;
  }

  .pulsing {
    animation: pulse 1.5s infinite;
  }

  .loader-4 {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
  }
  .loader-4 .spinner {
    position: relative;
    width: 100%;
    height: 100%;
  }
  .loader-4 .spinner .loader-circle-1 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0.3rem #fff solid;
    border-radius: 50%;
    border-left: 0.3rem #46E0D4 solid;
    -webkit-animation: spinner 1.5s infinite;
            animation: spinner 1.5s infinite;
  }
  .loader-4 .spinner .loader-circle-2 {
    position: absolute;
    top: 10%;
    left: 10%;
    width: 80%;
    height: 80%;
    border: 0.3rem #fff solid;
    border-radius: 50%;
    border-left: 0.3rem #46E0D4 solid;
    -webkit-animation: spinner 1.5s 200ms infinite;
            animation: spinner 1.5s 200ms infinite;
  }
  .loader-4 .spinner .loader-circle-3 {
    position: absolute;
    top: 20%;
    left: 20%;
    width: 60%;
    height: 60%;
    border: 0.3rem #fff solid;
    border-radius: 50%;
    border-left: 0.3rem #46E0D4 solid;
    -webkit-animation: spinner 1.5s 350ms infinite;
            animation: spinner 1.5s 350ms infinite;
  }
  @-webkit-keyframes spinner {
    0% {
      transform: rotate(0);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  @keyframes spinner {
    0% {
      transform: rotate(0);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  

  @media all and (max-height: 900px) {
    .screen {
      padding-top: 10px;
    }

    .screen__scroll {
      min-height: 480px;
      max-height: 480px;

      .scrollbar__content {
        min-height: 480px;
      }
    }

    .transcripts {

      &__item {
        margin-bottom: 16px;
        padding: 12px;
      }
    }

    .toolbar {
      padding: 0 16px 16px;
    }

    .conference-meta {
      top: 0;

      &.mobileMeta {
        z-index: 5;
      }
    }

    .mic {
      width: 100px;
      height: 100px;
    }

    .timer {
      margin-bottom: 0;
    }
  }

  @media all and (max-height: 700px) {
    .screen {
      padding-top: 10px;
    }

    .screen__scroll {
      min-height: 300px;
      max-height: 300px;

      .scrollbar__content {
        min-height: 300px;
      }
    }
  }

  @media all and (max-width: 800px) {
    .screen__scroll {
      min-height: 500px;
      max-height: 500px;

      .scrollbar__content {
        min-height: 500px;
      }
    }

    .meta-footer {
      background: #3E3E3E;
      border-bottom: 1px solid #C2C2C2;
      box-shadow: 0px 8px 25px rgba(0, 0, 0, 0.5);
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 16px;

      .conference-meta__title {
        font-size: 0.8rem;
        margin: 0;
      }

      .conference-meta__speaker {
        font-size: 0.75rem;
      }
    }
  }

  .mobile-meta-header {
    display: flex;
    justify-content: space-between;
    padding: 20px 16px;
    background: #202020;
    border-bottom: 1px solid #C2C2C2;
    box-shadow: 0px 8px 25px rgba(0, 0, 0, 0.5);

    .mobile-info-btn {
      svg {
        width: 30px;
        height: 30px;
      }
    }
  }

  .forum-info-popup {
    font-family: "Inter";
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    position: absolute;
    z-index: 9;
    background: #202020;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 32px;

    .conference-close {
      position: absolute;
      top: 16px;
      right: 16px;
    }

    .forum-info__text {
      color: #46E0D4;
      font-size: 1.2rem;
      text-align: center;
      margin-bottom: 32px;
    }

    .forum-info__links {
      display: flex;
      justify-content: space-between;
      margin-bottom: 32px;
    }

    .forum-info__footer {
      display: flex;
      flex-direction: column;
      row-gap: 24px;
      justify-content: center;
      text-align: center;

      b {
        color: #fff;
        font-size: 1.1rem;
      }

      span {
        color: #fff;
        font-size: 0.9rem;
        opacity: 0.6;
      }
    }
  }

  .loader {
    position: relative;
    overflow: hidden;
  }

  .default-03 .loader-container {
    width: 80px;
    height: 80px;
    animation: spin 2.5s infinite cubic-bezier(0.17, 0.72, 0.55, 1.66);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .default-03 .ball {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0);
    width: 11%;
    height: 50%;
    background-color: transparent;
    transform-origin: bottom center;
    overflow: visible;
  }
  .default-03 .ball:nth-of-type(2) {
    transform: translate(-50%, 0) rotate(36deg);
  }
  .default-03 .ball:nth-of-type(3) {
    transform: translate(-50%, 0) rotate(72deg);
  }
  .default-03 .ball:nth-of-type(4) {
    transform: translate(-50%, 0) rotate(108deg);
  }
  .default-03 .ball:nth-of-type(5) {
    transform: translate(-50%, 0) rotate(144deg);
  }
  .default-03 .ball:nth-of-type(6) {
    transform: translate(-50%, 0) rotate(180deg);
  }
  .default-03 .ball:nth-of-type(7) {
    transform: translate(-50%, 0) rotate(216deg);
  }
  .default-03 .ball:nth-of-type(8) {
    transform: translate(-50%, 0) rotate(252deg);
  }
  .default-03 .ball:nth-of-type(9) {
    transform: translate(-50%, 0) rotate(288deg);
  }
  .default-03 .ball:nth-of-type(10) {
    transform: translate(-50%, 0) rotate(324deg);
  }
  .default-03 .ball::before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 56%;
    border-radius: 70px;
    background-color: #fff;
  }
  .default-03 .ball::after {
    position: absolute;
    content: "";
    bottom: -100;
    left: 0;
    width: 100%;
    height: 56%;
    border-radius: 70px;
    background-color: #ffc7bd;
    animation: default3 2.5s infinite linear;
    opacity: 0;
  }
  @keyframes spin {
    0% {
      transform: translate(-50%, -50%) rotate(0deg);
    }
    100% {
      transform: translate(-50%, -50%) rotate(360deg);
    }
  }
  @keyframes default3 {
    20% {
      opacity: 1;
    }
    40% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  .scroll-area {
    position: relative;
    margin: auto;
    width: 600px;
    height: 400px;
  }

@keyframes pulse
{
    0%
    {
        box-shadow: 0 0 0 0 rgba(255,255,255,.7), 0 0 0 0 rgba(255,255,255,.7);
    }
    
    100%
    {
        box-shadow: 0 0 0 0 rgba(255,255,255,.0), 0 0 0 100px rgba(255,255,255,0);
    }
}

/**
tutorial styles
*/

.shepherd-element {
  margin-top: -15px;
}

.shepherd-footer {
  align-items: center;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  display: flex;
  justify-content: space-between;
  padding: 0 .75rem .75rem;
  
  .shepherd-button:last-child {
    margin-right: 0;
  }
    
  .shepherd-progress {
    font-size: .8rem;
    margin-right: auto;
    font-weight: bold;
  }

  .shepherd-button {
    background: #46E0D4;

    &:hover {
      background: #46E0D4 !important;
      opacity: 0.8;
    }
    
    &.shepherd-skip {
      background: #979797;
      
      &:hover {
        background: #979797 !important;
      }
    }

    
  }
}

.shepherd-element {
  border: 2px solid #46E0D4;
}

.shepherd-arrow:before {
  border: 2px solid #46E0D4;
    border-left: none;
    border-top: none;
}

#keyboard_input_field {
  caret-color: #000000 !important;
  outline: none !important;
  flex: 1;
  display: flex;
  word-break: break-word;
}

.clear,
.clear:before,
.clear:after{
	content:"";
	display:block;
	clear:both;
}

</style>
