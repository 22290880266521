<template>
  <div class="overlay">
    <video-background 
            src="/media/output.mp4"
            style="height: 100%;"
        >
        <div class="overlay__bg"></div>
  </video-background>
  </div>
  <div class="host flex-centered">
    <agreement-modal v-if="!authorized && !guestAgreed"></agreement-modal>
    <event-start :is_host="false" v-else></event-start>
  </div>
  <main-footer></main-footer>
  
</template>

<script>
// @ is an alias to /src
import VideoBackground from 'vue-responsive-video-background-player';
import MainFooter from '@/components/MainFooter.vue';
import EventStart from '@/components/EventStart.vue'
import AgreementModal from '@/components/AgreementModal.vue';

import { mapState } from 'vuex';

export default {
  name: 'JoinView',
  components: {
    VideoBackground,
    MainFooter,
    EventStart,
    AgreementModal
  },
  data() {
    return {
      errors: {},
      title: "", 
      heading: '',
      profileState: false,
      createdState: false,
    }
  },
  methods: {
  },
  computed: {
    ...mapState('auth', ['agreed', 'authorized']),
    ...mapState('nonpersist', ['user', 'guestAgreed'])
  },
  mounted() {
    
  }
}
</script>

<style lang="scss">
  .host__container {
    min-height: 550px;
  }

  .profile-link {
    height: 4.375rem;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 35px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 46px 0 31px;
    font-family: 'Inter';
    background: rgba(255, 255, 255, 0.05) url("@/assets/icons/arrow-gray.svg") 92% 46% no-repeat;

    &__name {
      font-weight: 700;
      font-size: 1.125rem;
      line-height: 1.375rem;
      /* identical to box height, or 122% */

      text-transform: capitalize;

      /* Label Dark / Primary */

      color: #FFFFFF;
    }

    &__lang {
      margin-right: 10px;
    }

    img {
      width: 25px;
    }
  }

  .host-form {
    width: 70%;

    input, button {
      width: 100% !important;
    }
  }
</style>
