import api from '@/services/api.service';

class TranscriptsService {
    getSessionsList(params) {
        return api
            .post('/conference-get-list', params);
    }
    getTranscriptUrl(item, token) {
        return api.defaults.baseURL + "/conference-export" + "?format=txt&token=" + token + "&conference_id=" + item._id;
    }
}
export default new TranscriptsService();