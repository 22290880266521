export default new class StringService {
    
    subscription(type) {
        let tierString = "";
        switch(type) {
            case "basic":
                tierString = "Free Plan";
                break;
            default: 
                tierString = type + " Plan";
                break;    
        }

        return tierString;
    }
}