<template>
  <img :src="source1x" :srcset="source2x + ' 2x'" :alt="alt">
</template>

<script>
// @ is an alias to /src

export default {
  name: 'RetinaImage',
  props: {
    source: {
      type: String,
      required: true
    },
    ext: {
      type: String,
      required: true
    },
    alt: {
      type: String,
      required: false,
      default: ''
    }
  },
  computed: {
    source1x() {
      return require("@/assets/media/" + this.source + '.' + this.ext)
    },
    source2x() {
      return require("@/assets/media/" + this.source + '@2x.' + this.ext)
    }
  }
}
</script>