export const counter = {
    namespaced: true,
    state: {
        asrTimeLeft: 0,
    },
    actions: {
      
    },
    getters: {
    },
    mutations: {
        setAsrLeft(state, asrTimeLeft) {
            state.asrTimeLeft = asrTimeLeft;
        }
    }
  }