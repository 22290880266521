<template>
  <div class="mobile-meta-header"  v-if="$isMobile()">
        <a href="/" class="conference-meta__logo">
          <img src="@/assets/logo-sm.svg" width="167" alt="">
        </a>
        <a href="javascript:;" class="mobile-info-btn" @click="showPopup">
          <svg width="78" height="78" viewBox="0 0 78 78" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M39 33.8573V57.0001M39 18.4287V23.5716" stroke="#46E0D4" stroke-width="5.14286" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M39 75C58.8823 75 75 58.8823 75 39C75 19.1177 58.8823 3 39 3C19.1177 3 3 19.1177 3 39C3 58.8823 19.1177 75 39 75Z" stroke="#46E0D4" stroke-width="5.14286" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
        </a>
        <app-info @close="hidePopup" v-show="popup"></app-info>
      </div>
</template>

<script>
import AppInfo from '@/components/includes/AppInfo.vue'
// @ is an alias to /src

export default {
  name: 'MobileHeader',
  data() {
    return {
        popup: false
    }
  },
  components: {
    AppInfo
  },
  methods: {
    hidePopup() {
        this.popup = false
    },
    showPopup() {
        this.popup = true
    }
  },
  computed: {
   
  }
}
</script>

<style lang="scss">
  
</style>


