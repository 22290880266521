import LanguagesService from '../services/languages.service';

export const lang = {
  namespaced: true,
  state: {
    languages: []
  },
  actions: {
    get({ commit }) {
      return LanguagesService.getArray().then(
        response => {
          commit('setLanguages', response.data);
        },
        error => {
          commit('loginFailure', error);
        }
      );
    },
  },
  getters: {
    getLanguages(state) {
      return state.languages;
    }
  },
  mutations: {
    setLanguages(state, languages) {
      state.languages = languages;
    }
  }
}