<template>
  <header class="main-header">
    <router-link to="/" class="main-header__logo">
      <img src="@/assets/website/img/logo-light.svg" width="167" alt="">
    </router-link>
        <nav class="website-nav">
          <!--<router-link to="/faq" active-class="active">{{ $t('how_it_works_title')}}</router-link>-->
          <router-link to="/plans" active-class="active">{{ $t('pricing_title') }}</router-link>
          <!--<router-link to="/about" active-class="active">{{ $t('about_title')}}</router-link>-->
          <a href="javascript:;" @click="openBeacon">{{ $t('contact_us_title')}}</a>
          <router-link to="/start" active-class="active"  v-if="!authorized" class="orange-link">{{ $t('signin_title')}}/{{ $t('register_title')}}</router-link>
          <router-link to="/profile" active-class="active"  v-else class="orange-link">{{ $t('profile_title')}}</router-link>
          <locale-selector :initial="$i18n.locale"></locale-selector>
        </nav>
      </header>
</template>

<script>
// @ is an alias to /src
import { mapState } from 'vuex';
import LocaleSelector from "@/components/LocaleSelector";

export default {
  name: 'MainHeader',
  components: {
    LocaleSelector
  },
  methods: {
    openBeacon() {
      window.Beacon('open');
    }
  },
  computed: {
     ...mapState('auth', ['token', 'authorized']),
    displayLogo() {
      return this.$route.name != 'home'
    }
  }
}
</script>

<style lang="scss">
    .wrapper {
        justify-content: flex-start !important;
    }

  .main-header {
    padding: 33px 30px 0;
    display: flex;
    justify-content: space-between;

    &__logo {  
      position: relative;
      z-index: 10;
    }
  }

  .website-nav {
    display: flex;
    justify-content: flex-end;
    margin-left: auto;
    align-items: center;

    a {
      margin-left: 75px;
      font-weight: 300;
      font-size: 1.3rem;
      line-height: 2rem;
      /* identical to box height */
      text-align: center;
      text-transform: uppercase;

      color: #979797;

      &:first-child {
        margin-left: 0;
      }

      &.orange-link, &:hover{
        color: #46E0D4;
        display: inline;
      }

      &.active {
        color: #46E0D4;
        font-weight: 700;
      }

      @media all and (max-width: 1400px) {
        font-size: 1rem;
        margin-left: 55px;
      }
    }
  }
</style>
