<template>
  <custom-scrollbar  class="profile-scroll" :class="{visible: selectorExpanded}" :autoHide="false">
    <div class="profile-settings">
            <div class="settings-item">
                <div class="tts-settings">
                    <div class="table-item">
                      <div class="table-item__left">
                        <span>{{ $t('plan_type') }}</span>
                      </div>
                      <div class="table-item__right">
                        <span>{{ subscription.title }}</span>
                      </div>
                    </div>
                    <div class="table-item">
                      <div class="table-item__left">
                        <span>{{ $t('plan_start_date') }}</span>
                      </div>
                      <div class="table-item__right">
                        <span>{{ subscription.period_started_at }}</span>
                      </div>
                    </div>
                    <div class="table-item">
                      <div class="table-item__left">
                        <span>{{ $t('plan_expiration_date') }}</span>
                      </div>
                      <div class="table-item__right">
                        <span>{{ subscription.period_expired_at }}</span>
                      </div>
                    </div>
                </div>    
            </div>
            <div class="settings-item">
                <label class="item-label">
                  <span class="item-label__text">{{ $t('plan_credit') }}</span>
                </label>
                <div class="tts-settings">
                    <div class="table-item">
                      <div class="table-item__left">
                        <span>{{ $t('plan_sessions_remaining') }}</span>
                      </div>
                      <div class="table-item__right">
                        <span v-if="features.host_event.total_allowed_items >= 999">{{ $t('common_unlimited') }}</span>
                        <span v-else>
                          <span class="left-items">{{ features.host_event.left_allowed_items }}</span> / {{ features.host_event.total_allowed_items }}
                        </span>
                      </div>
                    </div>
                    <div class="table-item">
                      <div class="table-item__left">
                        <span>{{ $t('plan_asr_remaining') }}</span>
                      </div>
                      <div class="table-item__right">
                        <span>{{ asrHoursLeft }} {{ asrMinutesLeft }} {{ asrSecondsLeft }}</span>
                      </div>
                    </div>
                </div>
                
            </div>
            <div class="settings-item">
                <label class="item-label">
                  <span class="item-label__text">{{ $t('plan_features') }}</span>
                </label>
                <div class="tts-settings">
                    <div class="table-item">
                      <div class="table-item__left">
                        <span>{{ $t('transcripts_title')}}</span>
                      </div>
                      <div class="table-item__right">
                        <span>{{ availabilityStatus(features.transcripts.allowed) }}</span>
                      </div>
                    </div>
                    <div class="table-item">
                      <div class="table-item__left">
                        <span>{{ $t('session_setting_translate_after_pause') }}</span>
                      </div>
                      <div class="table-item__right">
                        <span>{{ availabilityStatus(features.asr.continuous_recording) }}</span>
                      </div>
                    </div>
                    <div class="table-item">
                      <div class="table-item__left">
                        <span>{{ $t('session_setting_translate_live') }}</span>
                      </div>
                      <div class="table-item__right">
                        <span>{{ availabilityStatus(features.asr.real_time_translation) }}</span>
                      </div>
                    </div>
                    <div class="table-item">
                      <div class="table-item__left">
                        <span>{{ $t('speech_synthesis') }}</span>
                      </div>
                      <div class="table-item__right">
                        <span>{{ availabilityStatus(features.custom_terms.allowed) }}</span>
                      </div>
                    </div>
                </div>
                
            </div>
          </div>
  </custom-scrollbar>
          <div class="subscription-upgrade">
            <a href="/plans" class="orange-btn form-btn">{{ $t('common_see_plans') }}</a>
            <a href="javascript:;" class="danger-btn" @click="cancelSubscription" v-if="canCancel">
              <img src="@/assets/icons/sub-cancel-icon.svg" alt="Cancel Subscription">
              {{ $t('profile_cancel_subscription')}}
            </a>
          </div>
    
</template>

<script>
// @ is an alias to /src
import { mapState } from 'vuex';
import ProductService from '@/services/products.service'

export default {
  name: 'SubscriptionPage',
  props: {
    subscription: {
      type: Object
    },
    features: {
      type: Object
    }
  },
  components: {

  },
  data() {
    return {
    }
  },
  methods: {
    availabilityStatus(bool) {
      return bool ? this.$t('common_available') : this.$t('common_unavailable');
    },
    cancelSubscription() {
      if (this.subscription.payment_type != 'stripe' && this.subscription.payment_type.length > 0) {
        this.$notify('apple_subscription_notice', {
          icon: "warning",
        })
        return false;
      }

      this.$notify('profile_subscription_cancel_warning', {
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: this.$t('common_cancel'),
        confirmButtonText: this.$t('common_yes'),
        focusCancel: true,
      }).then((result) => {
        if (result.isConfirmed) {
            ProductService.cancel(this.token).then(() => {
              this.$notify('profile_subscription_cancel_success', {
                icon: "success"
              }).then(() => {
                location.reload();
              })
            })
        }
      })
    },
    getPlanTitle(plan) {
      let planTitle;
      if (this.plansMapping[plan.type]) {
        planTitle = this.plansMapping[plan.type];
      } else {
        planTitle = "Custom";
      }

      return planTitle;
    }
  },
  computed: {
    ...mapState('auth', ['token', 'authorized', 'agreed']),
    ...mapState('locale', ['plansMapping']),
    asrHoursLeft() {
      console.log(this.features.asr.left_allowed_items);
      return Math.floor(this.features.asr.left_allowed_items / 60 / 60) + this.$t('common_hours_short');
    },
    asrMinutesLeft() {
      return Math.floor(this.features.asr.left_allowed_items / 60 % 60) + this.$t('common_minutes_short');
    },
    asrSecondsLeft() {
      return this.features.asr.left_allowed_items % 60 + this.$t('common_seconds_short')
    },
    isCanceled() {
      return this.subscription.upcoming_changes && this.subscription.upcoming_changes.type == 'basic';
    },
    canCancel() {
      return this.subscription.type != 'basic' && !this.isCanceled && this.subscription.type != 'teams_participant' && this.subscription.type != 'organizations_participant';
    }
  }
}
</script>

<style lang="scss">

  
</style>
