import { createI18n  } from "vue-i18n";
import axios from "axios";
import {Constants} from "@/cfg/constants";
import store from "@/store";

export const i18n = createI18n ({
    locale: store.state.locale.locale,
    fallbackLocale: "en",
    silentTranslationWarn: true
})
function setI18nLanguage(lang) {
    if (i18n.mode === 'legacy') {
        i18n.global.locale = lang
    } else {
        i18n.global.locale.value = lang
    }
    document.querySelector("html").setAttribute("lang", lang);
    return lang;
}

export function loadLanguageAsync(lang) {
    return axios.get(Constants.NODE_URL + Constants.NODE_API_PREFIX + '/translations',
        { params: { lang: lang }
        }).then((response) => {

        let msgs = response.data;
        i18n.global.setLocaleMessage(lang, msgs);
        //i18n.setLocaleMessage(lang, msgs);
        setI18nLanguage(lang);
    })
}
