export const nonpersist = {
  namespaced: true,
  state: {
    user: {},
    prefetched: false,
    asrTimeLeft: 0,
    guestAgreed: false,
  },
  actions: {
    
  },
  getters: {
  },
  mutations: {
    setUser(state, user) {
      state.user = user;
    },
    setPrefetched(state, bool) {
        state.prefetched = bool;
    },
    setAsrTimeLeft(state, asrTimeLeft) {
      state.asrTimeLeft = asrTimeLeft;
    },
    setGuestAgreed(state, bool) {
      state.guestAgreed = bool;
    }
  }
}