<template>
    <div class="page-wrapper page-wrapper--plans">
        <div class="pricing">
            <div class="pricing-header">
                <div class="pricing-header__left">{{ $t('website_plans_compare') }}</div>
                <div class="pricing-header__right" v-if="yearlyEnabled">
                    <span>{{ $t('website_plans_monthly') }}</span>
                    <Toggle v-model="yearly"/>
                    <span>{{ $t('website_plans_yearly') }} {{ $t('website_plans_yearly_discount') }}</span>
                </div>
            </div>
            <div class="pricing-tabs">
                <div class="tabs-controls" v-if="this.teamPlansEnabled">
                    <a href="#individual" :class="{ active: tab == 'individual'}"
                       @click="setTab('individual')">{{ $t('plans_individual') }}</a>
                    <a href="#team" :class="{ active: tab == 'team'}" @click="setTab('team')">{{ $t('plans_team') }}</a>
                </div>
                <div class="tabs-controls__subheader" v-if="tab == 'team'">{{ $t('plans_teams_subheader') }}</div>
                <div class="tabs-content">
                    <div class="tab" data-tab="individual" v-show="tab == 'individual'">
                        <div class="pricing-table pricing-table--3col">
                          <!-- eslint-disable -->
                            <div class="plan-column" v-for="(item, code) in displayProducts" :key="item.title">
                                <div class="plan" v-if="!item.is_custom">
                                    <div class="plan__price">
                                        <b v-if="!yearly">${{ getMonthlyPrice(item) }}</b>
                                        <b v-else>${{
                                                getYearlyPrice(item)
                                            }}</b>/{{ yearly ? $t('common_year') : $t('common_month') }}
                                      <span class="plan__price--year-monthly" v-if="yearly && item.payment_types.stripe && item.payment_types.stripe.prices.yearly.discount">
                                        (–{{ item.payment_types.stripe.prices.yearly.discount }}% {{ $t('common_discount') }})
                                      </span>
                                    </div>
                                    <div class="plan__price plan__price--year-monthly" v-if="yearly"
                                         :style="{ visibility: getMonthlyPrice(item) > 0 ? 'visible' : 'hidden' }">
                                        <b>${{ getMonthlyPrice(item, true) }}</b>/{{ $t('common_month') }}

                                    </div>
                                    <div class="plan__title">
                                        <h2>{{ item.title }}</h2>
                                        <div class="plan__desc">{{ $t(code.toLowerCase() + '_plan_description') }}</div>
                                    </div>
                                    <div class="plan__features">
                                        <ul>
                                            <li>{{ $t('common_host') }} <b>{{ getSessionsLimit(item) }}</b>
                                                {{ $t('common_sessions') }}
                                            </li>
                                            <li><b>{{ item.features.host_event.participants_per_event }}
                                                {{ $t('website_plans_participants') }}</b>
                                                <TooltipWrap hover
                                                             :content="this.$t('website_plans_participants_tooltip')"
                                                             arrow="true">
                                                    <img src="@/assets/icons/information.svg" alt=""></TooltipWrap>
                                            </li>
                                            <li><span><b>{{ getASRlimit(item) }}</b> {{ $t('website_plans_asr_limit') }}
                                   <br>{{ $t('common_per') }} {{ $t('common_month') }} </span>
                                                <TooltipWrap hover :content="this.$t('website_plans_asr_tooltip')"
                                                             arrow="true">
                                                    <img src="@/assets/icons/information.svg" alt=""></TooltipWrap>
                                            </li>
                                            <li v-if="item.features.custom_terms.allowed">
                                                <b>{{ $t('website_plans_custom_terms') }}
                                                    ({{ item.features.custom_terms.total_allowed_items }})</b>
                                                <TooltipWrap hover
                                                             :content="this.$t('website_plans_custom_terms_tooltip')"
                                                             arrow="true">
                                                    <img src="@/assets/icons/information.svg" alt=""></TooltipWrap>
                                            </li>
                                            <li v-if="item.features.transcripts.allowed">{{
                                                    $t('transcripts_title')
                                                }}
                                            </li>
                                            <li v-if="item.features.tts.allowed">{{ $t('settings_tts') }}</li>
                                            <li v-if="item.features.asr.continuous_recording">
                                                {{ $t('session_setting_translate_after_pause') }}
                                            </li>
                                            <li v-if="item.features.asr.real_time_translation">
                                                {{ $t('session_setting_translate_live') }}
                                            </li>


                                        </ul>
                                    </div>
                                </div>
                                <span class="current-selected"
                                      v-if="userCurrentPlan === code">{{ $t('plan_current') }}&nbsp;<span v-if="isCurrentAnnual">(yearly)</span></span>
                                <router-link v-else-if="!authorized" :to="{ path: 'start', query: { from: 'plans' }}"
                                             class="subscribe-btn">{{ $t('website_plans_subscribe_now') }}
                                </router-link>
                                <span
                                    v-else-if="(authorized && code == 'basic' && userCurrentPlan !== code) || (teamTiers.includes(userCurrentPlan))"
                                    class="current-selected"></span>
                                <span v-else-if="authorized && isUpcomingPlan(code)"
                                      class="current-selected current-selected--downgrade">{{ upcomingText }}</span>
                                <a href="javascript:;" v-else-if="authorized && userCurrentPlan !== code && isPlanChangeAllowed(item)"
                                   class="subscribe-btn" @click="selectPlan(code)">
                                   <span v-if="authorized && userCurrentPlan === 'basic'">{{ $t('plans_select_plan') }}</span>
                                    <span v-else-if="isUpgrade(item)">{{ $t('plan_select_upgrade') }}</span>
                                    <span v-else-if="isDowngrade(item)">{{ $t('plan_select_downgrade') }}</span>
                                </a>
                                <a href="javascript:;" v-else-if="isPlanChangeAllowed(item)" @click="selectPlan(code)"
                                   class="subscribe-btn">{{ $t('website_plans_subscribe_now') }}</a>
                            </div>
                          <!-- eslint-enable -->
                        </div>
                    </div>
                    <div class="tab" data-tab="team" v-show="tab == 'team'">
                        <div class="pricing-table pricing-table--3col">
                            <div class="plan-column" v-for="(item, code) in teamProducts" :key="item.title">
                                <div class="plan" v-if="!item.is_custom">
                                    <div class="plan__price"><b v-if="!yearly">${{ getMonthlyPrice(item) }}</b><b
                                        v-else>${{ getMonthlyPrice(item, true) }}</b>/{{
                                            $t('common_month')
                                        }}/{{ $t('plans_team_seat') }}
                                    </div>
                                    <div class="plan__title">
                                        <h2>{{ item.title }}</h2>
                                        <div class="plan__desc">{{ $t(code.toLowerCase() + '_plan_description') }}</div>
                                    </div>
                                    <div class="plan__features">
                                        <ul>
                                            <li>{{ $t('common_host') }} <b>{{ getSessionsLimit(item) }}</b>
                                                {{ $t('common_sessions') }}
                                            </li>
                                            <li><b>{{ item.features.host_event.participants_per_event }}
                                                {{ $t('website_plans_participants') }}</b>
                                                <TooltipWrap hover
                                                             :content="this.$t('website_plans_participants_tooltip')"
                                                             arrow="true">
                                                    <img src="@/assets/icons/information.svg" alt=""></TooltipWrap>
                                            </li>
                                            <li><span><b>{{ getASRlimit(item) }}</b> {{ $t('website_plans_asr_limit') }}
                                   <br>{{ $t('common_per') }} {{ $t('common_month') }} </span>
                                                <TooltipWrap hover :content="this.$t('website_plans_asr_tooltip')"
                                                             arrow="true">
                                                    <img src="@/assets/icons/information.svg" alt=""></TooltipWrap>
                                            </li>
                                            <li v-if="item.features.custom_terms.allowed">
                                                <b>{{ $t('website_plans_custom_terms') }}
                                                    ({{ item.features.custom_terms.total_allowed_items }})</b>
                                                <TooltipWrap hover
                                                             :content="this.$t('website_plans_custom_terms_tooltip')"
                                                             arrow="true">
                                                    <img src="@/assets/icons/information.svg" alt=""></TooltipWrap>
                                            </li>
                                            <li v-if="item.features.transcripts.allowed">{{
                                                    $t('transcripts_title')
                                                }}
                                            </li>
                                            <li v-if="item.features.tts.allowed">{{ $t('settings_tts') }}</li>
                                            <li v-if="item.features.asr.continuous_recording">
                                                {{ $t('session_setting_translate_after_pause') }}
                                            </li>
                                            <li v-if="item.features.asr.real_time_translation">
                                                {{ $t('session_setting_translate_live') }}
                                            </li>


                                        </ul>
                                    </div>
                                    <div class="seats-selector">
                                        <div class="seats-selector__text" v-html="$t('plans_teams_select_seats')"></div>
                                        <div class="seats-selector__wrap">
                                            <div class="seats-selector__select">
                                                <button type="button" class="seats-selector__minus"
                                                        @click="decSeats(code)"
                                                        :disabled="seats[code] <= item.billing.min_allowed_multi_seats">
                                                    &mdash;
                                                </button>
                                                <input type="number" :min="item.billing.min_allowed_multi_seats"
                                                       :max="item.billing.max_allowed_multi_seats"
                                                       v-model="seats[code]">
                                                <button type="button" class="seats-selector__plus"
                                                        @click="incSeats(code)"
                                                        :disabled="seats[code] >= item.billing.max_allowed_multi_seats">
                                                    +
                                                </button>
                                            </div>
                                            <div class="seats-selector__total">
                                                ${{ (seats[code] * getMonthlyPrice(item)).toFixed(2) }}/month
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <span class="current-selected"
                                      v-if="userCurrentPlan === code">{{ $t('plan_current') }}</span>
                                <router-link v-else-if="!authorized" :to="{ path: 'start', query: { from: 'plans' }}"
                                             class="subscribe-btn">{{ $t('website_plans_subscribe_now') }}
                                </router-link>
                                <a href="javascript:;" v-else-if="authorized && userCurrentPlan === 'basic'"
                                   class="subscribe-btn"
                                   @click="selectTeamPlan(code)">{{ $t('website_plans_subscribe_now') }}</a>
                                <a href="javascript:;" v-else-if="authorized && userCurrentPlan !== code"
                                   class="contact-link" @click="openBeacon">{{ $t('contact_us_title') }}</a>

                            </div>
                            <div class="plan-column">
                                <div class="plan plan--custom">
                                    <div class="plan__price"><b>{{ $t('plans_team_custom_title') }}</b></div>
                                    <div class="plan__custom-content">
                                        <p>{{ $t('plans_team_custom_description') }}</p>
                                        <a href="javascript:;" @click="openBeacon">{{ $t('contact_us_title') }}</a>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>

    </div>
    <div class="pricing-footer">
        <span v-html="$t('website_plans_need_more')"></span> <br><a href="javascript:;"
                                                                    @click="openBeacon">{{ $t('contact_us_title') }}</a>
    </div>
</template>

<script>
/* eslint-disable */
// @ is an alias to /src
import Toggle from '@vueform/toggle'
import ProductService from "@/services/products.service";
import {mapState} from 'vuex';
import moment from 'moment';
import axios from "axios";
import { Constants } from "@/cfg/constants"

export default {
    name: 'AboutView',
    components: {
        Toggle
    },
    data() {
        return {
            yearly: false,
            yearlyEnabled: false,
            tab: "individual",
            seats: {
                teams: 0,
                organizations: 0
            },
            products: {},
            displayProducts: {},
            teamProducts: {},
            teamPlansEnabled: false,
            tiers: {
                'basic': 0,
                'starter': 1,
                'professional': 2,
                'premium': 3
            },
            teamTiers: [
                'teams',
                'organizations',
                'teams_participant',
                'organizations_participant'
            ],
            plansDirections: {}
        }
    },
    methods: {
        openBeacon() {
            window.Beacon('open');
        },
        getPlans() {
            let prePlans = [];
            ProductService.getProductsArray().then((data) => {
                prePlans = data.data.result.subscriptions.filter((pr) => {
                    return pr.ui_visibility && !pr.is_custom
                });
                prePlans.forEach((plan) => {
                    this.displayProducts[plan.id] = plan;
                });
                data.data.result.subscriptions.forEach((subscription) => {
                  this.products[subscription.id] = subscription;
                });
            });
        },
        getPlansDirections() {
            axios.get('/data/plans-directions.json').then((response) => {
                this.plansDirections = response.data;
            });
        },
        getTeamPlans() {
            let preTeamPlans = [];
            ProductService.getTeamProductsArray().then((data) => {
                preTeamPlans = data.data.result.subscriptions.filter((pr) => {
                    return pr.ui_visibility
                });
                preTeamPlans.forEach((plan) => {
                    this.teamProducts[plan.id] = plan;
                });
                this.seats.teams = this.teamProducts.teams.billing.min_allowed_multi_seats;
                this.seats.organizations = this.teamProducts.organizations.billing.min_allowed_multi_seats;
            }).catch((error) => {
                console.log(error);
            });
        },
        setTab(tab) {
            this.tab = tab;
        },
        incSeats(code) {
            this.seats[code] = this.seats[code] + 1;
        },
        decSeats(code) {
            this.seats[code] = this.seats[code] - 1;
        },
        isDowngrade(plan) {
            if (this.userCurrentPlan === 'basic') {
                return false;
            }
            const planPrice = this.getMonthlyPrice(plan);
            const currentPlan = this.products[this.user.subscription.type];
            const currentPrice = this.getMonthlyPrice(currentPlan);

            return parseFloat(planPrice) < parseFloat(currentPrice);
        },

        isUpgrade(plan) {
            if (this.userCurrentPlan === 'basic') {
                return true;
            }
            const planPrice = this.getMonthlyPrice(plan);
            const currentPlan = this.products[this.user.subscription.type];
            const currentPrice = this.getMonthlyPrice(currentPlan);

            return parseFloat(planPrice) > parseFloat(currentPrice);
        },
        isUpcomingPlan(code) {
            return this.user.subscription.upcoming_changes && this.user.subscription.upcoming_changes.type == code;
        },
        selectPlan(code) {
            if (this.user.subscription.payment_type != 'stripe' && this.user.subscription.payment_type.length > 0) {
                this.$notify('apple_subscription_notice', {
                    icon: "warning",
                })
                return false;
            }

            const stripeProduct = this.products[code].payment_types.stripe;
            const subProduct = this.products[code];
            let priceId;

            if (this.yearly) {
                priceId = stripeProduct.prices.yearly.id;
            } else {
                priceId = stripeProduct.prices.monthly.id;
            }

            this.$store.commit("auth/setPriceId", priceId);
            if (this.isDowngrade(subProduct)) {
                this.$notify('plans_downgrade_warning', {
                    icon: "warning",
                    showCancelButton: true,
                    cancelButtonText: this.$t('common_cancel'),
                    confirmButtonText: this.$t('common_yes'),
                    focusCancel: true,
                }).then((result) => {
                    if (result.isConfirmed) {
                        ProductService.createOrder(this.token, priceId).then((data) => {
                            let result = data.data.result;
                            if (result.downgrade) {
                                this.$notify('plans_downgrade_success', {
                                    icon: "success",
                                    confirmRedirect: "profile"
                                })
                            } else {
                                location.href = result.url;
                            }

                        })
                    }
                })
            } else {
                ProductService.createOrder(this.token, priceId).then((data) => {
                    let result = data.data.result;
                    if (result.downgrade) {
                        this.$notify('plans_downgrade_success', {
                            icon: "success",
                            confirmRedirect: "profile"
                        })
                    } else {
                        location.href = result.url;
                    }

                })
            }
        },
        selectTeamPlan(code) {
            if (this.user.subscription.payment_type != 'stripe' && this.user.subscription.payment_type.length > 0) {
                this.$notify('apple_subscription_notice', {
                    icon: "warning",
                })
                return false;
            }

            const stripeProduct = this.teamProducts[code].payment_types.stripe;
            let priceId;

            if (this.yearly) {
                priceId = stripeProduct.prices.yearly.id;
            } else {
                priceId = stripeProduct.prices.monthly.id;
            }

            this.$store.commit("auth/setPriceId", priceId);
            const seats = this.seats[code];

            ProductService.createOrder(this.token, priceId, seats).then((data) => {
                let result = data.data.result;
                location.href = result.url;
            })
        },
        getMonthlyPrice(item, yearly = false) {
            if (!item.payment_types.stripe) {
                return 0;
            }
            let price;
            if (yearly) {
                price = (item.payment_types.stripe.prices.yearly.price / 12 / 100).toFixed(2);
            } else {
                price = (item.payment_types.stripe.prices.monthly.price / 100).toFixed(2);
            }

            return price;
        },
        getYearlyPrice(item) {
            if (!item.payment_types.stripe) {
                return 0;
            }

            return (item.payment_types.stripe.prices.yearly.price / 100).toFixed(2);
        },
        getASRlimit(item) {
            let asrGrantedText;
            let asrTimeGranted = item.features.asr.total_allowed_items;
            let asrMinutes = asrTimeGranted / 60;
            if (asrMinutes < 60) {
                asrGrantedText = asrMinutes + " " + this.$t('common_minutes');
            } else {
                asrGrantedText = (asrMinutes / 60) + " " + this.$t('common_hours');
            }

            return asrGrantedText;
        },
        getSessionsLimit(item) {
            return item.features.host_event.total_allowed_items < 999 ? item.features.host_event.total_allowed_items : this.$t('common_unlimited');
        },
        isPlanChangeAllowed(newPlan) {
            if (this.user.subscription.type === 'basic') {
                return true;
            }
            let newPlanCode = newPlan.id;
            if (this.yearly) {
                newPlanCode += '_annual';
            } else {
                newPlanCode += '_monthly';
            }

            const keyToCheck = this.isCurrentAnnual ? 'annual' : 'monthly';
            if (!this.plansDirections[this.user.subscription.type]) {
              return true;
            }
            const isUpgradeAllowed = this.plansDirections[this.user.subscription.type][keyToCheck]['upgrade'].includes(newPlanCode);
            const isDowngradeAllowed = this.plansDirections[this.user.subscription.type][keyToCheck]['downgrade'].includes(newPlanCode);

            return isUpgradeAllowed || isDowngradeAllowed;
        },
        isProduction() {
          // TODO: this is temp. We need to add the env variable for this later.
          return Constants.API_URL.includes("production");
        }
    },
    computed: {
        ...mapState('auth', ['token', 'authorized']),
        ...mapState('nonpersist', ['user']),
        userCurrentPlan() {
            if (!this.authorized) {
                return false;
            }

            if (this.user && this.user.subscription) {
                return this.user.subscription.type;
            }
        },
        upcomingText() {
            return this.$t('profile_upcoming_changes', {
                plan: this.$t('plans_this_plan'),
                date: this.user.subscription.upcoming_changes.date

            });
        },
        isCurrentAnnual() {
            return this.user.subscription.billing_frequency && this.user.subscription.billing_frequency === 'yearly';
        },
    },
    watch: {
        'seats.teams': function (newVal, oldVal) {
            if (newVal > this.teamProducts.teams.billing.max_allowed_multi_seats) {
                this.seats.teams = this.teamProducts.teams.billing.max_allowed_multi_seats;
            } else if (newVal < this.teamProducts.teams.billing.min_allowed_multi_seats) {
                this.seats.teams = this.teamProducts.teams.billing.min_allowed_multi_seats;
            }
        },
        'seats.organizations': function (newVal, oldVal) {
            if (newVal > this.teamProducts.organizations.billing.max_allowed_multi_seats) {
                this.seats.organizations = this.teamProducts.organizations.billing.max_allowed_multi_seats;
            } else if (newVal < this.teamProducts.organizations.billing.min_allowed_multi_seats) {
                this.seats.organizations = this.teamProducts.organizations.billing.min_allowed_multi_seats;
            }
        }
    },
    async mounted() {
      this.yearlyEnabled = true;
      await this.getPlans();
      await this.getPlansDirections();
      if (this.teamPlansEnabled) {
          await this.getTeamPlans();
      }

      let hash = location.hash;
      if (hash) {
          hash = hash.substring(1);
          if (hash === 'team') {
              this.tab = 'team';
          }
      }
    }
}
</script>

<style lang="scss" scoped>
html, body, #app, .wrapper {
    min-height: 100% !important;
    height: auto !important;
}

.page-wrapper {

    &--plans {
        justify-content: center;
        align-items: center;
        width: 90%;
        margin: 0 auto;
        padding-top: 4rem;
    }
}

.pricing {
    width: 100%;

    &-header {
        display: flex;
        justify-content: space-between;
        margin-bottom: 2rem;

        &__left {
            font-size: 1.25rem;
            line-height: 100%;
            font-weight: 400;
            color: #979797;
            display: flex;
            align-items: center;
        }

        &__right {
            font-weight: 400;
            font-size: 1.375rem;
            line-height: 150%;
            color: #3D485A;
            display: flex;

            .toggle-container {
                margin: 0 1rem;
            }
        }
    }

    &-table {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        column-gap: 11px;

        &.pricing-table--3col {
            grid-template-columns: 1fr 1fr 1fr;
        }
    }

    .plan-column {

        .plan {
            background: linear-gradient(0.78deg, #F0F0F0 3.95%, rgba(252, 251, 252, 0) 97.57%);
            border: 3px solid #DADADA;
            border-radius: 10px;
            padding: 3rem 2.5rem 0;
            height: 100%;
            font-size: 1rem;
            line-height: 120%;

                &--highlighted {
                    background: linear-gradient(180deg, #46e0d4 0%, #A5771A 100%);
                    /* Forum shadow */

                box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                color: #fff;
                border: none;
                height: 104%;
                position: relative;
                top: -2%;

                .highlight-text {
                    color: #FAFAFA;
                    opacity: 0.5;
                    font-size: 1.25rem;
                    line-height: 150%;
                    margin-bottom: 1px;
                }

                & + .subscribe-btn {
                    position: relative;
                    top: -2%;
                }
            }

            .asterisked {
                font-size: 0.6rem;
                line-height: 120%;
            }

            &__title {
                height: 5rem;

                h2 {
                    font-weight: 700;
                    font-size: 1.6rem;
                    line-height: 150%;
                    margin: 0 0 2px 0;
                }
            }

            &__price {
                font-size: 1.25rem;
                line-height: 150%;
                font-weight: 700;
                margin-bottom: 2rem;

                b {
                    font-size: 2.25rem;
                    line-height: 100%;
                }

                &--year-monthly {
                    margin-top: -20px;
                    color: #979797;

                    b {
                        font-size: 1.5rem;
                    }
                }
            }

            &__features {
                margin-top: 2rem;

                ul {
                    margin: 0;
                    padding: 0;
                    list-style: none;

                    li {
                        margin-top: 18px;

                        &:first-child {
                            margin-top: 0;
                        }

                        .inline-block {
                            position: static;
                            padding-left: 5px;
                            padding-top: 2px;

                            img {
                                vertical-align: bottom;
                                position: relative;
                                top: -2px;
                            }
                        }
                    }
                }
            }

            &.plan--custom {
                display: flex;
                flex-direction: column;

                .plan__price {
                    margin-bottom: 0;
                }

                .plan__custom-content {
                    margin: auto;
                    display: flex;
                    flex-direction: column;
                    flex: 1;
                    height: 100%;
                    align-items: center;
                    justify-content: center;

                    p {
                        font-size: 1.7rem;
                        line-height: 110%;
                        text-align: center;
                        margin-top: 0;
                    }

                    a {
                      font-weight: 700;
                      font-size: 1.6rem;
                      display: flex;
                      align-items: center;
                      text-align: center;
                      -webkit-text-decoration-line: underline;
                      text-decoration-line: underline;
                      color: #46e0d4;
                    }
                }

                  .custom-question {
                        font-size: 10rem;
                        line-height: 75%;
                        color: #46e0d4;
                  }
                }
            }

            &:hover {
                .plan {
                    border-color: #46e0d4;
                }   
            }
        }

    .buttons-row {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        column-gap: 11px;

            .button-column {
                padding: 0 1.5rem 0;
            }
        }
        .subscribe-btn {
            background: #46E0D4;
            border-radius: 25px;
            color: #fff;
            text-transform: uppercase;
            font-size: 1rem;
            line-height: 1.2rem;
            display: flex;
            align-items: center;
            text-align: center;
            letter-spacing: 0.15em;
            height: 2.8rem;
            justify-content: center;
            font-weight: 700;
            margin: 11px auto 0;
            width: 80%;

        &:hover {
            filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
        }

        &--margined {
            margin-top: 25px;
        }
    }

    .current-selected {
        text-transform: uppercase;
        font-size: 1rem;
        line-height: 1.2rem;
        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: 0.15em;
        height: 2.8rem;
        justify-content: center;
        font-weight: 700;
        margin: 11px auto 0;
        width: 80%;

        &.current-selected--downgrade {
            text-transform: none;
            /* line-height: 1rem; */
            letter-spacing: 0.1em;
            width: 90%;
        }
    }

    &-footer {
        display: flex;
        justify-content: center;
        font-weight: 400;
        font-size: 1.6rem;
        line-height: 120%;
        /* identical to box height, or 30px */

        display: flex;
        align-items: center;
        text-align: center;
        font-feature-settings: 'pnum' on, 'lnum' on;

        color: #979797;
        margin-top: 6rem;
        padding-bottom: 2.25rem;
        flex-direction: column;

        a {
            font-weight: 700;
            font-size: 1.6rem;
            /* identical to box height, or 38px */

            display: flex;
            align-items: center;
            text-align: center;
            text-decoration-line: underline;

            /* Gold */

                color: #46e0d4;
                margin-left: 1rem;
            }
        }
    }
    :deep(.popper) {
        max-width: 200px !important;
        text-align: left !important;
        font-size: 0.8rem !important;
        line-height: 150% !important;
        font-weight: 400;
        padding: 1rem;
    }

.tabs-controls {
    margin-bottom: 30px;
    display: flex;
    column-gap: 2.25rem;

    a {
        color: #000;
        font-weight: 700;
        font-size: 2.25rem;
        line-height: 100%;
        padding-bottom: 10px;
        border-bottom: 2px solid transparent;

        &.active {
          color: #46E0D4;
          border-color: #46E0D4;
        }
    }
}

.tabs-controls__subheader {

    margin-bottom: 30px;
    font-size: 1.25rem;
    line-height: 125%;
    width: 90%;

}

    .contact-link {
      font-weight: 700;
      font-size: 1.6rem;
      display: flex;
      align-items: center;
      text-align: center;
      -webkit-text-decoration-line: underline;
      text-decoration-line: underline;
      color: #46e0d4;
      justify-content: center;
      margin-top: 1rem;
    }

input[type=number] {
    -moz-appearance: textfield;
    appearance: textfield;
    margin: 0;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.seats-selector {
    margin-top: 16px;

    &__wrap {

        display: inline-flex;

    }

    &__select {
        position: relative;
        padding-left: 10px;
    }

    &__total {
        margin-left: 30px;
        display: flex;
        align-items: center;
        font-size: 1rem;
        font-weight: 700;
    }

    &__text {
        color: #979797;
        margin-bottom: 10px;
        font-size: 0.8rem;
    }

      button {
        background: #46e0d4;
        border: none;
        outline: none;
        border-radius: 50%;
        height: 30px;
        width: 30px;
        color: #fff;
        position: absolute;
        cursor: pointer;

        &.seats-selector__minus {
            left: -3px;
        }

        &.seats-selector__plus {
            right: -14px;
        }

        &:disabled, &[disabled] {
            opacity: 1;
            background: #979797;
        }
    }

      input {
          height: 30px;
          border: none;
          text-align: center;
          width: 80px;
          padding: 0 20px;
          border: 1px solid #46e0d4;

          &:focus {
            border: 2px solid #46e0d4;
            outline: none;
        }
    }
}
</style>
